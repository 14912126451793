/* Red Hat Common User Experience (RCUE) */
/* PatternFly additions to Bootstrap */
.slider {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.slider.slider-horizontal {
  width: 100%;
  height: 16px;
}
.slider.slider-horizontal .slider-track {
  height: 8px;
  width: 100%;
  margin-top: -4px;
  top: 50%;
  left: 0;
}
.slider.slider-horizontal .slider-selection,
.slider.slider-horizontal .slider-track-low,
.slider.slider-horizontal .slider-track-high {
  height: 100%;
  top: 0;
  bottom: 0;
}
.slider.slider-horizontal .slider-tick,
.slider.slider-horizontal .slider-handle {
  margin-left: -8px;
}
.slider.slider-horizontal .slider-tick.triangle,
.slider.slider-horizontal .slider-handle.triangle {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  border-width: 0 8px 8px 8px;
  width: 0;
  height: 0;
  border-bottom-color: #ededed;
  margin-top: 0;
}
.slider.slider-horizontal .slider-tick-container {
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.slider.slider-horizontal .slider-tick-label-container {
  white-space: nowrap;
  margin-top: 16px;
}
.slider.slider-horizontal .slider-tick-label-container .slider-tick-label {
  padding-top: 16px * 0.2;
  display: inline-block;
  text-align: center;
}
.slider.slider-horizontal.slider-rtl .slider-track {
  left: initial;
  right: 0;
}
.slider.slider-horizontal.slider-rtl .slider-tick,
.slider.slider-horizontal.slider-rtl .slider-handle {
  margin-left: initial;
  margin-right: -8px;
}
.slider.slider-horizontal.slider-rtl .slider-tick-container {
  left: initial;
  right: 0;
}
.slider.slider-vertical {
  height: 210px;
  width: 16px;
}
.slider.slider-vertical .slider-track {
  width: 8px;
  height: 100%;
  left: 25%;
  top: 0;
}
.slider.slider-vertical .slider-selection {
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
}
.slider.slider-vertical .slider-track-low,
.slider.slider-vertical .slider-track-high {
  width: 100%;
  left: 0;
  right: 0;
}
.slider.slider-vertical .slider-tick,
.slider.slider-vertical .slider-handle {
  margin-top: -8px;
}
.slider.slider-vertical .slider-tick.triangle,
.slider.slider-vertical .slider-handle.triangle {
  border-width: 8px 0 8px 8px;
  width: 1px;
  height: 1px;
  border-left-color: #ededed;
  border-right-color: #ededed;
  margin-left: 0;
  margin-right: 0;
}
.slider.slider-vertical .slider-tick-label-container {
  white-space: nowrap;
}
.slider.slider-vertical .slider-tick-label-container .slider-tick-label {
  padding-left: 16px * 0.2;
}
.slider.slider-vertical.slider-rtl .slider-track {
  left: initial;
  right: 25%;
}
.slider.slider-vertical.slider-rtl .slider-selection {
  left: initial;
  right: 0;
}
.slider.slider-vertical.slider-rtl .slider-tick.triangle,
.slider.slider-vertical.slider-rtl .slider-handle.triangle {
  border-width: 8px 8px 8px 0;
}
.slider.slider-vertical.slider-rtl .slider-tick-label-container .slider-tick-label {
  padding-left: initial;
  padding-right: 16px * 0.2;
}
.slider.slider-disabled .slider-handle {
  background-image: linear-gradient(to bottom, #DFDFDF 0%, #BEBEBE 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdfdfdf', endColorstr='#ffbebebe', GradientType=0);
  background-repeat: repeat-x;
}
.slider.slider-disabled .slider-track {
  background-image: linear-gradient(to bottom, #E5E5E5 0%, #E9E9E9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe5e5e5', endColorstr='#ffe9e9e9', GradientType=0);
  background-repeat: repeat-x;
  cursor: not-allowed;
}
.slider input {
  display: none;
}
.slider .tooltip.top {
  margin-top: -36px;
}
.slider .tooltip-inner {
  white-space: nowrap;
  max-width: none;
}
.slider .hide {
  display: none;
}
.slider-track {
  position: absolute;
  cursor: pointer;
  background-image: linear-gradient(to bottom, #F5F5F5 0%, #F9F9F9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#fff9f9f9', GradientType=0);
  background-repeat: repeat-x;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 1px;
}
.slider-selection {
  position: absolute;
  background-image: linear-gradient(to bottom, #F9F9F9 0%, #F5F5F5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff9f9f9', endColorstr='#fff5f5f5', GradientType=0);
  background-repeat: repeat-x;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 1px;
}
.slider-selection.tick-slider-selection {
  background-image: linear-gradient(to bottom, #0088ce 0%, #39a5dc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088ce', endColorstr='#ff39a5dc', GradientType=0);
  background-repeat: repeat-x;
}
.slider-track-low,
.slider-track-high {
  position: absolute;
  background: transparent;
  box-sizing: border-box;
  border-radius: 1px;
}
.slider-handle {
  position: absolute;
  top: 0;
  width: 16px;
  height: 16px;
  background-color: #39a5dc;
  background-image: linear-gradient(to bottom, #fafafa 0%, #ededed 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffafafa', endColorstr='#ffededed', GradientType=0);
  background-repeat: repeat-x;
  filter: none;
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  border: 0px solid transparent;
}
.slider-handle.round {
  border-radius: 50%;
}
.slider-handle.triangle {
  background: transparent none;
}
.slider-handle.custom {
  background: transparent none;
}
.slider-handle.custom::before {
  line-height: 16px;
  font-size: 20px;
  content: '\2605';
  color: #726204;
}
.slider-tick {
  position: absolute;
  width: 16px;
  height: 16px;
  background-image: linear-gradient(to bottom, #F9F9F9 0%, #F5F5F5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff9f9f9', endColorstr='#fff5f5f5', GradientType=0);
  background-repeat: repeat-x;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  filter: none;
  opacity: 0.8;
  border: 0px solid transparent;
}
.slider-tick.round {
  border-radius: 50%;
}
.slider-tick.triangle {
  background: transparent none;
}
.slider-tick.custom {
  background: transparent none;
}
.slider-tick.custom::before {
  line-height: 16px;
  font-size: 20px;
  content: '\2605';
  color: #726204;
}
.slider-tick.in-selection {
  background-image: linear-gradient(to bottom, #0088ce 0%, #39a5dc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088ce', endColorstr='#ff39a5dc', GradientType=0);
  background-repeat: repeat-x;
  opacity: 1;
}
@media (min-width: 768px) {
  .form-search .combobox-container,
  .form-inline .combobox-container {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: top;
  }
  .form-search .combobox-container .input-group-addon,
  .form-inline .combobox-container .input-group-addon {
    width: auto;
  }
}
.combobox-selected .caret {
  display: none;
}
/* :not doesn't work in IE8 */
.combobox-container:not(.combobox-selected) .glyphicon-remove {
  display: none;
}
.typeahead-long {
  max-height: 300px;
  overflow-y: auto;
}
.control-group.error .combobox-container .add-on {
  color: #B94A48;
  border-color: #B94A48;
}
.control-group.error .combobox-container .caret {
  border-top-color: #B94A48;
}
.control-group.warning .combobox-container .add-on {
  color: #C09853;
  border-color: #C09853;
}
.control-group.warning .combobox-container .caret {
  border-top-color: #C09853;
}
.control-group.success .combobox-container .add-on {
  color: #468847;
  border-color: #468847;
}
.control-group.success .combobox-container .caret {
  border-top-color: #468847;
}
.datepicker {
  border-radius: 1px;
  direction: ltr;
}
.datepicker-inline {
  width: 220px;
}
.datepicker-rtl {
  direction: rtl;
}
.datepicker-rtl.dropdown-menu {
  left: auto;
}
.datepicker-rtl table tr td span {
  float: right;
}
.datepicker-dropdown {
  top: 0;
  left: 0;
  padding: 4px;
}
.datepicker-dropdown:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #bbb;
  border-top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}
.datepicker-dropdown:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-top: 0;
  position: absolute;
}
.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}
.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}
.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}
.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}
.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}
.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}
.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #bbb;
}
.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}
.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.datepicker table tr td,
.datepicker table tr th {
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: none;
}
.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent;
}
.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #9c9c9c;
}
.datepicker table tr td.day:hover,
.datepicker table tr td.focused {
  background: #f1f1f1;
  cursor: pointer;
}
.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: none;
  color: #9c9c9c;
  cursor: default;
}
.datepicker table tr td.highlighted {
  color: #000;
  background-color: #d9edf7;
  border-color: #85c5e5;
  border-radius: 0;
}
.datepicker table tr td.highlighted:focus,
.datepicker table tr td.highlighted.focus {
  color: #000;
  background-color: #afd9ee;
  border-color: #298fc2;
}
.datepicker table tr td.highlighted:hover {
  color: #000;
  background-color: #afd9ee;
  border-color: #52addb;
}
.datepicker table tr td.highlighted:active,
.datepicker table tr td.highlighted.active,
.open > .dropdown-toggle.datepicker table tr td.highlighted {
  color: #000;
  background-color: #afd9ee;
  background-image: none;
  border-color: #52addb;
}
.datepicker table tr td.highlighted:active:hover,
.datepicker table tr td.highlighted.active:hover,
.open > .dropdown-toggle.datepicker table tr td.highlighted:hover,
.datepicker table tr td.highlighted:active:focus,
.datepicker table tr td.highlighted.active:focus,
.open > .dropdown-toggle.datepicker table tr td.highlighted:focus,
.datepicker table tr td.highlighted:active.focus,
.datepicker table tr td.highlighted.active.focus,
.open > .dropdown-toggle.datepicker table tr td.highlighted.focus {
  color: #000;
  background-color: #91cbe8;
  border-color: #298fc2;
}
.datepicker table tr td.highlighted.disabled:hover,
.datepicker table tr td.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.highlighted:hover,
.datepicker table tr td.highlighted.disabled:focus,
.datepicker table tr td.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.highlighted:focus,
.datepicker table tr td.highlighted.disabled.focus,
.datepicker table tr td.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.highlighted.focus {
  background-color: #d9edf7;
  border-color: #85c5e5;
}
.datepicker table tr td.highlighted .badge {
  color: #d9edf7;
  background-color: #000;
}
.datepicker table tr td.highlighted.focused {
  background: #afd9ee;
}
.datepicker table tr td.highlighted.disabled,
.datepicker table tr td.highlighted.disabled:active {
  background: #d9edf7;
  color: #9c9c9c;
}
.datepicker table tr td.today {
  color: #000;
  background-color: #ffdb99;
  border-color: #ffb733;
}
.datepicker table tr td.today:focus,
.datepicker table tr td.today.focus {
  color: #000;
  background-color: #ffc966;
  border-color: #b37400;
}
.datepicker table tr td.today:hover {
  color: #000;
  background-color: #ffc966;
  border-color: #f59e00;
}
.datepicker table tr td.today:active,
.datepicker table tr td.today.active,
.open > .dropdown-toggle.datepicker table tr td.today {
  color: #000;
  background-color: #ffc966;
  background-image: none;
  border-color: #f59e00;
}
.datepicker table tr td.today:active:hover,
.datepicker table tr td.today.active:hover,
.open > .dropdown-toggle.datepicker table tr td.today:hover,
.datepicker table tr td.today:active:focus,
.datepicker table tr td.today.active:focus,
.open > .dropdown-toggle.datepicker table tr td.today:focus,
.datepicker table tr td.today:active.focus,
.datepicker table tr td.today.active.focus,
.open > .dropdown-toggle.datepicker table tr td.today.focus {
  color: #000;
  background-color: #ffbc42;
  border-color: #b37400;
}
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today[disabled]:hover,
fieldset[disabled] .datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled:focus,
.datepicker table tr td.today[disabled]:focus,
fieldset[disabled] .datepicker table tr td.today:focus,
.datepicker table tr td.today.disabled.focus,
.datepicker table tr td.today[disabled].focus,
fieldset[disabled] .datepicker table tr td.today.focus {
  background-color: #ffdb99;
  border-color: #ffb733;
}
.datepicker table tr td.today .badge {
  color: #ffdb99;
  background-color: #000;
}
.datepicker table tr td.today.focused {
  background: #ffc966;
}
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:active {
  background: #ffdb99;
  color: #9c9c9c;
}
.datepicker table tr td.range {
  color: #000;
  background-color: #f1f1f1;
  border-color: #bebebe;
  border-radius: 0;
}
.datepicker table tr td.range:focus,
.datepicker table tr td.range.focus {
  color: #000;
  background-color: #d8d8d8;
  border-color: #7f7f7f;
}
.datepicker table tr td.range:hover {
  color: #000;
  background-color: #d8d8d8;
  border-color: #a0a0a0;
}
.datepicker table tr td.range:active,
.datepicker table tr td.range.active,
.open > .dropdown-toggle.datepicker table tr td.range {
  color: #000;
  background-color: #d8d8d8;
  background-image: none;
  border-color: #a0a0a0;
}
.datepicker table tr td.range:active:hover,
.datepicker table tr td.range.active:hover,
.open > .dropdown-toggle.datepicker table tr td.range:hover,
.datepicker table tr td.range:active:focus,
.datepicker table tr td.range.active:focus,
.open > .dropdown-toggle.datepicker table tr td.range:focus,
.datepicker table tr td.range:active.focus,
.datepicker table tr td.range.active.focus,
.open > .dropdown-toggle.datepicker table tr td.range.focus {
  color: #000;
  background-color: #c6c6c6;
  border-color: #7f7f7f;
}
.datepicker table tr td.range.disabled:hover,
.datepicker table tr td.range[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled:focus,
.datepicker table tr td.range[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range:focus,
.datepicker table tr td.range.disabled.focus,
.datepicker table tr td.range[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.focus {
  background-color: #f1f1f1;
  border-color: #bebebe;
}
.datepicker table tr td.range .badge {
  color: #f1f1f1;
  background-color: #000;
}
.datepicker table tr td.range.focused {
  background: #d8d8d8;
}
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:active {
  background: #f1f1f1;
  color: #9c9c9c;
}
.datepicker table tr td.range.highlighted {
  color: #000;
  background-color: #e5eff4;
  border-color: #9dc3d6;
}
.datepicker table tr td.range.highlighted:focus,
.datepicker table tr td.range.highlighted.focus {
  color: #000;
  background-color: #c1d9e5;
  border-color: #488bac;
}
.datepicker table tr td.range.highlighted:hover {
  color: #000;
  background-color: #c1d9e5;
  border-color: #72a9c4;
}
.datepicker table tr td.range.highlighted:active,
.datepicker table tr td.range.highlighted.active,
.open > .dropdown-toggle.datepicker table tr td.range.highlighted {
  color: #000;
  background-color: #c1d9e5;
  background-image: none;
  border-color: #72a9c4;
}
.datepicker table tr td.range.highlighted:active:hover,
.datepicker table tr td.range.highlighted.active:hover,
.open > .dropdown-toggle.datepicker table tr td.range.highlighted:hover,
.datepicker table tr td.range.highlighted:active:focus,
.datepicker table tr td.range.highlighted.active:focus,
.open > .dropdown-toggle.datepicker table tr td.range.highlighted:focus,
.datepicker table tr td.range.highlighted:active.focus,
.datepicker table tr td.range.highlighted.active.focus,
.open > .dropdown-toggle.datepicker table tr td.range.highlighted.focus {
  color: #000;
  background-color: #a8cadb;
  border-color: #488bac;
}
.datepicker table tr td.range.highlighted.disabled:hover,
.datepicker table tr td.range.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range.highlighted:hover,
.datepicker table tr td.range.highlighted.disabled:focus,
.datepicker table tr td.range.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range.highlighted:focus,
.datepicker table tr td.range.highlighted.disabled.focus,
.datepicker table tr td.range.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.highlighted.focus {
  background-color: #e5eff4;
  border-color: #9dc3d6;
}
.datepicker table tr td.range.highlighted .badge {
  color: #e5eff4;
  background-color: #000;
}
.datepicker table tr td.range.highlighted.focused {
  background: #c1d9e5;
}
.datepicker table tr td.range.highlighted.disabled,
.datepicker table tr td.range.highlighted.disabled:active {
  background: #e5eff4;
  color: #9c9c9c;
}
.datepicker table tr td.range.today {
  color: #000;
  background-color: #f8cb79;
  border-color: #f3a618;
}
.datepicker table tr td.range.today:focus,
.datepicker table tr td.range.today.focus {
  color: #000;
  background-color: #f6b848;
  border-color: #855807;
}
.datepicker table tr td.range.today:hover {
  color: #000;
  background-color: #f6b848;
  border-color: #c4820a;
}
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today.active,
.open > .dropdown-toggle.datepicker table tr td.range.today {
  color: #000;
  background-color: #f6b848;
  background-image: none;
  border-color: #c4820a;
}
.datepicker table tr td.range.today:active:hover,
.datepicker table tr td.range.today.active:hover,
.open > .dropdown-toggle.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:active:focus,
.datepicker table tr td.range.today.active:focus,
.open > .dropdown-toggle.datepicker table tr td.range.today:focus,
.datepicker table tr td.range.today:active.focus,
.datepicker table tr td.range.today.active.focus,
.open > .dropdown-toggle.datepicker table tr td.range.today.focus {
  color: #000;
  background-color: #f4ab26;
  border-color: #855807;
}
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today[disabled]:hover,
fieldset[disabled] .datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled:focus,
.datepicker table tr td.range.today[disabled]:focus,
fieldset[disabled] .datepicker table tr td.range.today:focus,
.datepicker table tr td.range.today.disabled.focus,
.datepicker table tr td.range.today[disabled].focus,
fieldset[disabled] .datepicker table tr td.range.today.focus {
  background-color: #f8cb79;
  border-color: #f3a618;
}
.datepicker table tr td.range.today .badge {
  color: #f8cb79;
  background-color: #000;
}
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:active {
  background: #f8cb79;
  color: #9c9c9c;
}
.datepicker table tr td.selected,
.datepicker table tr td.selected.highlighted {
  color: #fff;
  background-color: #9c9c9c;
  border-color: #555555;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td.selected:focus,
.datepicker table tr td.selected.highlighted:focus,
.datepicker table tr td.selected.focus,
.datepicker table tr td.selected.highlighted.focus {
  color: #fff;
  background-color: #838383;
  border-color: #161616;
}
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.highlighted:hover {
  color: #fff;
  background-color: #838383;
  border-color: #373737;
}
.datepicker table tr td.selected:active,
.datepicker table tr td.selected.highlighted:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected.highlighted.active,
.open > .dropdown-toggle.datepicker table tr td.selected,
.open > .dropdown-toggle.datepicker table tr td.selected.highlighted {
  color: #fff;
  background-color: #838383;
  background-image: none;
  border-color: #373737;
}
.datepicker table tr td.selected:active:hover,
.datepicker table tr td.selected.highlighted:active:hover,
.datepicker table tr td.selected.active:hover,
.datepicker table tr td.selected.highlighted.active:hover,
.open > .dropdown-toggle.datepicker table tr td.selected:hover,
.open > .dropdown-toggle.datepicker table tr td.selected.highlighted:hover,
.datepicker table tr td.selected:active:focus,
.datepicker table tr td.selected.highlighted:active:focus,
.datepicker table tr td.selected.active:focus,
.datepicker table tr td.selected.highlighted.active:focus,
.open > .dropdown-toggle.datepicker table tr td.selected:focus,
.open > .dropdown-toggle.datepicker table tr td.selected.highlighted:focus,
.datepicker table tr td.selected:active.focus,
.datepicker table tr td.selected.highlighted:active.focus,
.datepicker table tr td.selected.active.focus,
.datepicker table tr td.selected.highlighted.active.focus,
.open > .dropdown-toggle.datepicker table tr td.selected.focus,
.open > .dropdown-toggle.datepicker table tr td.selected.highlighted.focus {
  color: #fff;
  background-color: #717171;
  border-color: #161616;
}
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.highlighted.disabled:hover,
.datepicker table tr td.selected[disabled]:hover,
.datepicker table tr td.selected.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.selected:hover,
fieldset[disabled] .datepicker table tr td.selected.highlighted:hover,
.datepicker table tr td.selected.disabled:focus,
.datepicker table tr td.selected.highlighted.disabled:focus,
.datepicker table tr td.selected[disabled]:focus,
.datepicker table tr td.selected.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.selected:focus,
fieldset[disabled] .datepicker table tr td.selected.highlighted:focus,
.datepicker table tr td.selected.disabled.focus,
.datepicker table tr td.selected.highlighted.disabled.focus,
.datepicker table tr td.selected[disabled].focus,
.datepicker table tr td.selected.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.selected.focus,
fieldset[disabled] .datepicker table tr td.selected.highlighted.focus {
  background-color: #9c9c9c;
  border-color: #555555;
}
.datepicker table tr td.selected .badge,
.datepicker table tr td.selected.highlighted .badge {
  color: #9c9c9c;
  background-color: #fff;
}
.datepicker table tr td.active,
.datepicker table tr td.active.highlighted {
  color: #fff;
  background-color: #0088ce;
  border-color: #00659c;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td.active:focus,
.datepicker table tr td.active.highlighted:focus,
.datepicker table tr td.active.focus,
.datepicker table tr td.active.highlighted.focus {
  color: #fff;
  background-color: #00669b;
  border-color: #00121d;
}
.datepicker table tr td.active:hover,
.datepicker table tr td.active.highlighted:hover {
  color: #fff;
  background-color: #00669b;
  border-color: #003d5f;
}
.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active,
.open > .dropdown-toggle.datepicker table tr td.active,
.open > .dropdown-toggle.datepicker table tr td.active.highlighted {
  color: #fff;
  background-color: #00669b;
  background-image: none;
  border-color: #003d5f;
}
.datepicker table tr td.active:active:hover,
.datepicker table tr td.active.highlighted:active:hover,
.datepicker table tr td.active.active:hover,
.datepicker table tr td.active.highlighted.active:hover,
.open > .dropdown-toggle.datepicker table tr td.active:hover,
.open > .dropdown-toggle.datepicker table tr td.active.highlighted:hover,
.datepicker table tr td.active:active:focus,
.datepicker table tr td.active.highlighted:active:focus,
.datepicker table tr td.active.active:focus,
.datepicker table tr td.active.highlighted.active:focus,
.open > .dropdown-toggle.datepicker table tr td.active:focus,
.open > .dropdown-toggle.datepicker table tr td.active.highlighted:focus,
.datepicker table tr td.active:active.focus,
.datepicker table tr td.active.highlighted:active.focus,
.datepicker table tr td.active.active.focus,
.datepicker table tr td.active.highlighted.active.focus,
.open > .dropdown-toggle.datepicker table tr td.active.focus,
.open > .dropdown-toggle.datepicker table tr td.active.highlighted.focus {
  color: #fff;
  background-color: #004f77;
  border-color: #00121d;
}
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.highlighted.disabled:hover,
.datepicker table tr td.active[disabled]:hover,
.datepicker table tr td.active.highlighted[disabled]:hover,
fieldset[disabled] .datepicker table tr td.active:hover,
fieldset[disabled] .datepicker table tr td.active.highlighted:hover,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.highlighted.disabled:focus,
.datepicker table tr td.active[disabled]:focus,
.datepicker table tr td.active.highlighted[disabled]:focus,
fieldset[disabled] .datepicker table tr td.active:focus,
fieldset[disabled] .datepicker table tr td.active.highlighted:focus,
.datepicker table tr td.active.disabled.focus,
.datepicker table tr td.active.highlighted.disabled.focus,
.datepicker table tr td.active[disabled].focus,
.datepicker table tr td.active.highlighted[disabled].focus,
fieldset[disabled] .datepicker table tr td.active.focus,
fieldset[disabled] .datepicker table tr td.active.highlighted.focus {
  background-color: #0088ce;
  border-color: #00659c;
}
.datepicker table tr td.active .badge,
.datepicker table tr td.active.highlighted .badge {
  color: #0088ce;
  background-color: #fff;
}
.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  border-radius: 4px;
}
.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
  background: #f1f1f1;
}
.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: #9c9c9c;
  cursor: default;
}
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  color: #fff;
  background-color: #0088ce;
  border-color: #00659c;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active.focus,
.datepicker table tr td span.active:hover.focus,
.datepicker table tr td span.active.disabled.focus,
.datepicker table tr td span.active.disabled:hover.focus {
  color: #fff;
  background-color: #00669b;
  border-color: #00121d;
}
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover {
  color: #fff;
  background-color: #00669b;
  border-color: #003d5f;
}
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.open > .dropdown-toggle.datepicker table tr td span.active,
.open > .dropdown-toggle.datepicker table tr td span.active:hover,
.open > .dropdown-toggle.datepicker table tr td span.active.disabled,
.open > .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  color: #fff;
  background-color: #00669b;
  background-image: none;
  border-color: #003d5f;
}
.datepicker table tr td span.active:active:hover,
.datepicker table tr td span.active:hover:active:hover,
.datepicker table tr td span.active.disabled:active:hover,
.datepicker table tr td span.active.disabled:hover:active:hover,
.datepicker table tr td span.active.active:hover,
.datepicker table tr td span.active:hover.active:hover,
.datepicker table tr td span.active.disabled.active:hover,
.datepicker table tr td span.active.disabled:hover.active:hover,
.open > .dropdown-toggle.datepicker table tr td span.active:hover,
.open > .dropdown-toggle.datepicker table tr td span.active:hover:hover,
.open > .dropdown-toggle.datepicker table tr td span.active.disabled:hover,
.open > .dropdown-toggle.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active:focus,
.datepicker table tr td span.active:hover:active:focus,
.datepicker table tr td span.active.disabled:active:focus,
.datepicker table tr td span.active.disabled:hover:active:focus,
.datepicker table tr td span.active.active:focus,
.datepicker table tr td span.active:hover.active:focus,
.datepicker table tr td span.active.disabled.active:focus,
.datepicker table tr td span.active.disabled:hover.active:focus,
.open > .dropdown-toggle.datepicker table tr td span.active:focus,
.open > .dropdown-toggle.datepicker table tr td span.active:hover:focus,
.open > .dropdown-toggle.datepicker table tr td span.active.disabled:focus,
.open > .dropdown-toggle.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active:active.focus,
.datepicker table tr td span.active:hover:active.focus,
.datepicker table tr td span.active.disabled:active.focus,
.datepicker table tr td span.active.disabled:hover:active.focus,
.datepicker table tr td span.active.active.focus,
.datepicker table tr td span.active:hover.active.focus,
.datepicker table tr td span.active.disabled.active.focus,
.datepicker table tr td span.active.disabled:hover.active.focus,
.open > .dropdown-toggle.datepicker table tr td span.active.focus,
.open > .dropdown-toggle.datepicker table tr td span.active:hover.focus,
.open > .dropdown-toggle.datepicker table tr td span.active.disabled.focus,
.open > .dropdown-toggle.datepicker table tr td span.active.disabled:hover.focus {
  color: #fff;
  background-color: #004f77;
  border-color: #00121d;
}
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active:hover.disabled:hover,
.datepicker table tr td span.active.disabled.disabled:hover,
.datepicker table tr td span.active.disabled:hover.disabled:hover,
.datepicker table tr td span.active[disabled]:hover,
.datepicker table tr td span.active:hover[disabled]:hover,
.datepicker table tr td span.active.disabled[disabled]:hover,
.datepicker table tr td span.active.disabled:hover[disabled]:hover,
fieldset[disabled] .datepicker table tr td span.active:hover,
fieldset[disabled] .datepicker table tr td span.active:hover:hover,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active:hover.disabled:focus,
.datepicker table tr td span.active.disabled.disabled:focus,
.datepicker table tr td span.active.disabled:hover.disabled:focus,
.datepicker table tr td span.active[disabled]:focus,
.datepicker table tr td span.active:hover[disabled]:focus,
.datepicker table tr td span.active.disabled[disabled]:focus,
.datepicker table tr td span.active.disabled:hover[disabled]:focus,
fieldset[disabled] .datepicker table tr td span.active:focus,
fieldset[disabled] .datepicker table tr td span.active:hover:focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active.disabled.focus,
.datepicker table tr td span.active:hover.disabled.focus,
.datepicker table tr td span.active.disabled.disabled.focus,
.datepicker table tr td span.active.disabled:hover.disabled.focus,
.datepicker table tr td span.active[disabled].focus,
.datepicker table tr td span.active:hover[disabled].focus,
.datepicker table tr td span.active.disabled[disabled].focus,
.datepicker table tr td span.active.disabled:hover[disabled].focus,
fieldset[disabled] .datepicker table tr td span.active.focus,
fieldset[disabled] .datepicker table tr td span.active:hover.focus,
fieldset[disabled] .datepicker table tr td span.active.disabled.focus,
fieldset[disabled] .datepicker table tr td span.active.disabled:hover.focus {
  background-color: #0088ce;
  border-color: #00659c;
}
.datepicker table tr td span.active .badge,
.datepicker table tr td span.active:hover .badge,
.datepicker table tr td span.active.disabled .badge,
.datepicker table tr td span.active.disabled:hover .badge {
  color: #0088ce;
  background-color: #fff;
}
.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: #9c9c9c;
}
.datepicker .datepicker-switch {
  width: 145px;
}
.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr th {
  cursor: pointer;
}
.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
  background: #f1f1f1;
}
.datepicker .prev.disabled,
.datepicker .next.disabled {
  visibility: hidden;
}
.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}
.input-group.date .input-group-addon {
  cursor: pointer;
}
.input-daterange {
  width: 100%;
}
.input-daterange input {
  text-align: center;
}
.input-daterange input:first-child {
  border-radius: 3px 0 0 3px;
}
.input-daterange input:last-child {
  border-radius: 0 3px 3px 0;
}
.input-daterange .input-group-addon {
  width: auto;
  min-width: 16px;
  padding: 4px 5px;
  line-height: 1.66666667;
  border-width: 1px 0;
  margin-left: -5px;
  margin-right: -5px;
}
select.bs-select-hidden,
select.selectpicker {
  display: none !important;
}
.bootstrap-select {
  width: 220px \0;
  /*IE9 and below*/
}
.bootstrap-select > .dropdown-toggle {
  width: 100%;
  padding-right: 25px;
  z-index: 1;
}
.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active {
  color: #999;
}
.bootstrap-select > select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none;
}
.bootstrap-select > select.mobile-device {
  top: 0;
  left: 0;
  display: block !important;
  width: 100% !important;
  z-index: 2;
}
.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle {
  border-color: #b94a48;
}
.bootstrap-select.fit-width {
  width: auto !important;
}
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 220px;
}
.bootstrap-select .dropdown-toggle:focus {
  outline: thin dotted #333333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px;
}
.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none;
}
.bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%;
}
.bootstrap-select.form-control.input-group-btn {
  z-index: auto;
}
.bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}
.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0;
}
.bootstrap-select.btn-group.dropdown-menu-right,
.bootstrap-select.btn-group[class*="col-"].dropdown-menu-right,
.row .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
  float: right;
}
.form-inline .bootstrap-select.btn-group,
.form-horizontal .bootstrap-select.btn-group,
.form-group .bootstrap-select.btn-group {
  margin-bottom: 0;
}
.form-group-lg .bootstrap-select.btn-group.form-control,
.form-group-sm .bootstrap-select.btn-group.form-control {
  padding: 0;
}
.form-group-lg .bootstrap-select.btn-group.form-control .dropdown-toggle,
.form-group-sm .bootstrap-select.btn-group.form-control .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit;
}
.form-inline .bootstrap-select.btn-group .form-control {
  width: 100%;
}
.bootstrap-select.btn-group.disabled,
.bootstrap-select.btn-group > .disabled {
  cursor: not-allowed;
}
.bootstrap-select.btn-group.disabled:focus,
.bootstrap-select.btn-group > .disabled:focus {
  outline: none !important;
}
.bootstrap-select.btn-group.bs-container {
  position: absolute;
  height: 0 !important;
  padding: 0 !important;
}
.bootstrap-select.btn-group.bs-container .dropdown-menu {
  z-index: 1060;
}
.bootstrap-select.btn-group .dropdown-toggle .filter-option {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-align: left;
}
.bootstrap-select.btn-group .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle;
}
.bootstrap-select.btn-group[class*="col-"] .dropdown-toggle {
  width: 100%;
}
.bootstrap-select.btn-group .dropdown-menu {
  min-width: 100%;
  box-sizing: border-box;
}
.bootstrap-select.btn-group .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  box-shadow: none;
}
.bootstrap-select.btn-group .dropdown-menu li {
  position: relative;
}
.bootstrap-select.btn-group .dropdown-menu li.active small {
  color: #fff;
}
.bootstrap-select.btn-group .dropdown-menu li.disabled a {
  cursor: not-allowed;
}
.bootstrap-select.btn-group .dropdown-menu li a {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.bootstrap-select.btn-group .dropdown-menu li a.opt {
  position: relative;
  padding-left: 2.25em;
}
.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
  display: none;
}
.bootstrap-select.btn-group .dropdown-menu li a span.text {
  display: inline-block;
}
.bootstrap-select.btn-group .dropdown-menu li small {
  padding-left: 0.5em;
}
.bootstrap-select.btn-group .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: 96%;
  margin: 0 2%;
  min-height: 26px;
  padding: 3px 5px;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  opacity: 0.9;
  box-sizing: border-box;
}
.bootstrap-select.btn-group .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap;
}
.bootstrap-select.btn-group.fit-width .dropdown-toggle .filter-option {
  position: static;
}
.bootstrap-select.btn-group.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px;
}
.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  margin-top: 5px;
}
.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
  margin-right: 34px;
}
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle {
  z-index: 1061;
}
.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none;
}
.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none;
}
.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
  bottom: auto;
  top: -3px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0;
}
.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
  bottom: auto;
  top: -3px;
  border-top: 6px solid white;
  border-bottom: 0;
}
.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
  right: 12px;
  left: auto;
}
.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
  right: 13px;
  left: auto;
}
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:after {
  display: block;
}
.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px;
}
.bs-actionsbox {
  width: 100%;
  box-sizing: border-box;
}
.bs-actionsbox .btn-group button {
  width: 50%;
}
.bs-donebutton {
  float: left;
  width: 100%;
  box-sizing: border-box;
}
.bs-donebutton .btn-group button {
  width: 100%;
}
.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px;
}
.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none;
}
.bootstrap-switch {
  display: inline-block;
  direction: ltr;
  cursor: pointer;
  border-radius: 1px;
  border: 1px solid;
  border-color: #bbb;
  position: relative;
  text-align: left;
  overflow: hidden;
  line-height: 8px;
  z-index: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.bootstrap-switch .bootstrap-switch-container {
  display: inline-block;
  top: 0;
  border-radius: 1px;
  transform: translate3d(0, 0, 0);
}
.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
  box-sizing: border-box;
  cursor: pointer;
  display: table-cell;
  vertical-align: middle;
  padding: 2px 6px;
  font-size: 12px;
  line-height: 20px;
}
.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off {
  text-align: center;
  z-index: 1;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary {
  color: #fff;
  background: #0088ce;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info {
  color: #fff;
  background: #00659c;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success {
  color: #fff;
  background: #3f9c35;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning {
  background: #ec7a08;
  color: #fff;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger {
  color: #fff;
  background: #a30000;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
  color: #000;
  background: #f1f1f1;
}
.bootstrap-switch .bootstrap-switch-label {
  text-align: center;
  margin-top: -1px;
  margin-bottom: -1px;
  z-index: 100;
  color: #4d5258;
  background: #f1f1f1;
}
.bootstrap-switch span::before {
  content: "\200b";
}
.bootstrap-switch .bootstrap-switch-handle-on {
  border-top-left-radius: 1px - 1;
  border-bottom-left-radius: 1px - 1;
}
.bootstrap-switch .bootstrap-switch-handle-off {
  border-top-right-radius: 1px - 1;
  border-bottom-right-radius: 1px - 1;
}
.bootstrap-switch input[type='radio'],
.bootstrap-switch input[type='checkbox'] {
  position: absolute !important;
  top: 0;
  left: 0;
  margin: 0;
  z-index: -1;
  filter: alpha(opacity=0);
  opacity: 0;
  visibility: hidden;
}
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label {
  padding: 1px 5px;
  font-size: 11px;
  line-height: 1.5;
}
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label {
  padding: 2px 6px;
  font-size: 11px;
  line-height: 1.5;
}
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-label {
  padding: 2px 10px;
  font-size: 14px;
  line-height: 1.3333333;
}
.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
  cursor: default !important;
}
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
}
.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
  transition: margin-left 0.5s;
}
.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 1px - 1;
  border-bottom-right-radius: 1px - 1;
}
.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 1px - 1;
  border-bottom-left-radius: 1px - 1;
}
.bootstrap-switch.bootstrap-switch-focused {
  border-color: #0088ce;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(0, 136, 206, 0.6);
}
.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-off .bootstrap-switch-label {
  border-top-right-radius: 1px - 1;
  border-bottom-right-radius: 1px - 1;
}
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-on .bootstrap-switch-label {
  border-top-left-radius: 1px - 1;
  border-bottom-left-radius: 1px - 1;
}
/*
 *  Bootstrap TouchSpin - v3.1.1
 *  A mobile and touch friendly input spinner component for Bootstrap 3.
 *  http://www.virtuosoft.eu/code/bootstrap-touchspin/
 *
 *  Made by István Ujj-Mészáros
 *  Under Apache License v2.0 License
 */
.bootstrap-touchspin .input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}
.bootstrap-touchspin .input-group-btn-vertical > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  padding: 8px 10px;
  margin-left: -1px;
  position: relative;
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0;
  border-top-right-radius: 4px;
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  margin-top: -2px;
  border-radius: 0;
  border-bottom-right-radius: 4px;
}
.bootstrap-touchspin .input-group-btn-vertical i {
  position: absolute;
  top: 3px;
  left: 5px;
  font-size: 9px;
  font-weight: normal;
}
/*-- Chart --*/
.c3 svg {
  font: 10px sans-serif;
  -webkit-tap-highlight-color: transparent;
}
.c3 path,
.c3 line {
  fill: none;
  stroke: #000;
}
.c3 text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
}
.c3-legend-item-tile,
.c3-xgrid-focus,
.c3-ygrid,
.c3-event-rect,
.c3-bars path {
  shape-rendering: crispEdges;
}
.c3-chart-arc path {
  stroke: #fff;
}
.c3-chart-arc rect {
  stroke: white;
  stroke-width: 1;
}
.c3-chart-arc text {
  fill: #fff;
  font-size: 13px;
}
/*-- Axis --*/
/*-- Grid --*/
.c3-grid line {
  stroke: #aaa;
}
.c3-grid text {
  fill: #aaa;
}
.c3-xgrid,
.c3-ygrid {
  stroke-dasharray: 3 3;
}
/*-- Text on Chart --*/
.c3-text.c3-empty {
  fill: #808080;
  font-size: 2em;
}
/*-- Line --*/
.c3-line {
  stroke-width: 1px;
}
/*-- Point --*/
.c3-circle._expanded_ {
  stroke-width: 1px;
  stroke: white;
}
.c3-selected-circle {
  fill: white;
  stroke-width: 2px;
}
/*-- Bar --*/
.c3-bar {
  stroke-width: 0;
}
.c3-bar._expanded_ {
  fill-opacity: 1;
  fill-opacity: 0.75;
}
/*-- Focus --*/
.c3-target.c3-focused {
  opacity: 1;
}
.c3-target.c3-focused path.c3-line,
.c3-target.c3-focused path.c3-step {
  stroke-width: 2px;
}
.c3-target.c3-defocused {
  opacity: 0.3 !important;
}
/*-- Region --*/
.c3-region {
  fill: steelblue;
  fill-opacity: .1;
}
/*-- Brush --*/
.c3-brush .extent {
  fill-opacity: .1;
}
/*-- Select - Drag --*/
/*-- Legend --*/
.c3-legend-item {
  font-size: 12px;
}
.c3-legend-item-hidden {
  opacity: 0.15;
}
.c3-legend-background {
  opacity: 0.75;
  fill: white;
  stroke: lightgray;
  stroke-width: 1;
}
/*-- Title --*/
.c3-title {
  font: 14px sans-serif;
}
/*-- Tooltip --*/
.c3-tooltip-container {
  z-index: 10;
}
.c3-tooltip {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #fff;
  empty-cells: show;
  box-shadow: 7px 7px 12px -9px #777777;
  opacity: 0.9;
}
.c3-tooltip tr {
  border: 1px solid #CCC;
}
.c3-tooltip th {
  background-color: #aaa;
  font-size: 14px;
  padding: 2px 5px;
  text-align: left;
  color: #FFF;
}
.c3-tooltip td {
  font-size: 13px;
  padding: 3px 6px;
  background-color: #fff;
  border-left: 1px dotted #999;
}
.c3-tooltip td > span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
}
.c3-tooltip td.value {
  text-align: right;
}
/*-- Area --*/
.c3-area {
  stroke-width: 0;
  opacity: 0.2;
}
/*-- Arc --*/
.c3-chart-arcs-title {
  dominant-baseline: middle;
  font-size: 1.3em;
}
.c3-chart-arcs .c3-chart-arcs-background {
  fill: #e0e0e0;
  stroke: #FFF;
}
.c3-chart-arcs .c3-chart-arcs-gauge-unit {
  fill: #000;
  font-size: 16px;
}
.c3-chart-arcs .c3-chart-arcs-gauge-max {
  fill: #777;
}
.c3-chart-arcs .c3-chart-arcs-gauge-min {
  fill: #777;
}
.c3-chart-arc .c3-gauge-value {
  fill: #000;
  /*  font-size: 28px !important;*/
}
.c3-chart-arc.c3-target g path {
  opacity: 1;
}
.c3-chart-arc.c3-target.c3-focused g path {
  opacity: 1;
}
/*!
 * Datetimepicker for Bootstrap 3
 * version : 4.17.47
 * https://github.com/Eonasdan/bootstrap-datetimepicker/
 */
.bootstrap-datetimepicker-widget {
  list-style: none;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  display: block;
  margin: 2px 0;
  padding: 4px;
  width: 19em;
}
@media (min-width: 768px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
@media (min-width: 992px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
@media (min-width: 1200px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
.bootstrap-datetimepicker-widget.dropdown-menu:before,
.bootstrap-datetimepicker-widget.dropdown-menu:after {
  content: '';
  display: inline-block;
  position: absolute;
}
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  top: -7px;
  left: 7px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  top: -6px;
  left: 8px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.top:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #ccc;
  border-top-color: rgba(0, 0, 0, 0.2);
  bottom: -7px;
  left: 6px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.top:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid white;
  bottom: -6px;
  left: 7px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:before {
  left: auto;
  right: 6px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:after {
  left: auto;
  right: 7px;
}
.bootstrap-datetimepicker-widget .list-unstyled {
  margin: 0;
}
.bootstrap-datetimepicker-widget a[data-action] {
  padding: 6px 0;
}
.bootstrap-datetimepicker-widget a[data-action]:active {
  box-shadow: none;
}
.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second {
  width: 54px;
  font-weight: bold;
  font-size: 1em;
  margin: 0;
}
.bootstrap-datetimepicker-widget button[data-action] {
  padding: 6px;
}
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Increment Hours";
  content: "Increment Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Increment Minutes";
  content: "Increment Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Decrement Hours";
  content: "Decrement Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Decrement Minutes";
  content: "Decrement Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Show Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Show Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Toggle AM/PM";
}
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Clear the picker";
}
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Set the date to today";
}
.bootstrap-datetimepicker-widget .picker-switch {
  text-align: center;
}
.bootstrap-datetimepicker-widget .picker-switch::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Toggle Date and Time Screens";
}
.bootstrap-datetimepicker-widget .picker-switch td {
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  line-height: inherit;
}
.bootstrap-datetimepicker-widget .picker-switch td span {
  line-height: 2.5;
  height: 2.5em;
  width: 100%;
}
.bootstrap-datetimepicker-widget table {
  width: 100%;
  margin: 0;
}
.bootstrap-datetimepicker-widget table td,
.bootstrap-datetimepicker-widget table th {
  text-align: center;
  border-radius: 1px;
}
.bootstrap-datetimepicker-widget table th {
  height: 20px;
  line-height: 20px;
  width: 20px;
}
.bootstrap-datetimepicker-widget table th.picker-switch {
  width: 145px;
}
.bootstrap-datetimepicker-widget table th.disabled,
.bootstrap-datetimepicker-widget table th.disabled:hover {
  background: none;
  color: #9c9c9c;
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget table th.prev::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Previous Month";
}
.bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  content: "Next Month";
}
.bootstrap-datetimepicker-widget table thead tr:first-child th {
  cursor: pointer;
}
.bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
  background: transparent;
}
.bootstrap-datetimepicker-widget table td {
  height: 54px;
  line-height: 54px;
  width: 54px;
}
.bootstrap-datetimepicker-widget table td.cw {
  font-size: .8em;
  height: 20px;
  line-height: 20px;
  color: #9c9c9c;
}
.bootstrap-datetimepicker-widget table td.day {
  height: 20px;
  line-height: 20px;
  width: 20px;
}
.bootstrap-datetimepicker-widget table td.day:hover,
.bootstrap-datetimepicker-widget table td.hour:hover,
.bootstrap-datetimepicker-widget table td.minute:hover,
.bootstrap-datetimepicker-widget table td.second:hover {
  background: transparent;
  cursor: pointer;
}
.bootstrap-datetimepicker-widget table td.old,
.bootstrap-datetimepicker-widget table td.new {
  color: #9c9c9c;
}
.bootstrap-datetimepicker-widget table td.today {
  position: relative;
}
.bootstrap-datetimepicker-widget table td.today:before {
  content: '';
  display: inline-block;
  border: solid transparent;
  border-width: 0 0 7px 7px;
  border-bottom-color: #0088ce;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #0088ce;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.bootstrap-datetimepicker-widget table td.active.today:before {
  border-bottom-color: #fff;
}
.bootstrap-datetimepicker-widget table td.disabled,
.bootstrap-datetimepicker-widget table td.disabled:hover {
  background: none;
  color: #9c9c9c;
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget table td span {
  display: inline-block;
  width: 54px;
  height: 54px;
  line-height: 54px;
  margin: 2px 1.5px;
  cursor: pointer;
  border-radius: 1px;
}
.bootstrap-datetimepicker-widget table td span:hover {
  background: transparent;
}
.bootstrap-datetimepicker-widget table td span.active {
  background-color: #0088ce;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.bootstrap-datetimepicker-widget table td span.old {
  color: #9c9c9c;
}
.bootstrap-datetimepicker-widget table td span.disabled,
.bootstrap-datetimepicker-widget table td span.disabled:hover {
  background: none;
  color: #9c9c9c;
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget.usetwentyfour td.hour {
  height: 27px;
  line-height: 27px;
}
.bootstrap-datetimepicker-widget.wider {
  width: 21em;
}
.bootstrap-datetimepicker-widget .datepicker-decades .decade {
  line-height: 1.8em !important;
}
.input-group.date .input-group-addon {
  cursor: pointer;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.text-overflow-pf {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}
.fixed-height-accordion-pf {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.fixed-height-accordion-pf .panel {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.fixed-height-accordion-pf .panel.panel-open {
  -ms-flex: 1;
      flex: 1;
}
.fixed-height-accordion-pf .panel .collapse.in {
  -ms-flex: 1;
      flex: 1;
  overflow-y: auto;
}
.about-modal-pf {
  background-color: #292e34;
  background-image: url("../img/bg-login-2.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 216px auto;
}
@media (min-width: 768px) {
  .about-modal-pf {
    background-size: auto;
  }
}
.about-modal-pf .modal-body {
  color: #fff;
  padding-bottom: 16px;
  padding-left: 40px;
  padding-right: 40px;
}
@media (min-width: 768px) {
  .about-modal-pf .modal-body {
    padding-left: 80px;
    padding-right: 80px;
  }
}
.about-modal-pf .modal-header {
  background-color: transparent;
}
.about-modal-pf .pficon-close {
  color: #fff;
}
.product-versions-pf {
  margin-bottom: 30px;
  margin-top: 30px;
}
.product-versions-pf li strong {
  margin-right: 10px;
}
.trademark-pf {
  font-size: 11px;
}
.applauncher-pf {
  display: inline-block;
  overflow: visible;
}
.applauncher-pf .applauncher-pf-title {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.applauncher-pf .dropdown-toggle.disabled {
  cursor: not-allowed;
}
.applauncher-pf.open > .dropdown-menu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.applauncher-pf .applauncher-pf-item {
  width: 100%;
}
.applauncher-pf .applauncher-pf-link {
  display: -ms-flexbox;
  display: flex;
  white-space: initial;
  -ms-flex-align: center;
      align-items: center;
}
@media (min-width: 768px) {
  .applauncher-pf-block-list .applauncher-pf-item {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
  }
}
.applauncher-pf-block-list .applauncher-pf-link {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (min-width: 768px) {
  .applauncher-pf-block-list .applauncher-pf-link {
    -ms-flex-direction: column;
        flex-direction: column;
    text-align: center;
    padding: 15px 0;
    height: 100%;
  }
}
.applauncher-pf-block-list .applauncher-pf-link-icon {
  padding: 0;
}
.applauncher-pf-block-list .applauncher-pf-link-title {
  margin-top: auto;
}
@media (min-width: 768px) {
  .applauncher-pf:not(.applauncher-pf-block-list) .applauncher-pf-link {
    padding: 9px;
  }
  .applauncher-pf:not(.applauncher-pf-block-list) .applauncher-pf-link-icon {
    -ms-flex: 1 0 0;
        flex: 1 0 0;
    text-align: left;
  }
  .applauncher-pf:not(.applauncher-pf-block-list) .applauncher-pf-link-title {
    -ms-flex: 3;
        flex: 3;
  }
}
.applauncher-pf .dropdown-menu {
  padding: 9px;
  min-width: 220px;
}
.applauncher-pf .applauncher-pf-link {
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
}
.applauncher-pf .applauncher-pf-link:hover {
  background-color: #f5f5f5;
  border-color: #bbb;
  color: #0088ce;
  text-decoration: none;
  box-shadow: 0 0 2px 0 #d1d1d1;
}
.applauncher-pf .applauncher-pf-link-icon {
  font-size: 1.2em;
  text-align: center;
  width: 1.28571429em;
}
@media (min-width: 768px) {
  .applauncher-pf .applauncher-pf-link-icon {
    font-size: 2em;
  }
}
@media (min-width: 768px) {
  .navbar-utility .applauncher-pf .dropdown-menu {
    right: 0;
  }
}
.navbar-pf .navbar-utility .applauncher-pf.open > .dropdown-toggle,
.navbar-pf-alt .navbar-utility .applauncher-pf.open > .dropdown-toggle,
.navbar-pf .applauncher-pf.open > .dropdown-toggle,
.navbar-pf-alt .applauncher-pf.open > .dropdown-toggle,
.navbar-pf .navbar-utility .applauncher-pf.open > .dropdown-toggle:focus,
.navbar-pf-alt .navbar-utility .applauncher-pf.open > .dropdown-toggle:focus,
.navbar-pf .applauncher-pf.open > .dropdown-toggle:focus,
.navbar-pf-alt .applauncher-pf.open > .dropdown-toggle:focus {
  background-color: #454c53;
  color: #fff;
}
@media (min-width: 768px) {
  .navbar-pf .navbar-utility .applauncher-pf.open > .dropdown-toggle,
  .navbar-pf-alt .navbar-utility .applauncher-pf.open > .dropdown-toggle,
  .navbar-pf .applauncher-pf.open > .dropdown-toggle,
  .navbar-pf-alt .applauncher-pf.open > .dropdown-toggle,
  .navbar-pf .navbar-utility .applauncher-pf.open > .dropdown-toggle:focus,
  .navbar-pf-alt .navbar-utility .applauncher-pf.open > .dropdown-toggle:focus,
  .navbar-pf .applauncher-pf.open > .dropdown-toggle:focus,
  .navbar-pf-alt .applauncher-pf.open > .dropdown-toggle:focus {
    background-color: #5b6165;
    border-color: #53565b;
    color: #fff;
  }
}
@media (max-width: 767px) {
  .navbar-pf .navbar-utility .applauncher-pf.open .dropdown-menu > li > a,
  .navbar-pf-alt .navbar-utility .applauncher-pf.open .dropdown-menu > li > a,
  .navbar-pf .applauncher-pf.open .dropdown-menu > li > a,
  .navbar-pf-alt .applauncher-pf.open .dropdown-menu > li > a {
    padding-left: 20px;
  }
  .navbar-pf .navbar-utility .applauncher-pf.open .dropdown-menu > li > a .applauncher-pf-link-icon,
  .navbar-pf-alt .navbar-utility .applauncher-pf.open .dropdown-menu > li > a .applauncher-pf-link-icon,
  .navbar-pf .applauncher-pf.open .dropdown-menu > li > a .applauncher-pf-link-icon,
  .navbar-pf-alt .applauncher-pf.open .dropdown-menu > li > a .applauncher-pf-link-icon {
    padding-right: 20px;
  }
}
.navbar-pf .navbar-utility .applauncher-pf.dropdown > .dropdown-toggle,
.navbar-pf-alt .navbar-utility .applauncher-pf.dropdown > .dropdown-toggle,
.navbar-pf .applauncher-pf.dropdown > .dropdown-toggle,
.navbar-pf-alt .applauncher-pf.dropdown > .dropdown-toggle,
.navbar-pf .navbar-utility .applauncher-pf .dropdown-toggle,
.navbar-pf-alt .navbar-utility .applauncher-pf .dropdown-toggle,
.navbar-pf .applauncher-pf .dropdown-toggle,
.navbar-pf-alt .applauncher-pf .dropdown-toggle {
  color: #d1d1d1;
  text-align: left;
  text-decoration: none;
  border-width: 0;
  display: block;
  padding-left: 20px;
}
.navbar-pf .navbar-utility .applauncher-pf.dropdown > .dropdown-toggle.disabled,
.navbar-pf-alt .navbar-utility .applauncher-pf.dropdown > .dropdown-toggle.disabled,
.navbar-pf .applauncher-pf.dropdown > .dropdown-toggle.disabled,
.navbar-pf-alt .applauncher-pf.dropdown > .dropdown-toggle.disabled,
.navbar-pf .navbar-utility .applauncher-pf .dropdown-toggle.disabled,
.navbar-pf-alt .navbar-utility .applauncher-pf .dropdown-toggle.disabled,
.navbar-pf .applauncher-pf .dropdown-toggle.disabled,
.navbar-pf-alt .applauncher-pf .dropdown-toggle.disabled {
  color: #8b8d8f !important;
}
.navbar-pf .navbar-utility .applauncher-pf.dropdown > .dropdown-toggle:hover,
.navbar-pf-alt .navbar-utility .applauncher-pf.dropdown > .dropdown-toggle:hover,
.navbar-pf .applauncher-pf.dropdown > .dropdown-toggle:hover,
.navbar-pf-alt .applauncher-pf.dropdown > .dropdown-toggle:hover,
.navbar-pf .navbar-utility .applauncher-pf .dropdown-toggle:hover,
.navbar-pf-alt .navbar-utility .applauncher-pf .dropdown-toggle:hover,
.navbar-pf .applauncher-pf .dropdown-toggle:hover,
.navbar-pf-alt .applauncher-pf .dropdown-toggle:hover,
.navbar-pf .navbar-utility .applauncher-pf.dropdown > .dropdown-toggle:focus,
.navbar-pf-alt .navbar-utility .applauncher-pf.dropdown > .dropdown-toggle:focus,
.navbar-pf .applauncher-pf.dropdown > .dropdown-toggle:focus,
.navbar-pf-alt .applauncher-pf.dropdown > .dropdown-toggle:focus,
.navbar-pf .navbar-utility .applauncher-pf .dropdown-toggle:focus,
.navbar-pf-alt .navbar-utility .applauncher-pf .dropdown-toggle:focus,
.navbar-pf .applauncher-pf .dropdown-toggle:focus,
.navbar-pf-alt .applauncher-pf .dropdown-toggle:focus {
  color: #fff;
}
@media (min-width: 768px) {
  .navbar-pf .navbar-utility .applauncher-pf.dropdown > .dropdown-toggle,
  .navbar-pf-alt .navbar-utility .applauncher-pf.dropdown > .dropdown-toggle,
  .navbar-pf .applauncher-pf.dropdown > .dropdown-toggle,
  .navbar-pf-alt .applauncher-pf.dropdown > .dropdown-toggle,
  .navbar-pf .navbar-utility .applauncher-pf .dropdown-toggle,
  .navbar-pf-alt .navbar-utility .applauncher-pf .dropdown-toggle,
  .navbar-pf .applauncher-pf .dropdown-toggle,
  .navbar-pf-alt .applauncher-pf .dropdown-toggle {
    border-left: 1px solid #53565b;
    padding: 7px 10px;
    line-height: 1;
  }
}
.navbar-pf .navbar-utility .applauncher-pf .applauncher-pf-icon,
.navbar-pf-alt .navbar-utility .applauncher-pf .applauncher-pf-icon,
.navbar-pf .applauncher-pf .applauncher-pf-icon,
.navbar-pf-alt .applauncher-pf .applauncher-pf-icon {
  padding-right: 4px;
  position: relative;
}
@media (min-width: 768px) {
  .navbar-pf .navbar-utility .applauncher-pf .applauncher-pf-icon,
  .navbar-pf-alt .navbar-utility .applauncher-pf .applauncher-pf-icon,
  .navbar-pf .applauncher-pf .applauncher-pf-icon,
  .navbar-pf-alt .applauncher-pf .applauncher-pf-icon {
    padding: 0;
  }
}
.navbar-pf .navbar-utility .applauncher-pf .applauncher-pf-title,
.navbar-pf-alt .navbar-utility .applauncher-pf .applauncher-pf-title,
.navbar-pf .applauncher-pf .applauncher-pf-title,
.navbar-pf-alt .applauncher-pf .applauncher-pf-title {
  display: inline;
  position: relative;
  margin: 0;
}
.navbar-pf .navbar-utility .applauncher-pf .applauncher-pf-link,
.navbar-pf-alt .navbar-utility .applauncher-pf .applauncher-pf-link,
.navbar-pf .applauncher-pf .applauncher-pf-link,
.navbar-pf-alt .applauncher-pf .applauncher-pf-link {
  overflow: hidden;
  width: 100%;
}
.navbar-pf .navbar-utility .applauncher-pf .applauncher-pf-link:hover,
.navbar-pf-alt .navbar-utility .applauncher-pf .applauncher-pf-link:hover,
.navbar-pf .applauncher-pf .applauncher-pf-link:hover,
.navbar-pf-alt .applauncher-pf .applauncher-pf-link:hover {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.navbar-pf .navbar-utility .applauncher-pf .applauncher-pf-link-title,
.navbar-pf-alt .navbar-utility .applauncher-pf .applauncher-pf-link-title,
.navbar-pf .applauncher-pf .applauncher-pf-link-title,
.navbar-pf-alt .applauncher-pf .applauncher-pf-link-title {
  overflow: hidden;
}
@media (min-width: 768px) {
  .navbar-pf .navbar-utility .applauncher-pf,
  .navbar-pf-alt .navbar-utility .applauncher-pf,
  .navbar-pf .applauncher-pf,
  .navbar-pf-alt .applauncher-pf {
    display: inline-block;
    overflow: visible;
  }
  .navbar-pf .navbar-utility .applauncher-pf .applauncher-pf-title,
  .navbar-pf-alt .navbar-utility .applauncher-pf .applauncher-pf-title,
  .navbar-pf .applauncher-pf .applauncher-pf-title,
  .navbar-pf-alt .applauncher-pf .applauncher-pf-title {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  .navbar-pf .navbar-utility .applauncher-pf .dropdown-toggle.disabled,
  .navbar-pf-alt .navbar-utility .applauncher-pf .dropdown-toggle.disabled,
  .navbar-pf .applauncher-pf .dropdown-toggle.disabled,
  .navbar-pf-alt .applauncher-pf .dropdown-toggle.disabled {
    cursor: not-allowed;
  }
  .navbar-pf .navbar-utility .applauncher-pf.open > .dropdown-menu,
  .navbar-pf-alt .navbar-utility .applauncher-pf.open > .dropdown-menu,
  .navbar-pf .applauncher-pf.open > .dropdown-menu,
  .navbar-pf-alt .applauncher-pf.open > .dropdown-menu {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .navbar-pf .navbar-utility .applauncher-pf .applauncher-pf-item,
  .navbar-pf-alt .navbar-utility .applauncher-pf .applauncher-pf-item,
  .navbar-pf .applauncher-pf .applauncher-pf-item,
  .navbar-pf-alt .applauncher-pf .applauncher-pf-item {
    width: 100%;
  }
  .navbar-pf .navbar-utility .applauncher-pf .applauncher-pf-link,
  .navbar-pf-alt .navbar-utility .applauncher-pf .applauncher-pf-link,
  .navbar-pf .applauncher-pf .applauncher-pf-link,
  .navbar-pf-alt .applauncher-pf .applauncher-pf-link {
    display: -ms-flexbox;
    display: flex;
    white-space: initial;
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-pf .navbar-utility .applauncher-pf-block-list .applauncher-pf-link,
  .navbar-pf-alt .navbar-utility .applauncher-pf-block-list .applauncher-pf-link,
  .navbar-pf .applauncher-pf-block-list .applauncher-pf-link,
  .navbar-pf-alt .applauncher-pf-block-list .applauncher-pf-link {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .navbar-pf .navbar-utility .applauncher-pf-block-list .applauncher-pf-link-icon,
  .navbar-pf-alt .navbar-utility .applauncher-pf-block-list .applauncher-pf-link-icon,
  .navbar-pf .applauncher-pf-block-list .applauncher-pf-link-icon,
  .navbar-pf-alt .applauncher-pf-block-list .applauncher-pf-link-icon {
    padding: 0;
  }
  .navbar-pf .navbar-utility .applauncher-pf-block-list .applauncher-pf-link-title,
  .navbar-pf-alt .navbar-utility .applauncher-pf-block-list .applauncher-pf-link-title,
  .navbar-pf .applauncher-pf-block-list .applauncher-pf-link-title,
  .navbar-pf-alt .applauncher-pf-block-list .applauncher-pf-link-title {
    margin-top: auto;
  }
  .navbar-pf .navbar-utility .applauncher-pf .dropdown-menu,
  .navbar-pf-alt .navbar-utility .applauncher-pf .dropdown-menu,
  .navbar-pf .applauncher-pf .dropdown-menu,
  .navbar-pf-alt .applauncher-pf .dropdown-menu {
    padding: 9px;
    min-width: 220px;
  }
  .navbar-pf .navbar-utility .applauncher-pf .applauncher-pf-link,
  .navbar-pf-alt .navbar-utility .applauncher-pf .applauncher-pf-link,
  .navbar-pf .applauncher-pf .applauncher-pf-link,
  .navbar-pf-alt .applauncher-pf .applauncher-pf-link {
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
  }
  .navbar-pf .navbar-utility .applauncher-pf .applauncher-pf-link:hover,
  .navbar-pf-alt .navbar-utility .applauncher-pf .applauncher-pf-link:hover,
  .navbar-pf .applauncher-pf .applauncher-pf-link:hover,
  .navbar-pf-alt .applauncher-pf .applauncher-pf-link:hover {
    background-color: #f5f5f5;
    border-color: #bbb;
    color: #0088ce;
    text-decoration: none;
    box-shadow: 0 0 2px 0 #d1d1d1;
  }
  .navbar-pf .navbar-utility .applauncher-pf .applauncher-pf-link-icon,
  .navbar-pf-alt .navbar-utility .applauncher-pf .applauncher-pf-link-icon,
  .navbar-pf .applauncher-pf .applauncher-pf-link-icon,
  .navbar-pf-alt .applauncher-pf .applauncher-pf-link-icon {
    font-size: 1.2em;
    text-align: center;
    width: 1.28571429em;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .navbar-pf .navbar-utility .applauncher-pf-block-list .applauncher-pf-item,
  .navbar-pf-alt .navbar-utility .applauncher-pf-block-list .applauncher-pf-item,
  .navbar-pf .applauncher-pf-block-list .applauncher-pf-item,
  .navbar-pf-alt .applauncher-pf-block-list .applauncher-pf-item {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .navbar-pf .navbar-utility .applauncher-pf-block-list .applauncher-pf-link,
  .navbar-pf-alt .navbar-utility .applauncher-pf-block-list .applauncher-pf-link,
  .navbar-pf .applauncher-pf-block-list .applauncher-pf-link,
  .navbar-pf-alt .applauncher-pf-block-list .applauncher-pf-link {
    -ms-flex-direction: column;
        flex-direction: column;
    text-align: center;
    padding: 15px 0;
    height: 100%;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .navbar-pf .navbar-utility .applauncher-pf:not(.applauncher-pf-block-list) .applauncher-pf-link,
  .navbar-pf-alt .navbar-utility .applauncher-pf:not(.applauncher-pf-block-list) .applauncher-pf-link,
  .navbar-pf .applauncher-pf:not(.applauncher-pf-block-list) .applauncher-pf-link,
  .navbar-pf-alt .applauncher-pf:not(.applauncher-pf-block-list) .applauncher-pf-link {
    padding: 9px;
  }
  .navbar-pf .navbar-utility .applauncher-pf:not(.applauncher-pf-block-list) .applauncher-pf-link-icon,
  .navbar-pf-alt .navbar-utility .applauncher-pf:not(.applauncher-pf-block-list) .applauncher-pf-link-icon,
  .navbar-pf .applauncher-pf:not(.applauncher-pf-block-list) .applauncher-pf-link-icon,
  .navbar-pf-alt .applauncher-pf:not(.applauncher-pf-block-list) .applauncher-pf-link-icon {
    -ms-flex: 1 0 0;
        flex: 1 0 0;
    text-align: left;
  }
  .navbar-pf .navbar-utility .applauncher-pf:not(.applauncher-pf-block-list) .applauncher-pf-link-title,
  .navbar-pf-alt .navbar-utility .applauncher-pf:not(.applauncher-pf-block-list) .applauncher-pf-link-title,
  .navbar-pf .applauncher-pf:not(.applauncher-pf-block-list) .applauncher-pf-link-title,
  .navbar-pf-alt .applauncher-pf:not(.applauncher-pf-block-list) .applauncher-pf-link-title {
    -ms-flex: 3;
        flex: 3;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .navbar-pf .navbar-utility .applauncher-pf .applauncher-pf-link-icon,
  .navbar-pf-alt .navbar-utility .applauncher-pf .applauncher-pf-link-icon,
  .navbar-pf .applauncher-pf .applauncher-pf-link-icon,
  .navbar-pf-alt .applauncher-pf .applauncher-pf-link-icon {
    font-size: 2em;
  }
}
@media (min-width: 768px) {
  .navbar-iconic .navbar-utility .applauncher-pf.dropdown > .dropdown-toggle,
  .navbar-iconic .applauncher-pf.dropdown > .dropdown-toggle,
  .navbar-iconic .navbar-utility .applauncher-pf .dropdown-toggle,
  .navbar-iconic .applauncher-pf .dropdown-toggle {
    padding: 22px 10px;
  }
}
.blank-slate-pf {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 1px;
  margin-bottom: 20px;
  padding: 30px;
  text-align: center;
}
@media (min-width: 768px) {
  .blank-slate-pf {
    padding: 60px 60px;
  }
}
@media (min-width: 992px) {
  .blank-slate-pf {
    padding: 90px 120px;
  }
}
.blank-slate-pf .blank-slate-pf-icon {
  color: #9c9c9c;
  font-size: 57.6px;
  line-height: 57.6px;
}
.blank-slate-pf .blank-slate-pf-main-action {
  margin-top: 20px;
}
.blank-slate-pf .blank-slate-pf-secondary-action {
  margin-top: 20px;
}
.blank-slate-pf .blank-slate-pf-secondary-action a {
  display: inline-block;
  padding: 5px 15px;
}
.blank-slate-pf .blank-slate-pf-secondary-action a + a:before {
  background-color: #8b8d8f;
  content: "";
  display: inline-block;
  height: 10px;
  left: calc(-15px - 2px);
  position: relative;
  width: 1px;
}
.blank-slate-pf button {
  margin-bottom: 5px;
}
.blank-slate-pf.blank-slate-content-pf {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 20px;
}
.combobox-container.combobox-selected .glyphicon-remove {
  display: inline-block;
}
.combobox-container .caret {
  margin-left: 0;
}
.combobox-container .combobox::-ms-clear {
  display: none;
}
.combobox-container .dropdown-menu {
  margin-top: -1px;
  width: 100%;
}
.combobox-container .glyphicon-remove {
  display: none;
  top: auto;
  width: 12px;
}
.combobox-container .glyphicon-remove:before {
  content: "\e60b";
  font-family: "PatternFlyIcons-webfont";
}
.combobox-container .input-group-addon {
  background-color: #f1f1f1;
  background-image: linear-gradient(to bottom, #fafafa 0%, #ededed 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffafafa', endColorstr='#ffededed', GradientType=0);
  background-repeat: repeat-x;
  border-color: #bbb;
  color: #4d5258;
  position: relative;
}
.combobox-container .input-group-addon:hover,
.combobox-container .input-group-addon:focus,
.combobox-container .input-group-addon:active,
.combobox-container .input-group-addon.active,
.open .dropdown-toggle.combobox-container .input-group-addon {
  background-color: #f1f1f1;
  background-image: none;
  border-color: #bbb;
  color: #4d5258;
}
.combobox-container .input-group-addon:active,
.combobox-container .input-group-addon.active,
.open .dropdown-toggle.combobox-container .input-group-addon {
  background-image: none;
}
.combobox-container .input-group-addon:active:hover,
.combobox-container .input-group-addon.active:hover,
.open .dropdown-toggle.combobox-container .input-group-addon:hover,
.combobox-container .input-group-addon:active:focus,
.combobox-container .input-group-addon.active:focus,
.open .dropdown-toggle.combobox-container .input-group-addon:focus,
.combobox-container .input-group-addon:active.focus,
.combobox-container .input-group-addon.active.focus,
.open .dropdown-toggle.combobox-container .input-group-addon.focus {
  background-color: #e5e5e5;
  border-color: #a9a9a9;
}
.combobox-container .input-group-addon.disabled,
.combobox-container .input-group-addon[disabled],
fieldset[disabled] .combobox-container .input-group-addon,
.combobox-container .input-group-addon.disabled:hover,
.combobox-container .input-group-addon[disabled]:hover,
fieldset[disabled] .combobox-container .input-group-addon:hover,
.combobox-container .input-group-addon.disabled:focus,
.combobox-container .input-group-addon[disabled]:focus,
fieldset[disabled] .combobox-container .input-group-addon:focus,
.combobox-container .input-group-addon.disabled:active,
.combobox-container .input-group-addon[disabled]:active,
fieldset[disabled] .combobox-container .input-group-addon:active,
.combobox-container .input-group-addon.disabled.active,
.combobox-container .input-group-addon[disabled].active,
fieldset[disabled] .combobox-container .input-group-addon.active {
  background-color: #f1f1f1;
  border-color: #bbb;
}
.combobox-container .input-group-addon:active {
  box-shadow: inset 0 2px 8px rgba(3, 3, 3, 0.2);
}
.bootstrap-datepicker.form-control[readonly] {
  background-color: #fff;
  border-color: #bbb !important;
  color: #363636;
  box-shadow: inset 0 1px 1px rgba(3, 3, 3, 0.075);
}
.bootstrap-datepicker.form-control[readonly]:focus {
  border-color: #0088ce;
  outline: 0 !important;
  box-shadow: inset 0 1px 1px rgba(3, 3, 3, 0.075), 0 0 8px rgba(0, 136, 206, 0.6);
}
.bootstrap-datepicker.form-control[readonly]:focus {
  border-color: #0088ce !important;
}
.has-error .bootstrap-datepicker.form-control[readonly]:focus {
  border-color: #990000;
  box-shadow: inset 0 1px 1px rgba(3, 3, 3, 0.075), 0 0 6px #ff3333;
}
.has-success .bootstrap-datepicker.form-control[readonly]:focus {
  border-color: #2b542c;
  box-shadow: inset 0 1px 1px rgba(3, 3, 3, 0.075), 0 0 6px #67b168;
}
.has-warning .bootstrap-datepicker.form-control[readonly]:focus {
  border-color: #bb6106;
  box-shadow: inset 0 1px 1px rgba(3, 3, 3, 0.075), 0 0 6px #faad60;
}
.bootstrap-datepicker.form-control[readonly]:hover {
  border-color: #7dc3e8 !important;
}
.has-error .bootstrap-datepicker.form-control[readonly]:hover {
  border-color: #990000 !important;
}
.has-success .bootstrap-datepicker.form-control[readonly]:hover {
  border-color: #2b542c !important;
}
.has-warning .bootstrap-datepicker.form-control[readonly]:hover {
  border-color: #bb6106 !important;
}
.has-error .bootstrap-datepicker.form-control[readonly] {
  border-color: #cc0000 !important;
}
.has-success .bootstrap-datepicker.form-control[readonly] {
  border-color: #3c763d !important;
}
.has-warning .bootstrap-datepicker.form-control[readonly] {
  border-color: #ec7a08 !important;
}
.datepicker {
  border-radius: 1px;
}
.datepicker .datepicker-switch,
.datepicker tfoot .clear,
.datepicker tfoot .today {
  font-size: 14px;
  font-weight: 500;
}
.datepicker .next,
.datepicker .prev {
  font-weight: 500;
}
.datepicker table tr td,
.datepicker table tr th {
  border-radius: 1px;
}
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background: #0088ce !important;
  color: #fff !important;
  text-shadow: none;
}
.datepicker table tr td.day:hover,
.datepicker table tr td.day.focused {
  background: #def3ff;
}
.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
  text-shadow: none;
}
.datepicker table tr td span {
  border-radius: 1px;
}
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background: #0088ce;
  text-shadow: none;
}
.datepicker table tr td span:hover {
  background: #def3ff;
}
.datepicker thead tr:first-child th:hover,
.datepicker tfoot tr th:hover {
  background: #def3ff;
}
.input-daterange input:first-child {
  border-radius: 1px 0 0 1px;
}
.input-daterange input:last-child {
  border-radius: 0 1px 1px 0;
}
.input-daterange .input-group-addon {
  background-color: #f1f1f1;
  border-color: #bbb;
  line-height: 1.66666667;
  padding: 2px 6px;
}
.bootstrap-select.btn-group.form-control {
  margin-bottom: 0;
}
.bootstrap-select.btn-group .btn {
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.bootstrap-select.btn-group .btn:hover {
  border-color: #7dc3e8;
}
.bootstrap-select.btn-group .btn .caret {
  margin-top: -4px;
}
.bootstrap-select.btn-group .btn:focus {
  border-color: #0088ce;
  outline: 0 !important;
  box-shadow: inset 0 1px 1px rgba(3, 3, 3, 0.075), 0 0 8px rgba(0, 136, 206, 0.6);
}
.has-error .bootstrap-select.btn-group .btn {
  border-color: #cc0000;
}
.has-error .bootstrap-select.btn-group .btn:focus {
  border-color: #990000;
  box-shadow: inset 0 1px 1px rgba(3, 3, 3, 0.075), 0 0 6px #ff3333;
}
.has-success .bootstrap-select.btn-group .btn {
  border-color: #3c763d;
}
.has-success .bootstrap-select.btn-group .btn:focus {
  border-color: #2b542c;
  box-shadow: inset 0 1px 1px rgba(3, 3, 3, 0.075), 0 0 6px #67b168;
}
.has-warning .bootstrap-select.btn-group .btn {
  border-color: #ec7a08;
}
.has-warning .bootstrap-select.btn-group .btn:focus {
  border-color: #bb6106;
  box-shadow: inset 0 1px 1px rgba(3, 3, 3, 0.075), 0 0 6px #faad60;
}
.bootstrap-select.btn-group .dropdown-menu > .active > a,
.bootstrap-select.btn-group .dropdown-menu > .active > a:active {
  background-color: #def3ff !important;
  border-color: #bee1f4 !important;
  color: #363636 !important;
}
.bootstrap-select.btn-group .dropdown-menu > .active > a small,
.bootstrap-select.btn-group .dropdown-menu > .active > a:active small {
  color: #9c9c9c !important;
}
.bootstrap-select.btn-group .dropdown-menu > .disabled > a {
  color: #9c9c9c !important;
}
.bootstrap-select.btn-group .dropdown-menu > .selected > a {
  background-color: #0088ce !important;
  border-color: #0088ce !important;
  color: #fff !important;
}
.bootstrap-select.btn-group .dropdown-menu > .selected > a small {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bootstrap-select.btn-group .dropdown-menu .divider {
  background: #ededed !important;
  margin: 4px 1px !important;
}
.bootstrap-select.btn-group .dropdown-menu dt {
  color: #8b8d8f;
  font-weight: normal;
  padding: 1px 10px;
}
.bootstrap-select.btn-group .dropdown-menu li > a.opt {
  padding: 1px 10px;
}
.bootstrap-select.btn-group .dropdown-menu li a:active small {
  color: rgba(255, 255, 255, 0.5) !important;
}
.bootstrap-select.btn-group .dropdown-menu li a:hover small,
.bootstrap-select.btn-group .dropdown-menu li a:focus small {
  color: #9c9c9c;
}
.bootstrap-select.btn-group .dropdown-menu li:not(.disabled) a:hover small,
.bootstrap-select.btn-group .dropdown-menu li:not(.disabled) a:focus small {
  color: #9c9c9c;
}
.slider-tick-label-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-left: 0!important;
}
.slider-tick-label {
  width: auto !important;
}
.slider .tooltip {
  top: -10px;
}
.slider-track {
  background-color: #ededed;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.1), 0 0px 2px #ededed;
  border: 1px solid #bbb;
}
.slider-selection {
  background-image: linear-gradient(to bottom, #0088ce 0%, #39a5dc 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088ce', endColorstr='#ff39a5dc', GradientType=0);
  background-repeat: repeat-x;
}
.slider-handle {
  width: 16px;
  height: 16px;
  border: 1px solid #bbb;
}
.slider-tick {
  background-color: transparent !important;
  background-image: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) 2px, transparent 2px) !important;
  box-shadow: none;
}
.slider-pf {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.slider-pf * {
  margin-right: 10px;
}
.slider-pf *:last-child {
  margin: 0;
}
.slider-pf .slider {
  width: auto;
  -ms-flex: 1 1 100%;
      flex: 1 1 100%;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default {
  background: #fafafa;
}
.bootstrap-switch .bootstrap-switch-label {
  background: #f1f1f1;
  box-shadow: 0 0 2px rgba(3, 3, 3, 0.4);
  background-image: linear-gradient(to bottom, #fafafa 0%, #ededed 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffafafa', endColorstr='#ffededed', GradientType=0);
  background-repeat: repeat-x;
  position: relative;
  z-index: 9;
}
.bootstrap-touchspin .input-group-btn-vertical > .btn {
  padding-bottom: 6px;
  padding-top: 6px;
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border-bottom-right-radius: 1px;
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-top-right-radius: 1px;
}
.bootstrap-touchspin .input-group-btn-vertical i {
  font-size: 8px;
  left: 6px;
  top: 2px;
}
.bootstrap-touchspin .input-group-btn-vertical i.fa-angle-down,
.bootstrap-touchspin .input-group-btn-vertical i.fa-angle-up {
  font-size: 12px;
  line-height: 12px;
  top: 0;
}
.bootstrap-touchspin .input-group-btn-vertical i.fa-angle-down,
.bootstrap-touchspin .input-group-btn-vertical i.fa-angle-up {
  left: 7px;
}
.treeview .list-group {
  border-top: 0;
}
.treeview .list-group-item {
  background: transparent;
  border-bottom: 1px solid transparent !important;
  border-top: 1px solid transparent !important;
  cursor: default !important;
  margin-bottom: 0;
  overflow: hidden;
  padding: 0 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.treeview .list-group-item:hover {
  background: none !important;
}
.treeview .list-group-item.node-selected {
  background: none !important;
  border-color: transparent !important;
  color: inherit !important;
}
.treeview .list-group-item.node-check-changed span.node-icon,
.treeview .list-group-item.node-check-changed span.text {
  color: #39a5dc;
}
.treeview span.icon {
  display: inline-block;
  font-size: 13px;
  min-width: 10px;
  text-align: center;
}
.treeview span.icon > [class*="fa-angle"] {
  font-size: 15px;
}
.treeview span.icon.check-icon {
  margin-right: 10px;
}
.treeview span.icon.expand-icon {
  cursor: pointer !important;
}
.treeview span.image {
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 1.19em;
  line-height: 1em;
  margin-right: 5px;
  vertical-align: middle;
  width: 12px;
}
.treeview span.indent {
  margin-right: 5px;
}
.treeview .node-disabled {
  color: #d1d1d1;
  cursor: not-allowed;
}
.treeview .node-disabled span.expand-icon {
  cursor: default !important;
}
.treeview .node-hidden {
  display: none;
}
.treeview-pf-hover .list-group-item {
  cursor: pointer !important;
}
.treeview-pf-hover .list-group-item:hover {
  background-color: #def3ff !important;
  border-color: #bee1f4 !important;
}
.treeview-pf-select .list-group-item {
  cursor: pointer !important;
}
.treeview-pf-select .list-group-item.node-selected {
  background: #0088ce !important;
  border-color: #0088ce !important;
  color: #fff !important;
}
.card-pf {
  background: #fff;
  border-top: 2px solid transparent;
  box-shadow: 0 1px 1px rgba(3, 3, 3, 0.175);
  margin: 0 -10px 20px;
  padding: 0 20px;
}
.card-pf.card-pf-accented {
  border-top-color: #39a5dc;
}
.card-pf.card-pf-aggregate-status .card-pf-aggregate-status-notifications a,
.card-pf.card-pf-aggregate-status .card-pf-title a {
  color: #363636;
}
.card-pf.card-pf-aggregate-status .card-pf-aggregate-status-notifications a.add,
.card-pf.card-pf-aggregate-status .card-pf-title a.add {
  color: #0088ce;
}
.card-pf.card-pf-aggregate-status .card-pf-aggregate-status-notifications a.add:hover,
.card-pf.card-pf-aggregate-status .card-pf-title a.add:hover {
  color: #00659c;
}
.card-pf.card-pf-aggregate-status .card-pf-aggregate-status-notifications a:hover,
.card-pf.card-pf-aggregate-status .card-pf-title a:hover {
  color: #00659c;
}
.card-pf.card-pf-aggregate-status {
  padding: 0 10px;
  text-align: center;
}
.card-pf.card-pf-aggregate-status-mini {
  padding-bottom: 10px;
  position: relative;
}
@media (min-width: 768px) {
  .card-pf.card-pf-bleed-left {
    margin-left: -20px;
  }
  .card-pf.card-pf-bleed-right {
    border-right: 1px solid #d1d1d1;
    margin-right: -20px;
  }
}
.card-pf-aggregate-status-notifications {
  font-size: 24px;
  font-weight: 300;
}
.card-pf-aggregate-status-mini .card-pf-aggregate-status-notifications {
  line-height: 1;
}
.card-pf-aggregate-status-notifications .card-pf-aggregate-status-notification + .card-pf-aggregate-status-notification {
  border-left: 1px solid #d1d1d1;
  margin-left: 3px;
  padding-left: 10px;
}
.card-pf-aggregate-status-notifications .fa,
.card-pf-aggregate-status-notifications .fas,
.card-pf-aggregate-status-notifications .far,
.card-pf-aggregate-status-notifications .fab,
.card-pf-aggregate-status-notifications .fal,
.card-pf-aggregate-status-notifications .pficon {
  font-size: 18px;
  margin-right: 7px;
}
.card-pf-body {
  margin: 20px 0 0;
  padding: 0 0 20px;
}
.card-pf-aggregate-status .card-pf-body {
  margin-top: 10px;
  padding-bottom: 10px;
}
.card-pf-aggregate-status-mini .card-pf-body {
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0;
  position: absolute;
  right: 20px;
  top: 15px;
}
.card-pf-utilization .card-pf-title + .card-pf-body {
  margin-top: -8px;
}
.card-pf-body > *:last-child {
  margin-bottom: 0;
}
.card-pf-footer {
  background-color: #fafafa;
  border-top: 1px solid #d1d1d1;
  margin: 0 -20px !important;
  padding: 20px 20px 10px;
}
.card-pf-footer a .fa,
.card-pf-footer a .fas,
.card-pf-footer a .far,
.card-pf-footer a .fab,
.card-pf-footer a .fal,
.card-pf-footer a .pficon {
  margin-right: 5px;
}
.card-pf-footer .card-pf-time-frame-filter {
  margin-top: -2px;
}
.card-pf-link-with-icon {
  padding-left: 21px;
  position: relative;
}
.card-pf-link-with-icon .fa,
.card-pf-link-with-icon .fas,
.card-pf-link-with-icon .far,
.card-pf-link-with-icon .fab,
.card-pf-link-with-icon .fal,
.card-pf-link-with-icon .pficon {
  font-size: 16px;
  left: 0;
  position: absolute;
  top: 0;
}
.card-pf-heading .card-pf-time-frame-filter,
.card-pf-footer .card-pf-time-frame-filter {
  float: right;
  margin-left: 20px;
}
.card-pf-heading {
  border-bottom: 1px solid #d1d1d1;
  margin: 0 -20px 20px;
  padding: 0 20px 0;
}
.card-pf-heading .card-pf-time-frame-filter {
  margin-top: -5px;
}
.card-pf-heading-details {
  float: right;
  font-size: 10px;
}
.card-pf-subtitle {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
}
[class^="col"] .card-pf-subtitle {
  margin-top: 0;
}
@media (max-width: 767px) {
  .card-pf-body [class^="col"] + [class^="col"] > .card-pf-subtitle {
    margin-top: 40px;
  }
}
.card-pf-title {
  font-size: 16px;
  font-weight: 400;
  margin: 20px 0;
  padding: 0;
}
.card-pf-aggregate-status .card-pf-title {
  font-size: 14px;
  margin: 10px 0 0;
}
.card-pf-aggregate-status .card-pf-title .fa,
.card-pf-aggregate-status .card-pf-title .fas,
.card-pf-aggregate-status .card-pf-title .far,
.card-pf-aggregate-status .card-pf-title .fab,
.card-pf-aggregate-status .card-pf-title .fal,
.card-pf-aggregate-status .card-pf-title .pficon {
  color: #292e34;
  font-size: 16px;
  margin-right: 7px;
}
.card-pf-title .card-pf-aggregate-status-count {
  font-size: 16px;
}
.card-pf-aggregate-status-mini .card-pf-title .card-pf-aggregate-status-count {
  display: block;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 3px;
}
.card-pf-aggregate-status-mini .card-pf-title {
  font-size: 12px;
  margin-top: 5px;
}
.card-pf-aggregate-status-mini .card-pf-title a {
  display: inline-block;
}
.card-pf-aggregate-status-mini .card-pf-title .fa,
.card-pf-aggregate-status-mini .card-pf-title .fas,
.card-pf-aggregate-status-mini .card-pf-title .far,
.card-pf-aggregate-status-mini .card-pf-title .fab,
.card-pf-aggregate-status-mini .card-pf-title .fal,
.card-pf-aggregate-status-mini .card-pf-title .pficon {
  font-size: 26px;
  margin-right: 0;
  min-width: 26px;
  position: absolute;
  left: 20px;
  text-align: center;
  top: 15px;
}
.card-pf-utilization-details {
  border-bottom: 1px solid #d1d1d1;
  display: table;
  margin: 12px 0 15px;
  padding: 0 0 15px;
  width: 100%;
}
.card-pf-utilization-details .card-pf-utilization-card-details-count,
.card-pf-utilization-details .card-pf-utilization-card-details-description {
  float: left;
  line-height: 1;
}
.card-pf-utilization-details .card-pf-utilization-card-details-count {
  font-size: 26px;
  font-weight: 300;
  margin-right: 10px;
}
.card-pf-utilization-details .card-pf-utilization-card-details-line-1,
.card-pf-utilization-details .card-pf-utilization-card-details-line-2 {
  display: block;
}
.card-pf-utilization-details .card-pf-utilization-card-details-line-1 {
  font-size: 10px;
  margin-bottom: 2px;
}
.cards-pf {
  background: #f5f5f5;
}
.cards-pf .row-cards-pf {
  padding: 0 20px;
}
.cards-pf .row-cards-pf:first-child {
  padding-top: 20px;
}
.container-cards-pf {
  margin-top: 20px;
}
.row-cards-pf {
  margin-left: -10px;
  margin-right: -10px;
}
.card-pf-view {
  border: 2px solid transparent;
}
.card-pf-view .card-pf-heading-kebab .dropdown-kebab-pf {
  margin-top: -3px;
}
.card-pf-view .card-pf-heading-kebab + .progress-pf-legend p {
  margin-bottom: 0;
}
.card-pf-view .card-pf-heading-kebab + .progress-pf-legend .progress {
  margin-bottom: 7px;
  margin-top: 16px;
}
.card-pf-view .card-pf-info {
  margin-top: 15px;
}
.card-pf-view .card-pf-info strong {
  font-size: 13px;
  margin-right: 10px;
}
.card-pf-view .card-pf-item {
  display: inline-block;
  font-size: 16px;
  padding: 0 13px 0 15px;
}
.card-pf-view .card-pf-item:first-child {
  padding-left: 0;
}
.card-pf-view .card-pf-item:last-child {
  padding-right: 0;
}
.card-pf-view .card-pf-item + .card-pf-item {
  border-left: 1px solid #d1d1d1;
}
.card-pf-view .card-pf-item .fa-check {
  color: #3f9c35;
}
.card-pf-view .card-pf-item .fa + .card-pf-item-text,
.card-pf-view .card-pf-item .fas + .card-pf-item-text,
.card-pf-view .card-pf-item .far + .card-pf-item-text,
.card-pf-view .card-pf-item .fab + .card-pf-item-text,
.card-pf-view .card-pf-item .fal + .card-pf-item-text,
.card-pf-view .card-pf-item .pficon + .card-pf-item-text {
  margin-left: 10px;
}
.card-pf-view .card-pf-items {
  margin-top: 15px;
}
.card-pf-view .card-pf-title {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 0;
  margin-top: 15px;
}
.card-pf-view .card-pf-title .fa,
.card-pf-view .card-pf-title .fas,
.card-pf-view .card-pf-title .far,
.card-pf-view .card-pf-title .fab,
.card-pf-view .card-pf-title .fal,
.card-pf-view .card-pf-title .pficon {
  font-size: 18px;
  margin-right: 2px;
}
.col-lg-2 .card-pf-view .card-pf-title {
  font-size: 16px;
}
.card-pf-view .card-pf-top-element .card-pf-icon-circle {
  border: 2px solid #39a5dc;
  border-radius: 50%;
  display: block;
  font-size: 46px;
  height: 106px;
  line-height: 102px;
  margin: 0 auto;
  text-align: center;
  width: 106px;
}
.col-lg-2 .card-pf-view .card-pf-top-element .card-pf-icon-circle {
  font-size: 23px;
  height: 54px;
  line-height: 50px;
  width: 54px;
}
.card-pf-view .card-pf-view-checkbox {
  position: absolute;
  top: 11px;
  left: 15px;
}
.card-pf-view .card-pf-view-checkbox input[type=checkbox] {
  display: none;
}
.card-pf-view.card-pf-view-multi-select .card-pf-view-checkbox input[type=checkbox] {
  display: block;
}
@media (min-width: 768px) {
  .card-pf-view.card-pf-view-multi-select .card-pf-view-checkbox input[type=checkbox] {
    visibility: hidden;
  }
  .card-pf-view.card-pf-view-multi-select .card-pf-view-checkbox input[type=checkbox]:checked {
    visibility: visible;
  }
}
.card-pf-view.card-pf-view-multi-select:hover .card-pf-view-checkbox input[type=checkbox] {
  visibility: visible;
}
.card-pf-view.card-pf-view-select {
  position: relative;
}
.card-pf-view.card-pf-view-select:hover {
  box-shadow: 0 1px 6px rgba(3, 3, 3, 0.35);
}
.card-pf-view.card-pf-view-select.active {
  border: 2px solid #39a5dc;
}
.card-pf-view.card-pf-view-single-select {
  cursor: pointer;
}
.card-pf-view.card-pf-view-xs .card-pf-title {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 10px;
}
.card-pf-view.card-pf-view-xs .card-pf-title .fa,
.card-pf-view.card-pf-view-xs .card-pf-title .fas,
.card-pf-view.card-pf-view-xs .card-pf-title .far,
.card-pf-view.card-pf-view-xs .card-pf-title .fab,
.card-pf-view.card-pf-view-xs .card-pf-title .fal,
.card-pf-view.card-pf-view-xs .card-pf-title .pficon {
  font-size: 14px;
  margin-right: 5px;
}
.bullet-chart-pf {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.bullet-chart-pf.bullet-chart-pf-vertical {
  height: 100%;
  -ms-flex-direction: row;
      flex-direction: row;
}
.bullet-chart-pf-chart {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
      flex: 1;
  -ms-flex-direction: row;
      flex-direction: row;
  width: 100%;
}
.bullet-chart-pf-vertical .bullet-chart-pf-chart {
  -ms-flex: initial;
      flex: initial;
  -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
  height: 100%;
  width: initial;
}
.bullet-chart-pf-title-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  margin: 10px 0 25px;
  padding-right: 10px;
  text-align: right;
}
.bullet-chart-pf-vertical .bullet-chart-pf-title-container {
  margin-bottom: 0;
  padding-right: 0;
  text-align: center;
}
.bullet-chart-pf-title {
  font-size: 14px;
}
.bullet-chart-pf-details {
  color: #9c9c9c;
  font-size: 12px;
  line-height: 9px;
}
.bullet-chart-pf-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 5;
      flex: 5;
}
.bullet-chart-pf-vertical .bullet-chart-pf-container {
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
  -ms-flex: initial;
      flex: initial;
}
.bullet-chart-pf-vertical .bullet-chart-pf-container.show-axis {
  margin-left: -25px;
}
.bullet-chart-pf-data-container {
  -ms-flex: 1;
      flex: 1;
  position: relative;
}
.bullet-chart-pf-vertical .bullet-chart-pf-data-container {
  height: initial;
}
.bullet-chart-pf-vertical-data-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 5;
      flex: 5;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
}
.bullet-chart-pf-vertical-data-container .bullet-chart-pf-data-container {
  -ms-flex: initial;
      flex: initial;
}
.bullet-chart-pf-values-container {
  height: 20px;
  margin: 20px 0;
  position: relative;
}
.bullet-chart-pf-vertical .bullet-chart-pf-values-container {
  height: 100%;
  margin: 0 20px;
  width: 20px;
}
.bullet-chart-pf-value-bar {
  height: 100%;
  position: absolute;
  transition: left 600ms ease;
}
.bullet-chart-pf-vertical .bullet-chart-pf-value-bar {
  bottom: 0;
  height: initial;
  top: initial;
  width: 100%;
}
.bullet-chart-pf-value-dot {
  border-radius: 50%;
  border-style: solid;
  border-width: 0;
  height: 20px;
  -ms-transform: translateX(-50%);
      transform: translateX(-50%);
  position: absolute;
  transition: left 600ms ease;
  width: 20px;
}
.bullet-chart-pf-vertical .bullet-chart-pf-value-dot {
  top: initial;
  -ms-transform: translateY(50%);
      transform: translateY(50%);
}
.bullet-chart-pf-threshold-indicator {
  border: 0 solid #72767b;
  border-left-width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 400;
}
.bullet-chart-pf-vertical .bullet-chart-pf-threshold-indicator {
  border-left-width: 0;
  border-top-width: 1px;
  height: 1px;
  left: 0;
  top: initial;
  width: 100%;
}
.bullet-chart-pf-threshold-indicator.error {
  border-color: #cc0000;
}
.bullet-chart-pf-threshold-indicator.warning {
  border-color: #ec7a08;
}
.bullet-chart-pf-range-bar {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}
.bullet-chart-pf-vertical .bullet-chart-pf-range-bar {
  bottom: 0;
  height: initial;
  top: initial;
  width: 100%;
}
.bullet-chart-pf-range-bar.range-1 {
  background-color: #f5f5f5;
}
.bullet-chart-pf-range-bar.range-2 {
  background-color: #ededed;
}
.bullet-chart-pf-range-bar.range-3 {
  background-color: #dbdbdb;
}
.bullet-chart-pf-axis {
  border-top: 1px solid #72767b;
  height: 25px;
  position: relative;
}
.bullet-chart-pf-vertical .bullet-chart-pf-axis {
  height: initial;
  width: 25px;
  border-right: 1px solid #72767b;
  border-top: 0;
}
.bullet-chart-pf-title-container .bullet-chart-pf-axis {
  border-top: 0;
}
.bullet-chart-pf-axis-tic {
  padding-top: 5px;
  position: absolute;
  text-align: center;
  width: 30px;
}
.bullet-chart-pf-vertical .bullet-chart-pf-axis-tic {
  padding-top: 0;
  padding-right: 5px;
  width: 100%;
  text-align: right;
  line-height: 21px;
}
.bullet-chart-pf-axis-tic:before {
  border-left: 1px solid #72767b;
  content: "";
  height: 3px;
  left: 50%;
  position: absolute;
  top: -1px;
}
.bullet-chart-pf-vertical .bullet-chart-pf-axis-tic:before {
  border-left: 0;
  border-top: 1px solid #72767b;
  height: initial;
  left: initial;
  right: -1px;
  top: calc(50% - 1px);
  width: 3px;
}
.bullet-chart-pf-overflow {
  -ms-flex: 1;
      flex: 1;
}
.bullet-chart-pf-legend {
  margin-top: 7px;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: center;
      justify-content: center;
}
.bullet-chart-pf-vertical .bullet-chart-pf-legend {
  -ms-flex-direction: column;
      flex-direction: column;
  margin-left: 10px;
  margin-top: 0;
  text-align: left;
}
.bullet-chart-pf-legend-item {
  display: inline-block;
  margin-right: 10px;
}
.bullet-chart-pf-vertical .bullet-chart-pf-legend-item {
  margin-right: 0;
  margin-top: 10px;
}
.bullet-chart-pf-legend-item-box {
  display: inline-block;
  height: 13px;
  margin-right: 3px;
  width: 13px;
}
.bullet-chart-pf-legend-item-box.range-1 {
  background-color: #f5f5f5;
}
.bullet-chart-pf-legend-item-box.range-2 {
  background-color: #ededed;
}
.bullet-chart-pf-legend-item-box.range-3 {
  background-color: #dbdbdb;
}
.bullet-chart-pf-legend-item-text {
  display: inline-block;
  line-height: 14px;
  max-width: 150px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  top: 1px;
  white-space: nowrap;
  word-wrap: normal;
}
.c3 path {
  stroke: #d1d1d1;
}
.c3 svg {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
.c3-axis-x .tick line {
  stroke: #d1d1d1;
}
.c3-axis-y .tick line {
  display: none;
}
.c3-chart-arc path {
  stroke: #fff;
}
.c3-grid line {
  stroke: #d1d1d1;
}
.c3-line {
  stroke-width: 2px;
}
.c3-tooltip {
  background: #393f44;
  box-shadow: none;
  filter: alpha(opacity=90);
  opacity: 0.9;
}
.c3-tooltip td {
  background: transparent;
  border: 0;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
}
.c3-tooltip th {
  background: transparent;
  font-size: 12px;
  padding: 5px 10px 0;
  border-bottom: solid 2px #030303;
}
.c3-tooltip tr {
  border: 0;
}
.c3-tooltip tr + tr > td {
  padding-top: 0;
}
.c3-tooltip-sparkline,
.donut-tooltip-pf {
  background: #393f44;
  color: #fff;
  filter: alpha(opacity=90);
  opacity: 0.9;
  padding: 2px 6px;
}
.c3-xgrid,
.c3-ygrid {
  stroke-dasharray: 0 0;
}
.chart-pf-sparkline {
  margin-left: -5px;
  margin-right: -5px;
}
.donut-title-big-pf {
  font-size: 30px;
  font-weight: 300;
}
.donut-title-small-pf {
  font-size: 12px;
  font-weight: 400;
}
.line-chart-pf .c3-zoom-rect {
  opacity: 1 !important;
  fill: #fafafa;
  stroke: #d1d1d1;
  stroke-width: 1px;
}
.pct-donut-chart-pf .pct-donut-chart-pf-label {
  display: block;
}
.pct-donut-chart-pf.pct-donut-chart-pf-left,
.pct-donut-chart-pf.pct-donut-chart-pf-right,
.pct-donut-chart-pf .pct-donut-chart-pf-left,
.pct-donut-chart-pf .pct-donut-chart-pf-right {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
.pct-donut-chart-pf.pct-donut-chart-pf-left,
.pct-donut-chart-pf.pct-donut-chart-pf-right {
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.pct-donut-chart-pf.pct-donut-chart-pf-left,
.pct-donut-chart-pf .pct-donut-chart-pf-left {
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
}
.close {
  text-shadow: none;
  z-index: 1;
  position: relative;
  filter: alpha(opacity=60);
  opacity: 0.6;
}
.close:hover,
.close:focus {
  filter: alpha(opacity=90);
  opacity: 0.9;
}
.contextselector-pf {
  float: left;
}
.contextselector-pf .bootstrap-select {
  width: 202px !important;
}
@media (min-width: 480px) {
  .contextselector-pf .bootstrap-select {
    width: 242px !important;
  }
}
.contextselector-pf .bootstrap-select.open > .dropdown-toggle,
.contextselector-pf .bootstrap-select.open > .dropdown-toggle:hover,
.contextselector-pf .bootstrap-select.open > .dropdown-toggle:focus {
  background: rgba(255, 255, 255, 0.24);
  color: #fff;
}
.contextselector-pf .bootstrap-select.open > .dropdown-toggle:focus {
  outline: 0 !important;
}
.contextselector-pf .bootstrap-select > .dropdown-toggle {
  background: none;
  border: 0;
  box-shadow: none !important;
  color: #d1d1d1;
  font-weight: normal;
  padding-bottom: 19px;
  padding-left: 10px;
  padding-top: 19px;
}
.contextselector-pf .bootstrap-select > .dropdown-toggle:focus {
  outline: thin dotted !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px !important;
}
.contextselector-pf .bootstrap-select > .dropdown-toggle:hover {
  outline: 0 !important;
}
.contextselector-pf .bootstrap-select > .dropdown-toggle:focus,
.contextselector-pf .bootstrap-select > .dropdown-toggle:hover {
  background-color: rgba(255, 255, 255, 0.14);
}
.contextselector-pf .bootstrap-select > .dropdown-toggle .filter-option {
  text-overflow: ellipsis;
  position: relative;
}
.contextselector-pf .bootstrap-select .dropdown-menu li a span.text {
  display: block;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.layout-pf-fixed .navbar-pf-vertical.navbar-pf-contextselector {
  z-index: 1040;
}
@media (min-width: 992px) {
  .navbar-pf-vertical .nav.contextselector-pf {
    margin-left: 10px;
  }
}
.contextselector-pf {
  float: left;
}
.contextselector-pf-title {
  display: inline-block;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 170px;
}
@media (min-width: 480px) {
  .contextselector-pf-title {
    width: 210px;
  }
}
@media (min-width: 768px) {
  .contextselector-pf .contextselector-pf-list {
    max-height: 200px;
    overflow-y: auto;
  }
}
.contextselector-pf .contextselector-pf-list a {
  color: #393f44;
  display: block;
}
.contextselector-pf .contextselector-pf-list li {
  border-color: transparent;
  border-style: solid;
  border-width: 1px 0;
  padding: 1px 10px;
}
.contextselector-pf .contextselector-pf-list li:hover {
  background: #def3ff;
  border-color: #bee1f4;
}
.contextselector-pf .contextselector-pf-list li:hover a {
  text-decoration: none;
}
.contextselector-pf .dropdown.open,
.contextselector-pf .dropdown:hover {
  background-color: #64686c;
}
.contextselector-pf .dropdown-menu {
  margin-top: 0;
  width: 100%;
}
.contextselector-pf .form-group {
  margin: 0 5px 5px 5px;
}
.navbar-pf-vertical .nav.contextselector-pf .nav-item-iconic {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  padding: 23px 20px 18px 10px;
}
.ColVis_Button:active:focus {
  outline: none;
}
.ColVis_catcher {
  position: absolute;
  z-index: 999;
}
.ColVis_collection {
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 1px;
  box-shadow: 0 6px 12px rgba(3, 3, 3, 0.175);
  background-clip: padding-box;
  list-style: none;
  margin: -1px 0 0;
  padding: 5px 10px;
  width: 150px;
  z-index: 1000;
}
.ColVis_collection label {
  font-weight: normal;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 20px;
}
.ColVis_collectionBackground {
  background-color: #fff;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 998;
}
.dataTables_header {
  background-color: #f5f5f5;
  border: 1px solid #d1d1d1;
  border-bottom: none;
  padding: 5px;
  position: relative;
  text-align: center;
}
.dataTables_header .btn {
  box-shadow: none;
}
.dataTables_header .ColVis {
  position: absolute;
  right: 5px;
  text-align: left;
  top: 5px;
}
.dataTables_header .ColVis + .dataTables_info {
  padding-right: 30px;
}
.dataTables_header .dataTables_filter {
  position: absolute;
}
.dataTables_header .dataTables_filter input {
  border: 1px solid #bbb;
  height: 24px;
}
@media (max-width: 767px) {
  .dataTables_header .dataTables_filter input {
    width: 100px;
  }
}
.dataTables_header .dataTables_info {
  padding: 2px 0;
}
@media (max-width: 480px) {
  .dataTables_header .dataTables_info {
    text-align: right;
  }
}
.dataTables_header .dataTables_info b {
  font-weight: bold;
}
.dataTables_footer {
  background-color: #fff;
  border: 1px solid #d1d1d1;
  border-top: none;
  overflow: hidden;
}
.dataTables_paginate {
  background: #fafafa;
  float: right;
  margin: 0;
}
.dataTables_paginate .pagination {
  float: left;
  margin: 0;
}
.dataTables_paginate .pagination > li > span {
  border-color: #fff #d1d1d1 #f5f5f5;
  border-width: 0 1px;
  font-size: 16px;
  font-weight: normal;
  padding: 0;
  text-align: center;
  width: 31px;
}
.dataTables_paginate .pagination > li > span:hover,
.dataTables_paginate .pagination > li > span:focus {
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.dataTables_paginate .pagination > li.last > span {
  border-right: none;
}
.dataTables_paginate .pagination > li.disabled > span {
  background: #f5f5f5;
  border-left-color: #ededed;
  border-right-color: #ededed;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}
.dataTables_paginate .pagination-input {
  float: left;
  font-size: 12px;
  line-height: 1em;
  padding: 4px 15px 0;
  text-align: right;
}
.dataTables_paginate .pagination-input .paginate_input {
  border: 1px solid #d1d1d1;
  box-shadow: inset 0 1px 1px rgba(3, 3, 3, 0.075);
  font-size: 12px;
  font-weight: 600;
  height: 19px;
  margin-right: 8px;
  padding-right: 3px;
  text-align: right;
  width: 30px;
}
.dataTables_paginate .pagination-input .paginate_of {
  position: relative;
}
.dataTables_paginate .pagination-input .paginate_of b {
  margin-left: 3px;
}
.dataTables_empty {
  background: #f5f5f5;
}
/* Might need this for pagination?
.dataTables_wrapper {
  margin: @line-height-computed 0;
  @media (max-width: @screen-xs-max) {
    .table-responsive {
      margin-bottom: 0;
    }
  }
}
*/
.DTCR_clonedTable {
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 202;
}
.DTCR_pointer {
  background-color: #0088ce;
  width: 1px;
  z-index: 201;
}
.date-time-picker-pf .input-group-addon .fa,
.date-time-picker-pf .input-group-addon .fas,
.date-time-picker-pf .input-group-addon .far,
.date-time-picker-pf .input-group-addon .fab,
.date-time-picker-pf .input-group-addon .fal,
.date-time-picker-pf .input-group-addon .pficon {
  width: 12px;
}
.date-time-range-picker-pf .form-control,
.date-time-range-picker-pf .form-control:not(:first-child):not(:last-child) {
  border-radius: 1px;
}
.date-time-range-picker-pf .form-control:first-child {
  border-bottom-right-radius: 1px;
  border-top-right-radius: 1px;
}
.date-time-range-picker-pf .form-control:last-child {
  border-bottom-left-radius: 1px;
  border-top-left-radius: 1px;
}
.date-time-range-picker-pf .input-group-addon {
  background-color: transparent;
  border: none;
  line-height: 1.66666667;
  padding: 2px 6px;
}
.date-time-range-picker-pf .dropdown-kebab-pf {
  float: right;
  margin-left: 0;
  margin-right: -15px;
}
.experimental-pf > * {
  border: 2px solid #92d400;
}
.experimental-pf-bar {
  background-color: #92d400;
  border: none;
  text-align: center;
  position: relative;
}
.experimental-pf-more-info {
  background-color: #92d400;
  border: 0;
  color: #030303;
  display: block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  position: static;
}
@media (min-width: 992px) {
  .experimental-pf-more-info {
    padding: 0 10px;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
}
@media (min-width: 992px) {
  .experimental-pf-text {
    padding-left: 150px;
    padding-right: 150px;
  }
}
.experimental-pf-text a {
  color: #030303;
  text-decoration: underline;
}
.experimental-pf-text a:hover {
  color: #030303;
  cursor: pointer;
}
.btn-experimental-pf {
  background-color: #92d400;
  background-image: linear-gradient(to bottom, #ace12e 0%, #92d400 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fface12e', endColorstr='#ff92d400', GradientType=0);
  background-repeat: repeat-x;
  border-color: #92d400;
  color: #4d5258;
}
.btn-experimental-pf:hover,
.btn-experimental-pf:focus,
.btn-experimental-pf:active,
.btn-experimental-pf.active,
.open .dropdown-toggle.btn-experimental-pf {
  background-color: #92d400;
  background-image: none;
  border-color: #92d400;
  color: #4d5258;
}
.btn-experimental-pf:active,
.btn-experimental-pf.active,
.open .dropdown-toggle.btn-experimental-pf {
  background-image: none;
}
.btn-experimental-pf:active:hover,
.btn-experimental-pf.active:hover,
.open .dropdown-toggle.btn-experimental-pf:hover,
.btn-experimental-pf:active:focus,
.btn-experimental-pf.active:focus,
.open .dropdown-toggle.btn-experimental-pf:focus,
.btn-experimental-pf:active.focus,
.btn-experimental-pf.active.focus,
.open .dropdown-toggle.btn-experimental-pf.focus {
  background-color: #80bb00;
  border-color: #79b000;
}
.btn-experimental-pf.disabled,
.btn-experimental-pf[disabled],
fieldset[disabled] .btn-experimental-pf,
.btn-experimental-pf.disabled:hover,
.btn-experimental-pf[disabled]:hover,
fieldset[disabled] .btn-experimental-pf:hover,
.btn-experimental-pf.disabled:focus,
.btn-experimental-pf[disabled]:focus,
fieldset[disabled] .btn-experimental-pf:focus,
.btn-experimental-pf.disabled:active,
.btn-experimental-pf[disabled]:active,
fieldset[disabled] .btn-experimental-pf:active,
.btn-experimental-pf.disabled.active,
.btn-experimental-pf[disabled].active,
fieldset[disabled] .btn-experimental-pf.active {
  background-color: #92d400;
  border-color: #92d400;
}
.filter-pf-category-select {
  display: -ms-flexbox;
  display: flex;
}
.filter-pf-category-select-value {
  border-left-width: 0;
}
.filter-pf-category-item {
  margin-bottom: 5px;
}
.filter-pf-category-label {
  font-weight: 700;
  margin-right: 5px;
  padding: 5px 0 6px 5px;
}
.filter-pf-select .caret {
  position: absolute;
  top: 50%;
  right: 10px;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
}
.filter-pf-select-dropdown {
  background-color: #fff;
  background-image: none;
  color: #8b8d8f;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  padding-right: 25px;
  text-align: left;
}
.filter-pf-select-dropdown .caret {
  font-style: normal;
}
.filter-pf-select-dropdown.filter-selected {
  font-style: normal;
  color: inherit;
}
.filter-pf-active-label {
  margin-right: 5px;
}
.footer-pf-alt,
.footer-pf {
  background-color: #030303;
  color: #9c9c9c;
  font-size: 11px;
  line-height: 17px;
  padding-left: 25px;
  padding-top: 10px;
}
.layout-pf-alt-fixed-with-footer .footer-pf-alt,
.layout-pf-alt-fixed-with-footer .footer-pf,
.layout-pf-fixed-with-footer .footer-pf-alt,
.layout-pf-fixed-with-footer .footer-pf {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1030;
}
.hint-block-pf {
  background-color: #def3ff;
  color: #004368;
  padding: 10px 15px 15px;
}
.hint-block-pf .hint-block-pf-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 10px;
}
.hint-block-pf .hint-block-pf-body {
  font-size: 13px;
  font-weight: 300;
  line-height: 1.4;
}
.hint-block-pf > .btn {
  margin-top: 30px;
}
@font-face {
  font-family: "PatternFlyIcons-webfont";
  src: url("../fonts/PatternFlyIcons-webfont.eot");
  src: url("../fonts/PatternFlyIcons-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/PatternFlyIcons-webfont.ttf") format("truetype"), url("../fonts/PatternFlyIcons-webfont.woff") format("woff"), url("../fonts/PatternFlyIcons-webfont.svg#PatternFlyIcons-webfont") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^="pficon-"],
[class*=" pficon-"] {
  display: inline-block;
  font-family: "PatternFlyIcons-webfont";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
.pficon-add-circle-o:before {
  content: "\e61b";
}
.pficon-applications:before {
  content: "\e936";
}
.pficon-arrow:before {
  content: "\e929";
}
.pficon-asleep:before {
  content: "\e925";
}
.pficon-automation:before {
  content: "\e937";
}
.pficon-build:before {
  content: "\e902";
}
.pficon-builder-image:before {
  content: "\e800";
}
.pficon-bundle:before {
  content: "\e918";
}
.pficon-blueprint:before {
  content: "\e915";
}
.pficon-catalog:before {
  content: "\e939";
}
.pficon-chat:before {
  content: "\e928";
}
.pficon-close:before {
  content: "\e60b";
}
.pficon-cloud-security:before {
  content: "\e903";
}
.pficon-cloud-tenant:before {
  content: "\e904";
}
.pficon-cluster:before {
  content: "\e620";
}
.pficon-connected:before {
  content: "\e938";
}
.pficon-container-node:before {
  content: "\e621";
}
.pficon-cpu:before {
  content: "\e927";
}
.pficon-degraded:before {
  content: "\e91b";
}
.pficon-delete:before {
  content: "\e611";
}
.pficon-disconnected:before {
  content: "\e93c";
}
.pficon-domain:before {
  content: "\e919";
}
.pficon-drag-drop:before {
  content: "\e950";
}
.pficon-edit:before {
  content: "\e60a";
}
.pficon-enhancement:before {
  content: "\e93a";
}
.pficon-enterprise:before {
  content: "\e906";
}
.pficon-equalizer:before {
  content: "\e610";
}
.pficon-error-circle-o:before {
  color: #cc0000;
  content: "\e926";
}
.pficon-export:before {
  content: "\e616";
}
.pficon-flag:before,
.pficon-messages:before {
  content: "\e603";
}
.pficon-flavor:before {
  content: "\e907";
}
.pficon-filter:before {
  content: "\e943";
}
.pficon-folder-close:before {
  content: "\e607";
}
.pficon-folder-open:before {
  content: "\e606";
}
.pficon-help:before {
  content: "\e605";
}
.pficon-history:before {
  content: "\e93b";
}
.pficon-home:before {
  content: "\e618";
}
.pficon-image:before {
  content: "\e61f";
}
.pficon-import:before {
  content: "\e615";
}
.pficon-in-progress:before {
  content: "\e92c";
}
.pficon-info:before {
  content: "\e92b";
}
.pficon-infrastructure:before {
  content: "\e93d";
}
.pficon-integration:before {
  content: "\e948";
}
.pficon-key:before {
  content: "\e924";
}
.pficon-locked:before {
  content: "\e923";
}
.pficon-maintenance:before {
  content: "\e92d";
}
.pficon-memory:before {
  content: "\e908";
}
.pficon-middleware:before {
  content: "\e917";
}
.pficon-migration:before {
  content: "\e92e";
}
.pficon-monitoring:before {
  content: "\e944";
}
.pficon-network:before {
  content: "\e909";
}
.pficon-network-range:before {
  content: "\e94a";
}
.pficon-on:before {
  content: "\e931";
}
.pficon-on-running:before {
  content: "\e930";
}
.pficon-optimize:before {
  content: "\e93e";
}
.pficon-orders:before {
  content: "\e93f";
}
.pficon-off:before {
  content: "\e92f";
}
.pficon-ok:before {
  color: #3f9c35;
  content: "\e602";
}
.pficon-paused:before {
  content: "\e932";
}
.pficon-pending:before {
  content: "\e933";
}
.pficon-plugged:before {
  content: "\e940";
}
.pficon-port:before {
  content: "\e945";
}
.pficon-print:before {
  content: "\e612";
}
.pficon-process-automation:before {
  content: "\e949";
}
.pficon-private:before {
  content: "\e914";
}
.pficon-project:before {
  content: "\e905";
}
.pficon-rebalance:before {
  content: "\e91c";
}
.pficon-rebooting:before {
  content: "\e934";
}
.pficon-refresh:before,
.pficon-restart:before {
  content: "\e617";
}
.pficon-regions:before {
  content: "\e90a";
}
.pficon-registry:before {
  content: "\e623";
}
.pficon-remove:before {
  content: "\e611";
}
.pficon-replicator:before {
  content: "\e624";
}
.pficon-repository:before {
  content: "\e90b";
}
.pficon-resource-pool:before {
  content: "\e90c";
}
.pficon-resources-almost-empty:before {
  content: "\e91d";
}
.pficon-resources-almost-full:before {
  content: "\e912";
}
.pficon-resources-full:before {
  content: "\e92a";
}
.pficon-route:before {
  content: "\e625";
}
.pficon-running:before {
  content: "\e614";
}
.pficon-satellite:before {
  content: "\e94b";
}
.pficon-save:before {
  content: "\e601";
}
.pficon-screen:before {
  content: "\e600";
}
.pficon-search:before {
  content: "\e921";
}
.pficon-security:before {
  content: "\e946";
}
.pficon-server:before {
  content: "\e90d";
}
.pficon-server-group:before {
  content: "\e91a";
}
.pficon-service:before {
  content: "\e61e";
}
.pficon-services:before {
  content: "\e947";
}
.pficon-service-catalog:before {
  content: "\e941";
}
.pficon-settings:before {
  content: "\e610";
}
.pficon-sort-common-asc:before {
  content: "\e94e";
}
.pficon-sort-common-desc:before {
  content: "\e94f";
}
.pficon-spinner:before {
  content: "\e614";
}
.pficon-spinner2:before {
  content: "\e613";
}
.pficon-storage-domain:before {
  content: "\e90e";
}
.pficon-template:before {
  content: "\e94c";
}
.pficon-tenant:before {
  content: "\e916";
}
.pficon-thumb-tack-o:before {
  content: "\e920";
}
.pficon-topology:before {
  content: "\e608";
}
.pficon-trend-down:before {
  content: "\e900";
}
.pficon-trend-up:before {
  content: "\e901";
}
.pficon-unknown:before {
  content: "\e935";
}
.pficon-user:before {
  content: "\e91e";
}
.pficon-users:before {
  content: "\e91f";
}
.pficon-unlocked:before {
  content: "\e922";
}
.pficon-unplugged:before {
  content: "\e942";
}
.pficon-vcenter:before {
  content: "\e94d";
}
.pficon-virtual-machine:before {
  content: "\e90f";
}
.pficon-volume:before {
  content: "\e910";
}
.pficon-warning-triangle-o:before {
  color: #ec7a08;
  content: "\e913";
}
.pficon-zone:before {
  content: "\e911";
}
.navbar-nav > li > .dropdown-menu.infotip {
  border-top-width: 1px !important;
  margin-top: 10px;
}
@media (max-width: 767px) {
  .navbar-pf .navbar-nav .open .dropdown-menu.infotip {
    background-color: #fff !important;
    margin-top: 0;
  }
}
.infotip {
  min-width: 235px;
  padding: 0;
}
.infotip .list-group {
  border-top: 0;
  margin: 0;
  padding: 8px 0;
}
.infotip .list-group .list-group-item {
  border: none;
  margin: 0 15px 0 34px;
  padding: 5px 0;
}
.infotip .list-group .list-group-item > .i {
  color: #4d5258;
  font-size: 13px;
  left: -20px;
  position: absolute;
  top: 8px;
}
.infotip .list-group .list-group-item > a {
  color: #4d5258;
  line-height: 13px;
}
.infotip .list-group .list-group-item > .close {
  float: right;
}
.infotip .footer {
  background-color: #f5f5f5;
  padding: 6px 15px;
}
.infotip .footer a:hover {
  color: #0088ce;
}
.infotip .arrow,
.infotip .arrow:after {
  border-color: transparent;
  border-style: solid;
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}
.infotip .arrow {
  border-width: 11px;
}
.infotip .arrow:after {
  border-width: 10px;
  content: "";
}
.infotip.bottom .arrow,
.infotip.bottom-left .arrow,
.infotip.bottom-right .arrow {
  border-bottom-color: #bbb;
  border-top-width: 0;
  left: 50%;
  margin-left: -11px;
  top: -11px;
}
.infotip.bottom .arrow:after,
.infotip.bottom-left .arrow:after,
.infotip.bottom-right .arrow:after {
  border-top-width: 0;
  border-bottom-color: #fff;
  content: " ";
  margin-left: -10px;
  top: 1px;
}
.infotip.bottom-left .arrow {
  left: 20%;
}
.infotip.bottom-right .arrow {
  left: 80%;
}
.infotip.top .arrow {
  border-bottom-width: 0;
  border-top-color: #bbb;
  bottom: -11px;
  left: 50%;
  margin-left: -11px;
}
.infotip.top .arrow:after {
  border-bottom-width: 0;
  border-top-color: #f5f5f5;
  bottom: 1px;
  content: " ";
  margin-left: -10px;
}
.infotip.right .arrow {
  border-left-width: 0;
  border-right-color: #bbb;
  left: -11px;
  margin-top: -11px;
  top: 50%;
}
.infotip.right .arrow:after {
  bottom: -10px;
  border-left-width: 0;
  border-right-color: #fff;
  content: " ";
  left: 1px;
}
.infotip.left .arrow {
  border-left-color: #bbb;
  border-right-width: 0;
  margin-top: -11px;
  right: -11px;
  top: 50%;
}
.infotip.left .arrow:after {
  border-left-color: #fff;
  border-right-width: 0;
  bottom: -10px;
  content: " ";
  right: 1px;
}
.layout-pf,
.layout-pf body {
  min-height: 100%;
}
.layout-pf.layout-pf-fixed.transitions .container-pf-nav-pf-vertical {
  transition: all 200ms cubic-bezier(0.35, 0, 0.25, 1);
}
.layout-pf.layout-pf-fixed body {
  padding-top: 60px;
}
.layout-pf.layout-pf-fixed .navbar-pf {
  left: 0;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1030;
}
.layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical {
  margin-left: 200px;
}
.layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.nav-pf-vertical-with-badges {
  margin-left: 250px;
}
.layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.collapsed-nav {
  margin-left: 75px;
}
.layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.collapsed-nav.hidden-icons-pf {
  margin-left: 0;
}
.layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.hidden-nav {
  margin-left: 0;
}
.layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.hide-nav-pf {
  visibility: hidden !important;
}
.layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.collapsed-secondary-nav-pf,
.layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.collapsed-tertiary-nav-pf {
  margin-left: 200px;
}
.layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.collapsed-secondary-nav-pf.nav-pf-vertical-with-badges,
.layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.collapsed-tertiary-nav-pf.nav-pf-vertical-with-badges {
  margin-left: 250px;
}
@media (min-width: 1200px) {
  .layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf {
    margin-left: 400px;
  }
  .layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.nav-pf-vertical-with-badges {
    margin-left: 500px;
  }
  .layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.hidden-nav {
    margin-left: 0;
  }
  .layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed-secondary-nav-pf {
    margin-left: 200px;
  }
  .layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed-secondary-nav-pf.nav-pf-vertical-with-badges {
    margin-left: 250px;
  }
  .layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed-tertiary-nav-pf {
    margin-left: 200px;
  }
  .layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed-tertiary-nav-pf.nav-pf-vertical-with-badges {
    margin-left: 250px;
  }
  .layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed-nav {
    margin-left: 275px;
  }
  .layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed-nav.nav-pf-vertical-with-badges {
    margin-left: 325px;
  }
  .layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed-nav.collapsed-secondary-nav-pf {
    margin-left: 200px;
  }
  .layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed-nav.collapsed-secondary-nav-pf.nav-pf-vertical-with-badges {
    margin-left: 250px;
  }
  .layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed-nav.collapsed-tertiary-nav-pf {
    margin-left: 200px;
  }
  .layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed-nav.collapsed-tertiary-nav-pf.nav-pf-vertical-with-badges {
    margin-left: 250px;
  }
  .layout-pf.layout-pf-fixed .container-pf-nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed-nav.hidden-icons-pf {
    margin-left: 0;
  }
}
.layout-pf.layout-pf-fixed-with-footer body {
  padding-bottom: 37px;
}
.layout-pf-alt,
.layout-pf-alt body {
  min-height: 100%;
}
.layout-pf-alt.layout-pf-alt-fixed.layout-pf-alt-fixed-inner-scroll,
.layout-pf-alt.layout-pf-alt-fixed.layout-pf-alt-fixed-inner-scroll body {
  height: 100%;
  min-height: 0;
}
.layout-pf-alt.layout-pf-alt-fixed.layout-pf-alt-fixed-inner-scroll .container-pf-alt-nav-pf-vertical-alt {
  height: 100%;
  overflow: auto;
}
.layout-pf-alt.layout-pf-alt-fixed.layout-pf-alt-fixed-inner-scroll .container-pf-alt-nav-pf-vertical-alt.container-cards-pf {
  margin-top: 0;
  padding-top: 20px;
}
.layout-pf-alt.layout-pf-alt-fixed.transitions .container-pf-alt-nav-pf-vertical-alt {
  transition: all 200ms cubic-bezier(0.35, 0, 0.25, 1);
}
.layout-pf-alt.layout-pf-alt-fixed body {
  padding-top: 60px;
}
.layout-pf-alt.layout-pf-alt-fixed .container-pf-alt-nav-pf-vertical-alt {
  margin-left: 250px;
}
.layout-pf-alt.layout-pf-alt-fixed .container-pf-alt-nav-pf-vertical-alt.collapsed-nav {
  margin-left: 75px;
}
.layout-pf-alt.layout-pf-alt-fixed .container-pf-alt-nav-pf-vertical-alt.hidden-nav {
  margin-left: 0;
}
.layout-pf-alt.layout-pf-alt-fixed-with-footer body {
  padding-bottom: 37px;
}
.loading-state-pf {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  font-size: 12px;
  height: 100%;
}
.loading-state-pf:before {
  content: "";
  height: 25%;
  display: block;
}
.loading-state-pf.loading-state-pf-lg {
  font-size: 15px;
}
.loading-state-pf.loading-state-pf-sm {
  font-size: 9px;
}
.loading-state-pf.loading-state-pf-xs {
  font-size: 6px;
}
a.disabled {
  color: #8b8d8f;
  cursor: not-allowed;
  text-decoration: none;
}
.list-pf {
  border-bottom: 1px solid #ededed;
}
.list-pf-item {
  border-color: #ededed;
  border-left-color: #fff;
  border-right-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-bottom: none;
}
.list-pf-item:hover {
  background-color: #edf8ff;
}
.list-pf-item.active {
  background-color: #edf8ff;
  border-color: #bbb;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  box-shadow: 0 2px 6px rgba(3, 3, 3, 0.2);
}
.list-pf-expansion {
  background-color: #fff;
}
.list-pf-container {
  -ms-flex-align: start;
      align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
}
.list-pf-expansion .list-pf-container {
  border-top: 1px solid #bbb;
}
@media (min-width: 992px) {
  .list-pf:not(.list-pf-stacked) .list-pf-container {
    -ms-flex-align: center;
        -ms-grid-row-align: center;
        align-items: center;
  }
}
.list-pf-chevron {
  min-width: 1.2em;
}
.list-pf-chevron,
.list-pf-select {
  margin-right: 10px;
}
.list-pf-chevron + .list-pf-content,
.list-pf-select + .list-pf-content {
  border-left: 1px solid #d1d1d1;
  padding-left: 20px;
}
.list-pf-chevron .fa,
.list-pf-select .fa,
.list-pf-chevron .fas,
.list-pf-select .fas,
.list-pf-chevron .far,
.list-pf-select .far,
.list-pf-chevron .fab,
.list-pf-select .fab,
.list-pf-chevron .fal,
.list-pf-select .fal {
  font-size: 22px;
}
.list-pf-content-flex {
  -ms-flex-align: start;
      align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -ms-flex-pack: start;
      justify-content: flex-start;
  min-width: 0;
}
@media (min-width: 992px) {
  .list-pf:not(.list-pf-stacked) .list-pf-content-flex {
    -ms-flex-align: center;
        -ms-grid-row-align: center;
        align-items: center;
  }
}
.list-pf-left {
  -ms-flex-positive: 0;
      flex-grow: 0;
  margin-left: 0;
  margin-right: 20px;
}
.list-pf-icon {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.list-pf-icon-bordered {
  border-radius: 50%;
  border: 2px solid #39a5dc;
}
.list-pf-icon-small {
  font-size: 1.4em;
  height: 30px;
  line-height: 30px;
  width: 30px;
}
.list-pf-content-wrapper {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  min-width: 0;
}
@media (min-width: 992px) {
  .list-pf-content-wrapper {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .list-pf-content-wrapper > * + * {
    margin-left: 40px;
  }
}
.list-pf-stacked .list-pf-content-wrapper {
  -ms-flex-align: start;
      -ms-grid-row-align: flex-start;
      align-items: flex-start;
}
.list-pf-main-content {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 70%;
      flex-basis: 70%;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  min-width: 0;
}
@media (min-width: 992px) {
  .list-pf:not(.list-pf-stacked) .list-pf-main-content {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    width: auto;
  }
  .list-pf:not(.list-pf-stacked) .list-pf-main-content > * + * {
    margin-left: 40px;
  }
}
.list-pf-title {
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  font-weight: bold;
  min-width: 0;
  word-wrap: break-word;
}
.list-pf-description {
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  min-width: 0;
  word-wrap: break-word;
}
.list-pf-additional-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 31%;
      flex-basis: 31%;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: justify;
      justify-content: space-between;
}
.list-pf-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-positive: 0;
      flex-grow: 0;
  margin-left: 40px;
}
.list-pf-actions > * + * {
  margin-left: 10px;
}
.list-view-pf .list-group-item {
  -ms-flex-align: start;
      align-items: flex-start;
  background-clip: padding-box;
  border-color: transparent #fff;
  border-style: solid;
  border-width: 1px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-bottom: 0;
  padding-top: 0;
}
.list-view-pf .list-group-item:before,
.list-view-pf .list-group-item:after {
  display: table;
  content: " ";
}
.list-view-pf .list-group-item:after {
  clear: both;
}
.list-view-pf .list-group-item.list-view-pf-expand-active {
  background-color: #def3ff;
  box-shadow: 0 2px 6px rgba(3, 3, 3, 0.2);
  z-index: 1;
}
.list-view-pf .list-group-item.active {
  color: #555;
  background-color: #def3ff;
  background-clip: border-box;
  border-color: #bbb transparent transparent;
  z-index: auto;
}
.list-view-pf .list-group-item:hover {
  background-color: #edf8ff;
  border-left-color: transparent;
  border-right-color: transparent;
}
.list-view-pf .list-group-item.list-view-pf-expand-active {
  border: solid 1px #bbb;
}
.list-view-pf .list-group-item.list-view-pf-expand-active:first-child {
  border-top-color: #bbb;
}
.list-view-pf .list-group-item:first-child {
  border-top: 1px solid transparent;
}
@media (min-width: 992px) {
  .list-view-pf .list-group-item {
    -ms-flex-align: center;
        -ms-grid-row-align: center;
        align-items: center;
  }
}
.list-view-pf .list-group-item-heading {
  font-size: 16px;
}
.list-view-pf .list-group-item-heading small {
  display: block;
  font-size: 9.6px;
  font-weight: 400;
}
@media (min-width: 992px) {
  .list-view-pf .list-group-item-heading {
    -ms-flex: 1 0 calc(25% - 20px);
        flex: 1 0 calc(25% - 20px);
    float: left;
    font-size: 12px;
    margin: 0 20px 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(25% - 20px);
  }
}
.list-view-pf .list-group-item-text {
  color: currentColor !important;
  margin-bottom: 10px;
}
@media (min-width: 992px) {
  .list-view-pf .list-group-item-text {
    -ms-flex: 1 0 auto;
        flex: 1 0 auto;
    float: left;
    margin: 0 40px 0 0;
    width: calc(75% - 40px);
  }
}
.list-view-pf .close {
  float: none;
  position: absolute;
  right: 15px;
}
.list-view-pf-actions {
  float: right;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 20px;
  -ms-flex-order: 2;
      order: 2;
}
.list-view-pf-actions button,
.list-view-pf-actions > a,
.list-view-pf-actions .dropdown-kebab-pf {
  margin-left: 10px;
}
.list-view-pf-top-align .list-view-pf-actions {
  -ms-flex-item-align: start;
      align-self: flex-start;
}
.list-view-pf-additional-info {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (min-width: 992px) {
  .list-view-pf-additional-info {
    -ms-flex: 1 0 auto;
        flex: 1 0 auto;
    float: left;
    width: 50%;
  }
}
.list-view-pf-additional-info-item {
  -ms-flex-align: center;
      align-items: center;
  display: inline-block;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  max-width: 100%;
  text-align: center;
}
.list-view-pf-additional-info-item.list-view-pf-additional-info-item-stacked {
  text-align: center;
  -ms-flex-direction: column;
      flex-direction: column;
}
.list-view-pf-additional-info-item.list-view-pf-additional-info-item-stacked strong {
  font-size: 13px;
  line-height: 1em;
}
.list-view-pf-additional-info-item .pficon,
.list-view-pf-additional-info-item .fa,
.list-view-pf-additional-info-item .fas,
.list-view-pf-additional-info-item .far,
.list-view-pf-additional-info-item .fab,
.list-view-pf-additional-info-item .fal {
  font-size: 16px;
  margin-right: 10px;
}
.list-view-pf-additional-info-item strong {
  font-size: 16px;
  font-weight: 600;
  margin-right: 5px;
}
.list-view-pf-additional-info-item:last-child {
  margin-right: 0;
}
.list-view-pf-additional-info-item-donut-chart {
  width: 60px;
}
.list-view-pf-body {
  -ms-flex-align: center;
      -ms-grid-row-align: center;
      align-items: center;
  display: table-cell;
  -ms-flex: 1;
      flex: 1;
  min-width: 0;
  vertical-align: top;
  width: 100%;
}
@media (min-width: 992px) {
  .list-view-pf-body {
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
  }
}
.list-view-pf-checkbox {
  border-right: 1px solid #d1d1d1;
  float: left;
  margin-bottom: 20px;
  margin-right: 15px;
  margin-top: 20px;
  padding: 3px 10px 3px 0;
}
.list-view-pf-top-align .list-view-pf-checkbox {
  -ms-flex-item-align: start;
      align-self: flex-start;
}
.list-view-pf-description {
  -ms-flex: 1 0 50%;
      flex: 1 0 50%;
}
.list-view-pf-stacked .list-view-pf-description {
  display: block;
  -ms-flex: none;
      flex: none;
}
@media (min-width: 992px) {
  .list-view-pf-description {
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex;
    float: left;
    width: 50%;
  }
}
.list-view-pf-left {
  display: table-cell;
  padding-right: 20px;
  text-align: center;
  vertical-align: top;
}
.list-view-pf-left .list-view-pf-calendar {
  font-size: 11px;
  line-height: 1em;
}
.list-view-pf-left .list-view-pf-calendar strong {
  display: block;
  font-size: 44px;
  font-weight: 300;
  line-height: 1em;
}
.list-view-pf-left .pficon,
.list-view-pf-left .fa,
.list-view-pf-left .fas,
.list-view-pf-left .far,
.list-view-pf-left .fab,
.list-view-pf-left .fal {
  border-radius: 50%;
  font-size: 2em;
}
.list-view-pf-left .pficon.list-view-pf-icon-md,
.list-view-pf-left .fa.list-view-pf-icon-md,
.list-view-pf-left .fas.list-view-pf-icon-md,
.list-view-pf-left .far.list-view-pf-icon-md,
.list-view-pf-left .fab.list-view-pf-icon-md,
.list-view-pf-left .fal.list-view-pf-icon-md {
  background-color: #f5f5f5;
  height: 50px;
  line-height: 50px;
  width: 50px;
}
.list-view-pf-left .pficon.list-view-pf-icon-danger,
.list-view-pf-left .fa.list-view-pf-icon-danger,
.list-view-pf-left .fas.list-view-pf-icon-danger,
.list-view-pf-left .far.list-view-pf-icon-danger,
.list-view-pf-left .fab.list-view-pf-icon-danger,
.list-view-pf-left .fal.list-view-pf-icon-danger {
  background-color: #ffe6e6;
  color: #cc0000;
}
.list-view-pf-left .pficon.list-view-pf-icon-info,
.list-view-pf-left .fa.list-view-pf-icon-info,
.list-view-pf-left .fas.list-view-pf-icon-info,
.list-view-pf-left .far.list-view-pf-icon-info,
.list-view-pf-left .fab.list-view-pf-icon-info,
.list-view-pf-left .fal.list-view-pf-icon-info {
  color: #8b8d8f;
}
.list-view-pf-left .pficon.list-view-pf-icon-lg,
.list-view-pf-left .fa.list-view-pf-icon-lg,
.list-view-pf-left .fas.list-view-pf-icon-lg,
.list-view-pf-left .far.list-view-pf-icon-lg,
.list-view-pf-left .fab.list-view-pf-icon-lg,
.list-view-pf-left .fal.list-view-pf-icon-lg {
  background-color: #f5f5f5;
  height: 60px;
  line-height: 60px;
  width: 60px;
}
.list-view-pf-left .pficon.list-view-pf-icon-sm,
.list-view-pf-left .fa.list-view-pf-icon-sm,
.list-view-pf-left .fas.list-view-pf-icon-sm,
.list-view-pf-left .far.list-view-pf-icon-sm,
.list-view-pf-left .fab.list-view-pf-icon-sm,
.list-view-pf-left .fal.list-view-pf-icon-sm {
  border: 2px solid #39a5dc;
  font-size: 1.4em;
  height: 30px;
  line-height: 30px;
  width: 30px;
}
.list-view-pf-left .pficon.list-view-pf-icon-sm:before,
.list-view-pf-left .fa.list-view-pf-icon-sm:before,
.list-view-pf-left .fas.list-view-pf-icon-sm:before,
.list-view-pf-left .far.list-view-pf-icon-sm:before,
.list-view-pf-left .fab.list-view-pf-icon-sm:before,
.list-view-pf-left .fal.list-view-pf-icon-sm:before {
  display: block;
  line-height: 26px;
}
.list-view-pf-left .pficon.list-view-pf-icon-success,
.list-view-pf-left .fa.list-view-pf-icon-success,
.list-view-pf-left .fas.list-view-pf-icon-success,
.list-view-pf-left .far.list-view-pf-icon-success,
.list-view-pf-left .fab.list-view-pf-icon-success,
.list-view-pf-left .fal.list-view-pf-icon-success {
  background-color: #e9f4e9;
  color: #3f9c35;
}
.list-view-pf-left .pficon.list-view-pf-icon-warning,
.list-view-pf-left .fa.list-view-pf-icon-warning,
.list-view-pf-left .fas.list-view-pf-icon-warning,
.list-view-pf-left .far.list-view-pf-icon-warning,
.list-view-pf-left .fab.list-view-pf-icon-warning,
.list-view-pf-left .fal.list-view-pf-icon-warning {
  background-color: #fdf2e5;
  color: #ec7a08;
}
.list-view-pf-main-info {
  -ms-flex-align: start;
      align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
      flex: 1;
  min-width: 0;
  padding-bottom: 20px;
  padding-top: 20px;
}
@media (min-width: 992px) {
  .list-view-pf-main-info {
    -ms-flex-align: center;
        -ms-grid-row-align: center;
        align-items: center;
  }
  .list-view-pf-top-align .list-view-pf-main-info {
    -ms-flex-align: start;
        -ms-grid-row-align: flex-start;
        align-items: flex-start;
  }
}
.list-view-pf-stacked .list-group-item-heading {
  float: none;
  font-size: 16px;
  line-height: 1.2em;
  margin-bottom: 5px;
  margin-right: 40px;
  width: auto;
}
.list-view-pf-stacked .list-group-item-text {
  float: none;
  width: auto;
}
.list-view-pf-view {
  background: #ededed;
  border: none;
  margin-top: 30px;
}
.list-group-item-header {
  box-sizing: content-box;
  cursor: pointer;
  margin: 0 -15px;
  padding: 0 15px;
  width: 100%;
}
.list-view-pf-expand {
  cursor: pointer;
  float: left;
  margin-bottom: 20px;
  margin-right: 2px;
  margin-top: 20px;
  padding: 3px 0;
}
.list-view-pf-expand.active,
.list-view-pf-expand:hover {
  color: #0088ce;
}
.list-view-pf-additional-info-item .list-view-pf-expand {
  margin: 0;
  padding: 0;
}
.list-view-pf-expand .fa-angle-right {
  cursor: pointer;
  font-size: 17px;
  margin-right: 5px;
  margin-top: 2px;
  width: 10px;
}
.list-group-item-container {
  background: #fff;
  border-top: solid 1px #bbb;
  box-sizing: content-box;
  margin: -1px -15px 0;
  -ms-flex-order: 3;
      order: 3;
  padding: 15px;
  position: relative;
  width: 100%;
}
.list-view-pf-editable.active .list-view-pf-editor,
.list-view-pf-editable.list-view-pf-create .list-view-pf-editor,
.list-view-pf-editable.active .list-view-pf-editor.bootstrap-select,
.list-view-pf-editable.list-view-pf-create .list-view-pf-editor.bootstrap-select,
.list-view-pf-editable.active .bootstrap-switch,
.list-view-pf-editable.list-view-pf-create .bootstrap-switch,
.list-view-pf-editable.active .list-view-pf-actions .list-view-pf-save,
.list-view-pf-editable.list-view-pf-create .list-view-pf-actions .list-view-pf-save,
.list-view-pf-editable.active .list-view-pf-actions .list-view-pf-cancel,
.list-view-pf-editable.list-view-pf-create .list-view-pf-actions .list-view-pf-cancel {
  display: initial;
}
.list-view-pf-editable.active .list-view-pf-edit,
.list-view-pf-editable.list-view-pf-create .list-view-pf-edit,
.list-view-pf-editable.active .list-view-pf-value:not(.list-view-pf-readonly),
.list-view-pf-editable.list-view-pf-create .list-view-pf-value:not(.list-view-pf-readonly),
.list-view-pf-editable.active .dropdown-kebab-pf,
.list-view-pf-editable.list-view-pf-create .dropdown-kebab-pf {
  display: none;
}
.list-view-pf-editable .list-view-pf-editor,
.list-view-pf-editable .list-view-pf-editor.bootstrap-select,
.list-view-pf-editable .bootstrap-switch,
.list-view-pf-editable .list-view-pf-actions .list-view-pf-save,
.list-view-pf-editable .list-view-pf-actions .list-view-pf-cancel {
  display: none;
}
@media (min-width: 768px) {
  .list-view-pf-editable.active .list-view-pf-additional-info {
    margin-top: 20px;
  }
}
@media (min-width: 992px) {
  .list-view-pf-editable.active .list-view-pf-additional-info {
    margin-top: 0;
  }
}
.list-view-pf-editable.active .list-view-pf-additional-info .list-view-pf-additional-info-item {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .list-view-pf-editable.active .list-view-pf-additional-info .list-view-pf-additional-info-item {
    margin-top: 0;
  }
}
.list-view-pf-editable .list-view-pf-textbox {
  margin-right: 20px;
  width: 100%;
}
.list-view-pf-editable .list-view-pf-readonly {
  color: #8b8d8f;
}
.list-view-pf-editable .list-view-pf-actions {
  margin: 10px 0;
  width: 100px;
  text-align: right;
}
.list-view-pf-editable .list-view-pf-actions .list-view-pf-save,
.list-view-pf-editable .list-view-pf-actions .list-view-pf-cancel {
  font-size: 24px;
}
@media (max-width: 768px) {
  .list-view-pf-editable .list-view-pf-actions .list-view-pf-save,
  .list-view-pf-editable .list-view-pf-actions .list-view-pf-cancel {
    margin-left: 0;
  }
}
.list-view-pf-editable .list-view-pf-description {
  -ms-flex: 100%;
      flex: 100%;
}
@media (min-width: 768px) {
  .list-view-pf-editable .list-view-pf-description {
    -ms-flex: 100%;
        flex: 100%;
  }
}
@media (min-width: 992px) {
  .list-view-pf-editable .list-view-pf-description {
    -ms-flex: 25%;
        flex: 25%;
  }
}
.list-view-pf-editable .list-view-pf-additional-info {
  -ms-flex: 100%;
      flex: 100%;
}
.list-view-pf-editable .list-view-pf-additional-info .list-view-pf-additional-info-item {
  -ms-flex: 100%;
      flex: 100%;
}
@media (min-width: 768px) {
  .list-view-pf-editable .list-view-pf-additional-info {
    -ms-flex: 100%;
        flex: 100%;
  }
  .list-view-pf-editable .list-view-pf-additional-info .list-view-pf-additional-info-item {
    -ms-flex: 1;
        flex: 1;
  }
}
@media (min-width: 992px) {
  .list-view-pf-editable .list-view-pf-additional-info {
    -ms-flex: 75%;
        flex: 75%;
  }
  .list-view-pf-editable .list-view-pf-additional-info .list-view-pf-additional-info-item {
    -ms-flex: 1;
        flex: 1;
  }
}
.list-view-pf-dnd .dndDragging.drag-original {
  display: none;
}
.list-view-pf-dnd .dndDragging.drag-original .list-view-pf-dnd-original-items {
  display: block;
}
.list-view-pf-dnd .dndDragging .list-view-pf-dnd-drag-items {
  display: inline-block;
}
.list-view-pf-dnd .dndDragging .list-view-pf-dnd-original-items {
  display: none;
}
.list-view-pf-dnd .dndPlaceholder {
  background-color: #ededed;
  padding: 20px 0;
}
.list-view-pf-dnd .list-group-item-header {
  margin-left: -10px;
}
.list-view-pf-dnd .list-group-item-header:before {
  background-image: linear-gradient(to bottom, #0088ce 60%, #fff 0%);
  background-position: left;
  background-repeat: repeat-y;
  background-size: 2px 5px;
  border: 4px solid #0088ce;
  border-color: #00659c;
  content: "";
  height: 55px;
  left: 4px;
  position: absolute;
  top: 5px;
  width: 10px;
}
.list-view-pf-dnd-drag-items {
  display: none;
}
.login-pf {
  height: 100%;
  background: #1a1a1a url("../img/bg-login.png") repeat-x 50% 0;
  background-size: auto;
}
@media (min-width: 768px) {
  .login-pf {
    background-size: 100% auto;
  }
}
.login-pf #brand {
  position: relative;
  top: -70px;
}
.login-pf #brand img {
  display: block;
  height: 18px;
  margin: 0 auto;
  max-width: 100%;
}
@media (min-width: 768px) {
  .login-pf #brand img {
    margin: 0;
    text-align: left;
  }
}
.login-pf #badge {
  display: block;
  margin: 20px auto 70px;
  position: relative;
  text-align: center;
}
@media (min-width: 768px) {
  .login-pf #badge {
    float: right;
    margin-right: 64px;
    margin-top: 50px;
  }
}
.login-pf body {
  background: #1a1a1a url("../img/bg-login.png") repeat-x 50% 0;
  background-size: auto;
}
@media (min-width: 768px) {
  .login-pf body {
    background-size: 100% auto;
  }
}
.login-pf .container {
  background-color: transparent;
  clear: right;
  color: #fff;
  padding-bottom: 40px;
  padding-top: 20px;
  width: auto;
}
@media (min-width: 768px) {
  .login-pf .container {
    bottom: 13%;
    padding-left: 80px;
    position: absolute;
    width: 100%;
  }
}
.login-pf .container .details p:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 25px;
  margin-top: 25px;
}
@media (min-width: 768px) {
  .login-pf .container .details {
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    padding-left: 40px;
  }
  .login-pf .container .details p:first-child {
    border-top: 0;
    padding-top: 0;
    margin-top: 0;
  }
}
.login-pf .container .details p {
  margin-bottom: 2px;
}
.login-pf .container .form-horizontal .control-label {
  font-size: 13px;
  font-weight: 400;
  text-align: left;
}
.login-pf .container .form-horizontal .form-group:last-child,
.login-pf .container .form-horizontal .form-group:last-child .help-block:last-child {
  margin-bottom: 0;
}
.login-pf .container .help-block {
  color: #fff;
}
@media (min-width: 768px) {
  .login-pf .container .login {
    padding-right: 40px;
  }
}
.login-pf .container .submit {
  text-align: right;
}
.login-pf-page {
  padding-top: 20px;
}
.login-pf-page .login-pf-brand {
  margin-top: 0px;
  max-width: 360px;
  width: 70%;
}
@media (min-width: 768px) {
  .login-pf-page .login-pf-brand {
    margin-top: 22px;
  }
}
.login-pf-page .login-pf-page-header {
  margin-bottom: 20px;
  text-align: center;
}
@media (min-width: 768px) {
  .login-pf-page .login-pf-page-header {
    margin-bottom: 40px;
  }
}
.login-pf-page .login-pf-page-header p {
  color: #fff;
  font-size: 14px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.login-pf-page .card-pf {
  padding: 10px 20px 30px 20px;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .login-pf-page .card-pf {
    padding: 20px 40px 30px 40px;
  }
}
.login-pf-page .card-pf p {
  color: #72767b;
}
.login-pf-page .form-control {
  height: 36px;
}
.login-pf-page .checkbox-label {
  color: #72767b;
  font-weight: 300;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .login-pf-page .checkbox-label {
    margin: 0;
  }
}
.login-pf-page .btn-primary {
  margin-top: 40px;
}
.login-pf-page .login-pf-header {
  margin-bottom: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
@media (min-width: 768px) {
  .login-pf-page .login-pf-header {
    margin-bottom: 10px;
  }
}
.login-pf-page .login-pf-header h1 {
  text-align: center;
}
.login-pf-page .login-pf-header h1 {
  font-size: 16px;
}
@media (min-width: 768px) {
  .login-pf-page .login-pf-header h1 {
    font-size: 24px;
  }
}
.login-pf-page .login-pf-header .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: auto;
  -ms-flex-item-align: end;
      align-self: flex-end;
}
.login-pf-page .login-pf-header .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) .dropdown-toggle {
  color: #4d5258;
  background: none;
  padding: 0 15px 0 0;
  font-weight: 300;
}
.login-pf-page .login-pf-header .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) .dropdown-toggle:not(:focus) {
  box-shadow: none;
  border: 1px solid transparent;
}
.login-pf-page .login-pf-header .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) .dropdown-toggle .caret {
  right: 0;
}
.login-pf-page .login-pf-signup {
  margin: 40px 0 0;
  font-size: 15px;
  text-align: center;
}
.login-pf-page .login-pf-signup a {
  margin-left: 10px;
}
.login-pf-page .login-pf-settings {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.login-pf-page .login-pf-page-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-bottom: 50px;
}
@media (min-width: 768px) {
  .login-pf-page .login-pf-page-footer {
    padding-bottom: 100px;
  }
}
.login-pf-page .login-pf-page-footer-links {
  display: -ms-flexbox;
  display: flex;
  margin: 60px 0 0 0;
}
.login-pf-page .login-pf-page-footer-links li:not(:last-of-type) {
  position: relative;
  margin: 0 20px 0 0;
}
.login-pf-page .login-pf-page-footer-links li:not(:last-of-type):after {
  content: ".";
  color: #fff;
  position: absolute;
  top: 0px;
  right: -10px;
}
.login-pf-page .login-pf-page-footer-link {
  color: #fff;
  font-size: 14px;
}
.login-pf-page .login-pf-page-footer-sso-services {
  -ms-flex: 1 0 100%;
      flex: 1 0 100%;
  margin-top: 40px;
  text-align: center;
  color: #fff;
}
.login-pf-page .login-pf-page-footer-sso-services-logos {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  -ms-flex-pack: center;
      justify-content: center;
}
.login-pf-page .login-pf-page-footer-sso-services-logos li {
  margin: 0 5px 5px;
}
.login-pf-page.login-pf-page-accounts {
  margin-left: 20px;
  margin-right: 20px;
}
.login-pf-page .login-pf-accounts {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
}
.login-pf-page .login-pf-accounts .login-pf-header,
.login-pf-page .login-pf-accounts .login-pf-signup {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
}
.login-pf-page .login-pf-social-section {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  padding: 0;
}
@media (min-width: 768px) {
  .login-pf-page .login-pf-social-section {
    -ms-flex: 0 1 50%;
        flex: 0 1 50%;
    max-width: 50%;
  }
}
@media (min-width: 768px) {
  .login-pf-page .login-pf-social-section:first-of-type {
    padding-right: 40px;
  }
  .login-pf-page .login-pf-social-section:last-of-type {
    padding-left: 39px;
    border-left: 1px solid #d1d1d1;
  }
}
.login-pf-page .login-pf-social {
  margin: 40px 0 0 0;
}
@media (min-width: 768px) {
  .login-pf-page .login-pf-social {
    margin: 0;
  }
  .login-pf-page .login-pf-social-double-col {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
  }
  .login-pf-page .login-pf-social-double-col .login-pf-social-link {
    -ms-flex-preferred-size: 48%;
        flex-basis: 48%;
  }
}
.login-pf-page .login-pf-social:not(.login-pf-social-all) .login-pf-social-link-more {
  display: none;
}
.login-pf-page .login-pf-social-link {
  margin-bottom: 15px;
}
.login-pf-page .login-pf-social-link a {
  display: block;
  padding: 7px 0;
  font-size: 14px;
  background: #f5f5f5;
  text-align: center;
  color: #72767b;
}
.login-pf-page .login-pf-social-link a img {
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
}
.login-pf-page .login-pf-social-link a:hover {
  text-decoration: none;
  background: #ededed;
}
.login-pf-page .login-pf-social-toggle {
  float: right;
  padding: 0;
}
.login-pf-page .login-pf-social-toggle .caret {
  margin-left: 5px;
}
.login-pf-page .login-pf-social-toggle-active .caret {
  -ms-transform: rotate(-180deg);
      transform: rotate(-180deg);
}
.navbar-pf {
  background: #393f45;
  border: 0;
  border-radius: 0;
  border-top: 3px solid #cc0000;
  margin-bottom: 0;
  min-height: 0;
}
.navbar-pf .navbar-brand {
  color: #fff;
  height: auto;
  padding: 12px 0;
  margin: 0 0 0 20px;
}
.navbar-pf .navbar-brand img {
  display: block;
}
.navbar-pf .navbar-collapse {
  border-top: 0;
  box-shadow: none;
  padding: 0;
}
.navbar-pf .navbar-header {
  border-bottom: 1px solid #53565b;
  float: none;
}
.navbar-pf .navbar-nav {
  margin: 0;
}
.navbar-pf .navbar-nav > .active > a,
.navbar-pf .navbar-nav > .active > button,
.navbar-pf .navbar-nav > .active > a:hover,
.navbar-pf .navbar-nav > .active > button:hover,
.navbar-pf .navbar-nav > .active > a:focus,
.navbar-pf .navbar-nav > .active > button:focus {
  background-color: rgba(255, 255, 255, 0.24);
  color: #fff;
}
.navbar-pf .navbar-nav > li > a,
.navbar-pf .navbar-nav > li > button {
  color: #dbdada;
  line-height: 1;
  padding: 10px 20px;
  text-shadow: none;
}
.navbar-pf .navbar-nav > li > a:hover,
.navbar-pf .navbar-nav > li > button:hover,
.navbar-pf .navbar-nav > li > a:focus,
.navbar-pf .navbar-nav > li > button:focus {
  color: #fff;
  text-decoration: none;
}
.navbar-pf .navbar-nav > li > a > .dropdown-title,
.navbar-pf .navbar-nav > li > button > .dropdown-title,
.navbar-pf .navbar-nav > li > a > .pficon-user,
.navbar-pf .navbar-nav > li > button > .pficon-user {
  position: relative;
}
.navbar-pf .navbar-nav > li > a > .pficon-user,
.navbar-pf .navbar-nav > li > button > .pficon-user {
  padding-right: 4px;
}
.navbar-pf .navbar-nav > .open > a,
.navbar-pf .navbar-nav > .open > button,
.navbar-pf .navbar-nav > .open > a:hover,
.navbar-pf .navbar-nav > .open > button:hover,
.navbar-pf .navbar-nav > .open > a:focus,
.navbar-pf .navbar-nav > .open > button:focus {
  background-color: rgba(255, 255, 255, 0.24);
  color: #fff;
  outline: 0;
}
.navbar-pf .navbar-nav .badge {
  background-color: #0088ce;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  font-size: 9px;
  font-weight: 700;
  left: 26px;
  margin: 0;
  padding: 2px 4px;
  position: absolute;
  min-width: 10px;
  min-height: 10px;
  top: 6px;
}
@media (min-width: 768px) {
  .navbar-pf .navbar-nav .badge {
    left: auto;
    right: 6px;
    top: 3px;
  }
}
.navbar-pf .navbar-nav .badge.badge-pf-bordered {
  border: 1px solid #393f45;
}
@media (max-width: 767px) {
  .navbar-pf .navbar-nav .active .dropdown-menu,
  .navbar-pf .navbar-nav .active .navbar-persistent,
  .navbar-pf .navbar-nav .open .dropdown-menu {
    background-color: #3c434a !important;
    margin-left: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
  .navbar-pf .navbar-nav .active .dropdown-menu > .active > a,
  .navbar-pf .navbar-nav .active .navbar-persistent > .active > a,
  .navbar-pf .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-pf .navbar-nav .active .dropdown-menu .dropdown-submenu.open > a,
  .navbar-pf .navbar-nav .active .navbar-persistent .dropdown-submenu.open > a,
  .navbar-pf .navbar-nav .open .dropdown-menu .dropdown-submenu.open > a,
  .navbar-pf .navbar-nav .active .dropdown-menu > .active > a:hover,
  .navbar-pf .navbar-nav .active .navbar-persistent > .active > a:hover,
  .navbar-pf .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-pf .navbar-nav .active .dropdown-menu .dropdown-submenu.open > a:hover,
  .navbar-pf .navbar-nav .active .navbar-persistent .dropdown-submenu.open > a:hover,
  .navbar-pf .navbar-nav .open .dropdown-menu .dropdown-submenu.open > a:hover,
  .navbar-pf .navbar-nav .active .dropdown-menu > .active > a:focus,
  .navbar-pf .navbar-nav .active .navbar-persistent > .active > a:focus,
  .navbar-pf .navbar-nav .open .dropdown-menu > .active > a:focus,
  .navbar-pf .navbar-nav .active .dropdown-menu .dropdown-submenu.open > a:focus,
  .navbar-pf .navbar-nav .active .navbar-persistent .dropdown-submenu.open > a:focus,
  .navbar-pf .navbar-nav .open .dropdown-menu .dropdown-submenu.open > a:focus {
    background-color: rgba(255, 255, 255, 0.24) !important;
    color: #fff;
  }
  .navbar-pf .navbar-nav .active .dropdown-menu > li > a,
  .navbar-pf .navbar-nav .active .navbar-persistent > li > a,
  .navbar-pf .navbar-nav .open .dropdown-menu > li > a {
    background-color: transparent;
    border: 0;
    color: #dbdada;
    outline: none;
    padding-left: 30px;
  }
  .navbar-pf .navbar-nav .active .dropdown-menu > li > a:hover,
  .navbar-pf .navbar-nav .active .navbar-persistent > li > a:hover,
  .navbar-pf .navbar-nav .open .dropdown-menu > li > a:hover {
    color: #fff;
  }
  .navbar-pf .navbar-nav .active .dropdown-menu .divider,
  .navbar-pf .navbar-nav .active .navbar-persistent .divider,
  .navbar-pf .navbar-nav .open .dropdown-menu .divider {
    background-color: #53565b;
    margin: 0 1px;
  }
  .navbar-pf .navbar-nav .active .dropdown-menu .dropdown-header,
  .navbar-pf .navbar-nav .active .navbar-persistent .dropdown-header,
  .navbar-pf .navbar-nav .open .dropdown-menu .dropdown-header {
    padding-bottom: 0;
    padding-left: 30px;
  }
  .navbar-pf .navbar-nav .active .dropdown-menu .dropdown-submenu.open .dropdown-toggle,
  .navbar-pf .navbar-nav .active .navbar-persistent .dropdown-submenu.open .dropdown-toggle,
  .navbar-pf .navbar-nav .open .dropdown-menu .dropdown-submenu.open .dropdown-toggle {
    color: #fff;
  }
  .navbar-pf .navbar-nav .active .dropdown-menu .dropdown-submenu.pull-left,
  .navbar-pf .navbar-nav .active .navbar-persistent .dropdown-submenu.pull-left,
  .navbar-pf .navbar-nav .open .dropdown-menu .dropdown-submenu.pull-left {
    float: none !important;
  }
  .navbar-pf .navbar-nav .active .dropdown-menu .dropdown-submenu > a:after,
  .navbar-pf .navbar-nav .active .navbar-persistent .dropdown-submenu > a:after,
  .navbar-pf .navbar-nav .open .dropdown-menu .dropdown-submenu > a:after {
    display: none;
  }
  .navbar-pf .navbar-nav .active .dropdown-menu .dropdown-submenu .dropdown-header,
  .navbar-pf .navbar-nav .active .navbar-persistent .dropdown-submenu .dropdown-header,
  .navbar-pf .navbar-nav .open .dropdown-menu .dropdown-submenu .dropdown-header {
    padding-left: 45px;
  }
  .navbar-pf .navbar-nav .active .dropdown-menu .dropdown-submenu .dropdown-menu,
  .navbar-pf .navbar-nav .active .navbar-persistent .dropdown-submenu .dropdown-menu,
  .navbar-pf .navbar-nav .open .dropdown-menu .dropdown-submenu .dropdown-menu {
    border: 0;
    bottom: auto;
    box-shadow: none;
    display: block;
    float: none;
    margin: 0;
    min-width: 0;
    padding: 0;
    position: relative;
    left: auto;
    right: auto;
    top: auto;
  }
  .navbar-pf .navbar-nav .active .dropdown-menu .dropdown-submenu .dropdown-menu > li > a,
  .navbar-pf .navbar-nav .active .navbar-persistent .dropdown-submenu .dropdown-menu > li > a,
  .navbar-pf .navbar-nav .open .dropdown-menu .dropdown-submenu .dropdown-menu > li > a {
    padding: 5px 15px 5px 45px;
    line-height: 20px;
  }
  .navbar-pf .navbar-nav .active .dropdown-menu .dropdown-submenu .dropdown-menu .dropdown-menu > li > a,
  .navbar-pf .navbar-nav .active .navbar-persistent .dropdown-submenu .dropdown-menu .dropdown-menu > li > a,
  .navbar-pf .navbar-nav .open .dropdown-menu .dropdown-submenu .dropdown-menu .dropdown-menu > li > a {
    padding-left: 60px;
  }
  .navbar-pf .navbar-nav .active .navbar-persistent .dropdown-submenu.open .dropdown-menu {
    display: block;
  }
  .navbar-pf .navbar-nav .active .navbar-persistent .dropdown-submenu > a:after {
    display: inline-block !important;
    position: relative;
    right: auto;
    top: 1px;
  }
  .navbar-pf .navbar-nav .active .navbar-persistent .dropdown-submenu .dropdown-menu {
    display: none;
  }
  .navbar-pf .navbar-nav .active .navbar-persistent .dropdown-submenu .dropdown-submenu > a:after {
    display: none !important;
  }
  .navbar-pf .navbar-nav .context-bootstrap-select .open > .dropdown-menu {
    background-color: #fff !important;
  }
  .navbar-pf .navbar-nav .context-bootstrap-select .open > .dropdown-menu > .active > a,
  .navbar-pf .navbar-nav .context-bootstrap-select .open > .dropdown-menu > .active > a:active {
    background-color: #def3ff !important;
    border-color: #bee1f4 !important;
    color: #363636 !important;
  }
  .navbar-pf .navbar-nav .context-bootstrap-select .open > .dropdown-menu > .active > a small,
  .navbar-pf .navbar-nav .context-bootstrap-select .open > .dropdown-menu > .active > a:active small {
    color: #9c9c9c !important;
  }
  .navbar-pf .navbar-nav .context-bootstrap-select .open > .dropdown-menu > .disabled > a {
    color: #9c9c9c !important;
  }
  .navbar-pf .navbar-nav .context-bootstrap-select .open > .dropdown-menu > .selected > a,
  .navbar-pf .navbar-nav .context-bootstrap-select .open > .dropdown-menu > .selected > a:active {
    background-color: #0088ce !important;
    border-color: #0088ce !important;
    color: #fff !important;
  }
  .navbar-pf .navbar-nav .context-bootstrap-select .open > .dropdown-menu > .selected > a small,
  .navbar-pf .navbar-nav .context-bootstrap-select .open > .dropdown-menu > .selected > a:active small {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  .navbar-pf .navbar-nav .context-bootstrap-select .open > .dropdown-menu li > a.opt {
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    color: #363636;
    padding-left: 10px;
    padding-right: 10px;
  }
  .navbar-pf .navbar-nav .context-bootstrap-select .open > .dropdown-menu li a:active small {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  .navbar-pf .navbar-nav .context-bootstrap-select .open > .dropdown-menu li a:hover small,
  .navbar-pf .navbar-nav .context-bootstrap-select .open > .dropdown-menu li a:focus small {
    color: #9c9c9c;
  }
  .navbar-pf .navbar-nav .context-bootstrap-select > .open > .dropdown-menu {
    padding-bottom: 5px;
    padding-top: 5px;
  }
}
.navbar-pf .navbar-persistent {
  display: none;
}
.navbar-pf .active > .navbar-persistent {
  display: block;
}
.navbar-pf .navbar-primary {
  float: none;
}
.navbar-pf .navbar-primary .context {
  border-bottom: 1px solid #53565b;
}
.navbar-pf .navbar-primary .context.context-bootstrap-select .bootstrap-select.btn-group,
.navbar-pf .navbar-primary .context.context-bootstrap-select .bootstrap-select.btn-group[class*="span"] {
  margin: 8px 20px 9px;
  width: auto;
}
.navbar-pf .navbar-primary > li > .navbar-persistent > .dropdown-submenu > a {
  position: relative;
}
.navbar-pf .navbar-primary > li > .navbar-persistent > .dropdown-submenu > a:after {
  content: "\f107";
  display: inline-block;
  font-family: "FontAwesome";
  font-weight: normal;
}
@media (max-width: 767px) {
  .navbar-pf .navbar-primary > li > .navbar-persistent > .dropdown-submenu > a:after {
    height: 10px;
    margin-left: 4px;
    vertical-align: baseline;
  }
}
.navbar-pf .navbar-toggle {
  border: 0;
  margin: 0;
  padding: 10px 20px;
}
.navbar-pf .navbar-toggle:hover,
.navbar-pf .navbar-toggle:focus {
  background-color: transparent;
  outline: none;
}
.navbar-pf .navbar-toggle:hover .icon-bar,
.navbar-pf .navbar-toggle:focus .icon-bar {
  box-shadow: 0 0 3px #fff;
}
.navbar-pf .navbar-toggle .icon-bar {
  background-color: #fff;
}
.navbar-pf .navbar-utility {
  border-bottom: 1px solid #53565b;
}
.navbar-pf .navbar-utility li.dropdown > a,
.navbar-pf .navbar-utility li.dropdown > button {
  position: relative;
}
.navbar-pf .navbar-utility li.dropdown > a:hover,
.navbar-pf .navbar-utility li.dropdown > button:hover {
  outline: 0!important;
}
.navbar-pf .navbar-utility li.dropdown > a > .fa,
.navbar-pf .navbar-utility li.dropdown > button > .fa,
.navbar-pf .navbar-utility li.dropdown > a .fas,
.navbar-pf .navbar-utility li.dropdown > button .fas,
.navbar-pf .navbar-utility li.dropdown > a .far,
.navbar-pf .navbar-utility li.dropdown > button .far,
.navbar-pf .navbar-utility li.dropdown > a .fab,
.navbar-pf .navbar-utility li.dropdown > button .fab,
.navbar-pf .navbar-utility li.dropdown > a .fal,
.navbar-pf .navbar-utility li.dropdown > button .fal,
.navbar-pf .navbar-utility li.dropdown > a .pficon,
.navbar-pf .navbar-utility li.dropdown > button .pficon {
  line-height: 0;
}
@media (max-width: 767px) {
  .navbar-pf .navbar-utility > li + li {
    border-top: 1px solid #53565b;
  }
}
@media (min-width: 768px) {
  .navbar-pf .navbar-brand {
    padding: 7px 0 8px;
  }
  .navbar-pf .navbar-nav > li > a,
  .navbar-pf .navbar-nav > li > button {
    padding-bottom: 14px;
    padding-top: 14px;
  }
  .navbar-pf .navbar-persistent {
    font-size: 14px;
  }
  .navbar-pf .navbar-primary {
    font-size: 14px;
    background-image: linear-gradient(to bottom, #474c50 0%, #383f43 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff474c50', endColorstr='#ff383f43', GradientType=0);
    background-repeat: repeat-x;
  }
  .navbar-pf .navbar-primary.persistent-secondary .context .dropdown-menu {
    top: auto;
  }
  .navbar-pf .navbar-primary.persistent-secondary .dropup .dropdown-menu {
    bottom: -5px;
    top: auto;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li {
    position: static;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li.active {
    margin-bottom: 32px;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li.active > .navbar-persistent {
    display: block;
    left: 0;
    position: absolute;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent {
    background: #f6f6f6;
    border-bottom: 1px solid #cecdcd;
    padding: 0;
    width: 100%;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent a {
    text-decoration: none !important;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li.active:before,
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li.active:hover:before {
    background: #0088ce;
    bottom: -1px;
    content: "";
    display: block;
    height: 2px;
    left: 20px;
    position: absolute;
    right: 20px;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li.active > a,
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li.active > a:hover,
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li.active:hover > a {
    color: #0088ce !important;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li.active .active > a {
    color: #fff;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li.dropdown-submenu:hover > .dropdown-menu {
    display: none;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li.dropdown-submenu.open > .dropdown-menu {
    display: block;
    left: 20px;
    margin-top: 1px;
    top: 100%;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li.dropdown-submenu.open > .dropdown-toggle {
    color: #252525;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li.dropdown-submenu.open > .dropdown-toggle:after {
    border-top-color: #252525;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li.dropdown-submenu > .dropdown-toggle {
    padding-right: 35px !important;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li.dropdown-submenu > .dropdown-toggle:after {
    position: absolute;
    right: 20px;
    top: 10px;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li:hover:before,
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li.open:before {
    background: #bbb;
    bottom: -1px;
    content: "";
    display: block;
    height: 2px;
    left: 20px;
    position: absolute;
    right: 20px;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li:hover > a,
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li.open > a {
    color: #252525;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li:hover > a:after,
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li.open > a:after {
    border-top-color: #252525;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li > a {
    background-color: transparent;
    display: block;
    line-height: 1;
    padding: 9px 20px;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li > a.dropdown-toggle {
    padding-right: 35px;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li > a.dropdown-toggle:after {
    font-size: 15px;
    position: absolute;
    right: 20px;
    top: 9px;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li > a:hover {
    color: #252525;
  }
  .navbar-pf .navbar-primary.persistent-secondary > li > .navbar-persistent > li a {
    color: #4d5258;
  }
  .navbar-pf .navbar-primary > li > a,
  .navbar-pf .navbar-primary > li > button {
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    position: relative;
    margin: -1px 0 0;
  }
  .navbar-pf .navbar-primary > li > a:hover,
  .navbar-pf .navbar-primary > li > button:hover {
    background-color: rgba(255, 255, 255, 0.14);
    border-top-color: #949699;
    color: #dbdada;
    background-image: linear-gradient(to bottom, #5c6165 0%, #4b5053 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5c6165', endColorstr='#ff4b5053', GradientType=0);
    background-repeat: repeat-x;
  }
  .navbar-pf .navbar-primary > .active > a,
  .navbar-pf .navbar-primary .open > a,
  .navbar-pf .navbar-primary > .active > button,
  .navbar-pf .navbar-primary .open > button,
  .navbar-pf .navbar-primary > .active > a:hover,
  .navbar-pf .navbar-primary .open > a:hover,
  .navbar-pf .navbar-primary > .active > button:hover,
  .navbar-pf .navbar-primary .open > button:hover,
  .navbar-pf .navbar-primary > .active > a:focus,
  .navbar-pf .navbar-primary .open > a:focus,
  .navbar-pf .navbar-primary > .active > button:focus,
  .navbar-pf .navbar-primary .open > button:focus {
    background-color: rgba(255, 255, 255, 0.24);
    border-bottom-color: #64686c;
    border-top-color: #949699;
    box-shadow: none;
    color: #fff;
    background-image: linear-gradient(to bottom, #72757a 0%, #64686c 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff72757a', endColorstr='#ff64686c', GradientType=0);
    background-repeat: repeat-x;
  }
  .navbar-pf .navbar-primary li.context.context-bootstrap-select .filter-option {
    max-width: 160px;
    text-overflow: ellipsis;
  }
  .navbar-pf .navbar-primary li.context.dropdown {
    border-bottom: 0;
  }
  .navbar-pf .navbar-primary li.context > a,
  .navbar-pf .navbar-primary li.context.context-bootstrap-select {
    background-color: #505458;
    border-bottom-color: #65696d;
    border-right: 1px solid #65696d;
    border-top-color: #64696d;
    font-weight: 600;
    background-image: linear-gradient(to bottom, #585d61 0%, #505458 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff585d61', endColorstr='#ff505458', GradientType=0);
    background-repeat: repeat-x;
  }
  .navbar-pf .navbar-primary li.context > a:hover,
  .navbar-pf .navbar-primary li.context.context-bootstrap-select:hover {
    background-color: #5a5e62;
    border-bottom-color: #6e7276;
    border-right-color: #6e7276;
    border-top-color: #6c7276;
    background-image: linear-gradient(to bottom, #62676b 0%, #5a5e62 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff62676b', endColorstr='#ff5a5e62', GradientType=0);
    background-repeat: repeat-x;
  }
  .navbar-pf .navbar-primary li.context.open > a {
    background-color: #65696d;
    border-bottom-color: #6e7276;
    border-right-color: #777a7e;
    border-top-color: #767a7e;
    background-image: linear-gradient(to bottom, #6b7175 0%, #65696d 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff6b7175', endColorstr='#ff65696d', GradientType=0);
    background-repeat: repeat-x;
  }
  .navbar-pf .navbar-utility {
    border-bottom: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .navbar-pf .navbar-utility > .active > a,
  .navbar-pf .navbar-utility > .open > a,
  .navbar-pf .navbar-utility > .active > button,
  .navbar-pf .navbar-utility > .open > button,
  .navbar-pf .navbar-utility > .active > a:hover,
  .navbar-pf .navbar-utility > .open > a:hover,
  .navbar-pf .navbar-utility > .active > button:hover,
  .navbar-pf .navbar-utility > .open > button:hover,
  .navbar-pf .navbar-utility > .active > a:focus,
  .navbar-pf .navbar-utility > .open > a:focus,
  .navbar-pf .navbar-utility > .active > button:focus,
  .navbar-pf .navbar-utility > .open > button:focus {
    background: rgba(255, 255, 255, 0.24);
    color: #fff;
  }
  .navbar-pf .navbar-utility > li > a,
  .navbar-pf .navbar-utility > li > button {
    border: solid #53565b;
    border-width: 0 0 0 1px;
    color: #fff !important;
    padding: 7px 10px;
    font-size: 11px;
    margin: 0;
  }
  .navbar-pf .navbar-utility > li > a:hover,
  .navbar-pf .navbar-utility > li > button:hover {
    background: rgba(255, 255, 255, 0.14);
    border-left-color: #636466;
    outline: 0;
  }
  .navbar-pf .navbar-utility > li.open > a,
  .navbar-pf .navbar-utility > li.open > button {
    border-left-color: #6c6e70;
    color: #fff !important;
  }
  .navbar-pf .navbar-utility .open .dropdown-menu {
    left: auto;
    right: 0;
  }
  .navbar-pf .navbar-utility .open .dropdown-menu .dropdown-menu {
    left: auto;
    right: 100%;
  }
  .navbar-pf .navbar-utility .open .dropdown-menu {
    border-top-width: 0;
  }
  .navbar-pf .open.bootstrap-select .dropdown-menu,
  .navbar-pf .open .dropdown-submenu > .dropdown-menu {
    border-top-width: 1px !important;
  }
}
@media (max-width: 360px) {
  .navbar-pf .navbar-brand {
    margin-left: 10px;
    width: 75%;
  }
  .navbar-pf .navbar-brand img {
    height: auto;
    max-width: 100%;
  }
  .navbar-pf .navbar-toggle {
    padding-left: 0;
  }
}
.navbar-pf-alt {
  background-color: #030303;
  background-image: "bg-navbar-pf-alt.svg";
  background-repeat: no-repeat;
  background-size: auto 100%;
  border: none;
  border-radius: 0;
  border-top: 0 solid #cc0000;
  margin-bottom: 0;
}
.navbar-pf-alt .infotip.bottom-right .arrow {
  left: 90%;
}
.layout-pf-alt-fixed .navbar-pf-alt {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1030;
}
.navbar-pf-alt .nav.navbar-nav > li > .dropdown-menu.infotip {
  margin-top: 0;
}
.navbar-pf-alt .nav .nav-item-iconic {
  cursor: pointer;
  line-height: 1;
  max-height: 60px;
  padding: 22px 12px;
  position: relative;
}
.navbar-pf-alt .nav .nav-item-iconic:hover,
.navbar-pf-alt .nav .nav-item-iconic:focus {
  background-color: transparent;
}
.navbar-pf-alt .nav .nav-item-iconic:hover .caret,
.navbar-pf-alt .nav .nav-item-iconic:focus .caret,
.navbar-pf-alt .nav .nav-item-iconic:hover .fa,
.navbar-pf-alt .nav .nav-item-iconic:focus .fa,
.navbar-pf-alt .nav .nav-item-iconic:hover .fas,
.navbar-pf-alt .nav .nav-item-iconic:focus .fas,
.navbar-pf-alt .nav .nav-item-iconic:hover .far,
.navbar-pf-alt .nav .nav-item-iconic:focus .far,
.navbar-pf-alt .nav .nav-item-iconic:hover .fab,
.navbar-pf-alt .nav .nav-item-iconic:focus .fab,
.navbar-pf-alt .nav .nav-item-iconic:hover .fal,
.navbar-pf-alt .nav .nav-item-iconic:focus .fal,
.navbar-pf-alt .nav .nav-item-iconic:hover .glyphicon,
.navbar-pf-alt .nav .nav-item-iconic:focus .glyphicon,
.navbar-pf-alt .nav .nav-item-iconic:hover .pficon,
.navbar-pf-alt .nav .nav-item-iconic:focus .pficon {
  color: #fff;
}
.navbar-pf-alt .nav .nav-item-iconic .badge {
  background-color: #0088ce;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  font-size: 9px;
  font-weight: 700;
  margin: 0 0 -11px -12px;
  min-width: 0;
  padding: 2px 4px;
}
.navbar-pf-alt .nav .nav-item-iconic .caret,
.navbar-pf-alt .nav .nav-item-iconic .fa,
.navbar-pf-alt .nav .nav-item-iconic .fas,
.navbar-pf-alt .nav .nav-item-iconic .far,
.navbar-pf-alt .nav .nav-item-iconic .fab,
.navbar-pf-alt .nav .nav-item-iconic .fal,
.navbar-pf-alt .nav .nav-item-iconic .pficon {
  color: #d1d1d1;
  font-size: 16px;
}
.navbar-pf-alt .nav .nav-item-iconic .caret {
  font-size: 12px;
  width: auto;
}
.navbar-pf-alt .nav .open > .nav-item-iconic,
.navbar-pf-alt .nav .open > .nav-item-iconic:hover,
.navbar-pf-alt .nav .open > .nav-item-iconic:focus {
  background: transparent;
}
.navbar-pf-alt .nav .open > .nav-item-iconic .caret,
.navbar-pf-alt .nav .open > .nav-item-iconic:hover .caret,
.navbar-pf-alt .nav .open > .nav-item-iconic:focus .caret,
.navbar-pf-alt .nav .open > .nav-item-iconic .fa,
.navbar-pf-alt .nav .open > .nav-item-iconic:hover .fa,
.navbar-pf-alt .nav .open > .nav-item-iconic:focus .fa,
.navbar-pf-alt .nav .open > .nav-item-iconic .fas,
.navbar-pf-alt .nav .open > .nav-item-iconic:hover .fas,
.navbar-pf-alt .nav .open > .nav-item-iconic:focus .fas,
.navbar-pf-alt .nav .open > .nav-item-iconic .far,
.navbar-pf-alt .nav .open > .nav-item-iconic:hover .far,
.navbar-pf-alt .nav .open > .nav-item-iconic:focus .far,
.navbar-pf-alt .nav .open > .nav-item-iconic .fab,
.navbar-pf-alt .nav .open > .nav-item-iconic:hover .fab,
.navbar-pf-alt .nav .open > .nav-item-iconic:focus .fab,
.navbar-pf-alt .nav .open > .nav-item-iconic .fal,
.navbar-pf-alt .nav .open > .nav-item-iconic:hover .fal,
.navbar-pf-alt .nav .open > .nav-item-iconic:focus .fal,
.navbar-pf-alt .nav .open > .nav-item-iconic .pficon,
.navbar-pf-alt .nav .open > .nav-item-iconic:hover .pficon,
.navbar-pf-alt .nav .open > .nav-item-iconic:focus .pficon {
  color: #fff;
}
.navbar-pf-alt .navbar-brand {
  color: #fff;
  height: auto;
  margin: 0 0 0 25px;
  min-height: 35px;
  padding: 18px 0 22px;
}
.navbar-pf-alt .navbar-brand .navbar-brand-name {
  display: inline;
  margin-left: 0 15px 0 0;
  margin-right: 0 15px 0 0;
}
@media (max-width: 355px) {
  .navbar-pf-alt .navbar-brand .navbar-brand-name {
    display: none;
  }
}
.navbar-pf-alt .navbar-brand .navbar-brand-icon {
  display: inline;
  margin-left: 0 15px 0 0;
  margin-right: 0 15px 0 0;
}
.navbar-pf-alt .navbar-iconic {
  margin-right: 0;
}
.navbar-pf-alt .navbar-toggle {
  border: 0;
  display: block;
  float: left;
  margin: 14px 15px;
}
.navbar-pf-alt .navbar-toggle:hover .icon-bar,
.navbar-pf-alt .navbar-toggle:focus .icon-bar {
  background: #fff;
}
.navbar-pf-alt .navbar-toggle + .navbar-brand {
  margin-left: 0;
}
.navbar-pf-alt .navbar-toggle .icon-bar {
  background: #d1d1d1;
}
.navbar-pf-vertical {
  background-color: #1d1d1d;
  border: none;
  border-radius: 0;
  border-top: 2px solid #cc0000;
  margin-bottom: 0;
}
.navbar-pf-vertical .infotip.bottom-right .arrow {
  left: 90%;
}
.layout-pf-fixed .navbar-pf-vertical {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1030;
}
.navbar-pf-vertical .nav.navbar-nav > li > .dropdown-menu.infotip {
  margin-top: 0;
}
.navbar-pf-vertical .nav .nav-item-iconic {
  color: #d1d1d1;
  cursor: pointer;
  line-height: 1;
  max-height: 58px;
  padding: 21px 12px;
  position: relative;
  margin: 0;
  border: 0;
}
.navbar-pf-vertical .nav .nav-item-iconic:hover {
  outline: 0!important;
}
.navbar-pf-vertical .nav .nav-item-iconic:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
.navbar-pf-vertical .nav .nav-item-iconic:hover,
.navbar-pf-vertical .nav .nav-item-iconic:focus {
  background-color: rgba(255, 255, 255, 0.14);
  text-decoration: none;
}
.navbar-pf-vertical .nav .nav-item-iconic .badge {
  background-color: #0088ce;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  font-size: 9px;
  font-weight: 700;
  left: 20px;
  margin: 0;
  padding: 2px 4px;
  position: absolute;
  min-width: 10px;
  min-height: 10px;
  top: 18px;
}
.navbar-pf-vertical .nav .nav-item-iconic .badge.badge-pf-bordered {
  border: 1px solid #1d1d1d;
}
.navbar-pf-vertical .nav .nav-item-iconic .caret,
.navbar-pf-vertical .nav .nav-item-iconic .fa,
.navbar-pf-vertical .nav .nav-item-iconic .fas,
.navbar-pf-vertical .nav .nav-item-iconic .far,
.navbar-pf-vertical .nav .nav-item-iconic .fab,
.navbar-pf-vertical .nav .nav-item-iconic .fal,
.navbar-pf-vertical .nav .nav-item-iconic .pficon {
  color: #d1d1d1;
  font-size: 16px;
  position: relative;
}
.navbar-pf-vertical .nav .nav-item-iconic .caret {
  font-size: 12px;
  width: auto;
}
.navbar-pf-vertical .nav .nav-item-iconic > .dropdown-title {
  position: relative;
  display: inline-block;
  line-height: 1.55;
}
.navbar-pf-vertical .nav .nav-item-iconic > .pficon-user {
  padding-right: calc(4px + 3px);
  float: left;
}
.navbar-pf-vertical .nav .open > .nav-item-iconic,
.navbar-pf-vertical .nav .open > .nav-item-iconic:hover,
.navbar-pf-vertical .nav .open > .nav-item-iconic:focus {
  background: rgba(255, 255, 255, 0.24);
  color: #fff;
  outline: 0;
}
.navbar-pf-vertical .nav .open > .nav-item-iconic .caret,
.navbar-pf-vertical .nav .open > .nav-item-iconic:hover .caret,
.navbar-pf-vertical .nav .open > .nav-item-iconic:focus .caret,
.navbar-pf-vertical .nav .open > .nav-item-iconic .fa,
.navbar-pf-vertical .nav .open > .nav-item-iconic:hover .fa,
.navbar-pf-vertical .nav .open > .nav-item-iconic:focus .fa,
.navbar-pf-vertical .nav .open > .nav-item-iconic .fas,
.navbar-pf-vertical .nav .open > .nav-item-iconic:hover .fas,
.navbar-pf-vertical .nav .open > .nav-item-iconic:focus .fas,
.navbar-pf-vertical .nav .open > .nav-item-iconic .far,
.navbar-pf-vertical .nav .open > .nav-item-iconic:hover .far,
.navbar-pf-vertical .nav .open > .nav-item-iconic:focus .far,
.navbar-pf-vertical .nav .open > .nav-item-iconic .fab,
.navbar-pf-vertical .nav .open > .nav-item-iconic:hover .fab,
.navbar-pf-vertical .nav .open > .nav-item-iconic:focus .fab,
.navbar-pf-vertical .nav .open > .nav-item-iconic .fal,
.navbar-pf-vertical .nav .open > .nav-item-iconic:hover .fal,
.navbar-pf-vertical .nav .open > .nav-item-iconic:focus .fal,
.navbar-pf-vertical .nav .open > .nav-item-iconic .pficon,
.navbar-pf-vertical .nav .open > .nav-item-iconic:hover .pficon,
.navbar-pf-vertical .nav .open > .nav-item-iconic:focus .pficon {
  color: #fff;
}
.navbar-pf-vertical .navbar-brand {
  color: #fff;
  height: auto;
  margin: 0 0 0 25px;
  min-height: 35px;
  padding: 11px 0 12px;
}
.navbar-pf-vertical .navbar-brand .navbar-brand-name {
  display: inline-block;
  margin: 0 15px 0 0;
}
@media (max-width: 480px) {
  .navbar-pf-vertical .navbar-brand .navbar-brand-name {
    display: none;
  }
}
.navbar-pf-vertical .navbar-brand .navbar-brand-icon {
  display: inline-block;
  margin: 0 15px 0 0;
  max-height: 35px;
}
.navbar-pf-vertical .navbar-brand .navbar-brand-txt {
  line-height: 34px;
}
.navbar-pf-vertical .navbar-iconic {
  margin-right: 0;
}
.navbar-pf-vertical .navbar-toggle {
  border: 0;
  display: block;
  float: left;
  margin: 13px 15px;
}
.navbar-pf-vertical .navbar-toggle:hover .icon-bar,
.navbar-pf-vertical .navbar-toggle:focus .icon-bar {
  background: #fff;
}
.navbar-pf-vertical .navbar-toggle + .navbar-brand {
  margin-left: 0;
}
.navbar-pf-vertical .navbar-toggle .icon-bar {
  background: #d1d1d1;
}
.layout-pf-alt-fixed.transitions .nav-pf-vertical-alt {
  transition: all 200ms cubic-bezier(0.35, 0, 0.25, 1);
}
.layout-pf-alt-fixed.transitions .nav-pf-vertical-alt.collapsed .list-group-item .list-group-item-value {
  transition: opacity 0ms 100ms, opacity 100ms linear;
}
.layout-pf-alt-fixed.transitions .nav-pf-vertical-alt .list-group-item .badge {
  transition: all 200ms cubic-bezier(0.35, 0, 0.25, 1);
}
.layout-pf-alt-fixed.transitions .nav-pf-vertical-alt .list-group-item .list-group-item-value {
  transition: opacity 500ms ease-out;
  transition-delay: 150ms;
}
.nav-pf-vertical-alt {
  background: #fff;
}
.ie9.layout-pf-alt-fixed .nav-pf-vertical-alt {
  box-sizing: content-box;
}
.layout-pf-alt-fixed .nav-pf-vertical-alt {
  border-right: 1px solid #d1d1d1;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  left: 0;
  position: fixed;
  top: 60px;
  width: 250px;
  z-index: 1030;
}
.layout-pf-alt-fixed .nav-pf-vertical-alt.collapsed {
  width: 75px !important;
}
.layout-pf-alt-fixed .nav-pf-vertical-alt.collapsed .list-group-item .badge {
  padding: 2px 3px;
  right: 21px;
  top: 36px;
}
.layout-pf-alt-fixed .nav-pf-vertical-alt.collapsed .list-group-item .list-group-item-value {
  opacity: 0;
}
.layout-pf-alt-fixed .nav-pf-vertical-alt.hidden {
  display: none;
}
.layout-pf-alt-fixed .nav-pf-vertical-alt.hidden.show-mobile-nav {
  box-shadow: 0 0 3px rgba(3, 3, 3, 0.15);
  display: block !important;
}
.layout-pf-alt-fixed-with-footer .nav-pf-vertical-alt {
  bottom: 37px;
}
.nav-pf-vertical-alt .list-group {
  border-top: 0;
  margin-bottom: 0;
}
.nav-pf-vertical-alt .list-group-item {
  padding: 0;
}
.nav-pf-vertical-alt .list-group-item a {
  color: #363636;
  display: block;
  font-size: 14px;
  height: 63px;
  padding: 17px 20px 17px 25px;
  position: relative;
  white-space: nowrap;
}
.nav-pf-vertical-alt .list-group-item a:focus {
  color: #363636;
  text-decoration: none;
}
.nav-pf-vertical-alt .list-group-item a:hover {
  color: #39a5dc;
  text-decoration: none;
}
.nav-pf-vertical-alt .list-group-item.active {
  background-color: #fff;
  border-color: #f5f5f5;
}
.nav-pf-vertical-alt .list-group-item.active:before {
  background: #39a5dc;
  content: " ";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 5px;
}
.nav-pf-vertical-alt .list-group-item.active a {
  color: #39a5dc;
}
.nav-pf-vertical-alt .list-group-item .badge {
  background: #363636;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #fff;
  font-weight: 700;
  font-size: 9px;
  padding: 5px;
  position: absolute;
  right: 15px;
  text-align: center;
  top: 21px;
}
.nav-pf-vertical-alt .list-group-item .badge.notifications {
  background: #0088ce;
}
.nav-pf-vertical-alt .list-group-item .fa,
.nav-pf-vertical-alt .list-group-item .fas,
.nav-pf-vertical-alt .list-group-item .far,
.nav-pf-vertical-alt .list-group-item .fab,
.nav-pf-vertical-alt .list-group-item .fal,
.nav-pf-vertical-alt .list-group-item .glyphicon,
.nav-pf-vertical-alt .list-group-item .pficon {
  float: left;
  font-size: 18px;
  line-height: 30px;
  margin-right: 10px;
  text-align: center;
  width: 18px;
}
.nav-pf-vertical-alt .list-group-item .list-group-item-value {
  display: inline-block;
  line-height: 30px;
  opacity: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
}
.nav-pf-vertical-alt .list-group-item-separator {
  border-top-width: 5px;
}
.sidebar-pf .nav-pf-vertical-alt {
  margin-left: -20px;
  margin-right: -20px;
}
.drawer-pf {
  background-color: #fafafa;
  border: 1px solid #d1d1d1;
  box-shadow: 0 6px 12px rgba(3, 3, 3, 0.175);
  overflow-y: auto;
  position: absolute;
  right: 0;
  width: 320px;
  z-index: 2;
}
.drawer-pf .panel {
  border-bottom: none;
  border-left: none;
  border-right: none;
}
.drawer-pf .panel-group .panel-heading + .panel-collapse .panel-body {
  border-top: none;
  border-bottom: 1px solid #d1d1d1;
  padding: 0;
}
.drawer-pf .panel-counter {
  display: block;
  font-style: italic;
  line-height: 1.2;
  padding-left: 18px;
  padding-top: 5px;
}
.drawer-pf .panel-heading {
  border-bottom: 1px solid #d1d1d1;
}
.drawer-pf .panel-group {
  bottom: 0;
  margin-bottom: 0;
  position: absolute;
  top: 25px;
  width: 100%;
}
.drawer-pf .panel-title a {
  cursor: pointer;
  display: block;
}
.drawer-pf.drawer-pf-expanded {
  width: inherit;
}
@media (max-width: 767px) {
  .drawer-pf.drawer-pf-expanded {
    left: 0;
    width: 100%;
  }
}
@media (min-width: 767px) {
  .drawer-pf.drawer-pf-expanded {
    left: 270px;
  }
}
.drawer-pf.drawer-pf-expanded .drawer-pf-toggle-expand:before {
  content: "\f101";
}
.drawer-pf .blank-slate-pf {
  border: 0;
  margin-bottom: 0;
  padding: 30px;
}
.drawer-pf .blank-slate-pf .blank-slate-pf-icon {
  font-size: 3em;
  line-height: 1em;
}
.drawer-pf .blank-slate-pf h1 {
  font-size: 1em;
  margin-bottom: 0;
  margin-top: 15px;
}
.drawer-pf-close,
.drawer-pf-toggle-expand {
  color: #252525;
  cursor: pointer;
  line-height: inherit;
  padding: 2px 10px;
  position: absolute;
}
.drawer-pf-close:hover,
.drawer-pf-toggle-expand:hover,
.drawer-pf-close:focus,
.drawer-pf-toggle-expand:focus {
  color: #0088ce;
  text-decoration: none;
}
.drawer-pf-toggle-expand {
  left: 0;
}
.drawer-pf-toggle-expand:before {
  content: "\f100";
  font-family: "FontAwesome";
}
.drawer-pf-toggle-expand:hover {
  color: #0088ce;
}
.drawer-pf-close {
  right: 0;
}
.drawer-pf-action {
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #d1d1d1;
}
.drawer-pf-action .btn-link {
  color: #0088ce;
  padding: 10px 0;
}
.drawer-pf-action .btn-link .pficon,
.drawer-pf-action .btn-link .fa,
.drawer-pf-action .btn-link .fas,
.drawer-pf-action .btn-link .far,
.drawer-pf-action .btn-link .fab,
.drawer-pf-action .btn-link .fal {
  margin-right: 3px;
}
.drawer-pf-action .btn-link .pficon-close {
  position: relative;
  top: 1px;
}
.drawer-pf-action .btn-link:hover {
  color: #00659c;
}
.drawer-pf-action-link {
  border-left: solid 1px #d1d1d1;
  -ms-flex: 1 1 0%;
      flex: 1 1 0%;
  margin: 10px 0;
  text-align: center;
}
.drawer-pf-action-link:first-of-type {
  border-left-width: 0;
}
.drawer-pf-action-link .btn-link {
  padding: 0;
}
.drawer-pf-loading {
  color: #4d5258;
  font-size: 14px;
  padding: 20px 15px;
}
.drawer-pf-notification {
  border-bottom: 1px solid #d1d1d1;
  padding: 15px;
}
.drawer-pf-notification .date {
  border-right: 1px solid #aaa;
  display: inline-block;
  line-height: 1;
  margin-right: 5px;
  padding-right: 9px;
}
.drawer-pf-notification > .dropdown-kebab-pf {
  margin-top: -3px;
}
.drawer-pf-notification .pficon {
  font-size: 14px;
  margin-top: 3px;
}
.drawer-pf-notification:last-of-type {
  border-bottom: none;
}
.drawer-pf-notification:hover {
  background-color: #def3ff;
}
.drawer-pf-notification.unread .drawer-pf-notification-message {
  font-weight: bold;
  cursor: pointer;
}
.drawer-pf-notification.expanded-notification .date {
  border-right: none;
  padding-right: 0;
}
.drawer-pf-notification-content {
  cursor: default;
}
.drawer-pf-notification-info,
.drawer-pf-notification-message {
  display: block;
  padding-left: 27px;
  padding-right: 19px;
}
.expanded-notification .drawer-pf-notification-info,
.expanded-notification .drawer-pf-notification-message {
  display: inline-block;
}
.drawer-pf-title {
  background-color: #fafafa;
  border-bottom: 1px solid #d1d1d1;
  position: absolute;
  width: 100%;
}
.drawer-pf-title h3 {
  font-size: 12px;
  margin: 0;
  padding: 6px 15px;
}
.drawer-pf-notification.expanded-notification .drawer-pf-notification-content {
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 991px) {
  .drawer-pf-notification.expanded-notification .drawer-pf-notification-content {
    -ms-flex-direction: column;
        flex-direction: column;
  }
}
.drawer-pf-notification.expanded-notification .drawer-pf-notification-content .drawer-pf-notification-message {
  -ms-flex: 1 1;
      flex: 1 1;
}
/* Exclusive styles to work with Vertical Navigation */
.navbar-pf-vertical .drawer-pf {
  height: calc(100vh - 58px - 20px);
  top: 58px;
}
@media (max-width: 767px) {
  .navbar-pf-vertical .drawer-pf {
    width: 100%;
    height: calc(100vh - 58px);
  }
}
.navbar-pf-vertical .nav .drawer-pf-trigger .drawer-pf-trigger-icon {
  border-left: 1px solid #53565b;
  border-right: 1px solid #53565b;
  padding-left: 15px;
  padding-right: 15px;
}
.navbar-pf-vertical .nav .drawer-pf-trigger.open .drawer-pf-trigger-icon {
  background-color: #4a5053;
}
/* Exclusive styles to work with Horizontal Navigation */
.navbar-pf .drawer-pf {
  height: calc(100vh - 25px - 20px);
  top: 25px;
  border-top: 0;
}
@media (max-width: 767px) {
  .navbar-pf .drawer-pf {
    width: 100%;
    height: calc(100vh - 25px - 32px);
    top: calc(25px + 10px);
  }
}
.navbar-pf .drawer-pf-trigger-icon {
  cursor: pointer;
}
.search-pf.has-button {
  border-collapse: separate;
  display: table;
}
.search-pf.has-button .form-group {
  display: table-cell;
  width: 100%;
}
.search-pf.has-button .form-group .btn {
  box-shadow: none;
  float: left;
  margin-left: -1px;
}
.search-pf.has-button .form-group .btn.btn-lg {
  font-size: 14.5px;
}
.search-pf.has-button .form-group .btn.btn-sm {
  font-size: 10.7px;
}
.search-pf.has-button .form-group .form-control {
  float: left;
}
.search-pf .has-clear .clear {
  background: rgba(255, 255, 255, 0);
  border: 0;
  height: 25px;
  line-height: 1;
  padding: 0;
  position: absolute;
  right: 1px;
  top: 1px;
  width: 28px;
}
.search-pf .has-clear .clear:focus {
  outline: none;
}
.search-pf .has-clear .form-control {
  padding-right: 30px;
}
.search-pf .has-clear .form-control::-ms-clear {
  display: none;
}
.search-pf .has-clear .input-lg + .clear {
  height: 31px;
  width: 28px;
}
.search-pf .has-clear .input-sm + .clear {
  height: 20px;
  width: 28px;
}
.search-pf .has-clear .input-sm + .clear span {
  font-size: 10px;
}
.search-pf .has-clear .search-pf-input-group {
  position: relative;
}
.sidebar-header {
  border-bottom: 1px solid #ececec;
  padding-bottom: 11px;
  margin: 50px 0 20px;
}
.sidebar-header .actions {
  margin-top: -2px;
}
.sidebar-pf .sidebar-header + .list-group {
  border-top: 0;
  margin-top: -10px;
}
.sidebar-pf .sidebar-header + .list-group .list-group-item {
  background: transparent;
  border-color: #ececec;
  padding-left: 0;
}
.sidebar-pf .sidebar-header + .list-group .list-group-item-heading {
  font-size: 12px;
}
.sidebar-pf .nav-category h2 {
  color: #9c9c9c;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  margin: 0;
  padding: 8px 0;
}
.sidebar-pf .nav-category + .nav-category {
  margin-top: 10px;
}
.sidebar-pf .nav-pills > li.active > a {
  background: #0088ce !important;
  border-color: #0088ce !important;
  color: #fff;
}
@media (min-width: 768px) {
  .sidebar-pf .nav-pills > li.active > a:after {
    content: "\f105";
    font-family: "FontAwesome";
    display: block;
    position: absolute;
    right: 10px;
    top: 1px;
  }
}
.sidebar-pf .nav-pills > li.active > a .fa,
.sidebar-pf .nav-pills > li.active > a .fas,
.sidebar-pf .nav-pills > li.active > a .far,
.sidebar-pf .nav-pills > li.active > a .fab,
.sidebar-pf .nav-pills > li.active > a .fal {
  color: #fff;
}
.sidebar-pf .nav-pills > li > a {
  border-bottom: 1px solid transparent;
  border-radius: 0;
  border-top: 1px solid transparent;
  color: #363636;
  font-size: 13px;
  line-height: 21px;
  padding: 1px 20px;
}
.sidebar-pf .nav-pills > li > a:hover {
  background: #def3ff;
  border-color: #bee1f4;
}
.sidebar-pf .nav-pills > li > a .fa,
.sidebar-pf .nav-pills > li > a .fas,
.sidebar-pf .nav-pills > li > a .far,
.sidebar-pf .nav-pills > li > a .fab,
.sidebar-pf .nav-pills > li > a .fal {
  color: #6a7079;
  font-size: 15px;
  margin-right: 10px;
  text-align: center;
  vertical-align: middle;
  width: 15px;
}
.sidebar-pf .nav-stacked {
  margin-left: -20px;
  margin-right: -20px;
}
.sidebar-pf .nav-stacked li + li {
  margin-top: 0;
}
.sidebar-pf .panel {
  background: transparent;
}
.sidebar-pf .panel-body {
  padding: 6px 20px;
}
.sidebar-pf .panel-body .nav-pills > li > a {
  padding-left: 37px;
}
.sidebar-pf .panel-heading {
  padding: 9px 20px;
}
.sidebar-pf .panel-title {
  font-size: 12px;
}
.sidebar-pf .panel-title > a:before {
  display: inline-block;
  margin-left: 1px;
  margin-right: 4px;
  width: 9px;
}
.sidebar-pf .panel-title > a.collapsed:before {
  margin-left: 3px;
  margin-right: 2px;
}
@media (min-width: 767px) {
  .sidebar-header-bleed-left {
    margin-left: -20px;
  }
  .sidebar-header-bleed-left > h2 {
    margin-left: 20px;
  }
  .sidebar-header-bleed-right {
    margin-right: -20px;
  }
  .sidebar-header-bleed-right .actions {
    margin-right: 20px;
  }
  .sidebar-header-bleed-right > h2 {
    margin-right: 20px;
  }
  .sidebar-header-bleed-right + .list-group {
    margin-right: -20px;
  }
  .sidebar-pf .panel-group .panel-default,
  .sidebar-pf .treeview {
    border-left: 0;
    border-right: 0;
    margin-left: -20px;
    margin-right: -20px;
  }
  .sidebar-pf .treeview {
    margin-top: 5px;
  }
  .sidebar-pf .treeview .list-group-item {
    padding-left: 20px;
    padding-right: 20px;
  }
  .sidebar-pf .treeview .list-group-item.node-selected:after {
    content: "\f105";
    font-family: "FontAwesome";
    display: block;
    position: absolute;
    right: 10px;
    top: 1px;
  }
}
@media (min-width: 768px) {
  .sidebar-pf {
    background: #fafafa;
  }
  .sidebar-pf.sidebar-pf-left {
    border-right: 1px solid #d1d1d1;
  }
  .sidebar-pf.sidebar-pf-right {
    border-left: 1px solid #d1d1d1;
  }
  .sidebar-pf > .nav-category,
  .sidebar-pf > .nav-stacked {
    margin-top: 5px;
  }
}
.skiplink-pf {
  position: absolute;
  top: 12px;
  left: -300%;
  /* moves off screen */
  z-index: 1050;
}
.skiplink-pf:focus {
  left: 10px;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.spinner {
  animation: rotation 600ms infinite linear;
  border-bottom: 4px solid rgba(3, 3, 3, 0.25);
  border-left: 4px solid rgba(3, 3, 3, 0.25);
  border-right: 4px solid rgba(3, 3, 3, 0.25);
  border-radius: 100%;
  border-top: 4px solid rgba(3, 3, 3, 0.75);
  height: 24px;
  margin: 0 auto;
  position: relative;
  width: 24px;
}
.spinner.spinner-inline {
  display: inline-block;
  margin-right: 3px;
}
.spinner.spinner-lg {
  border-width: 5px;
  height: 30px;
  width: 30px;
}
.spinner.spinner-sm {
  border-width: 3px;
  height: 18px;
  width: 18px;
}
.spinner.spinner-xs {
  border-width: 2px;
  height: 12px;
  width: 12px;
}
.spinner.spinner-inverse {
  border-bottom-color: rgba(255, 255, 255, 0.25);
  border-left-color: rgba(255, 255, 255, 0.25);
  border-right-color: rgba(255, 255, 255, 0.25);
  border-top-color: rgba(255, 255, 255, 0.75);
}
.ie9 .spinner {
  background: url("../img/spinner.gif") no-repeat;
  border: 0;
}
.ie9 .spinner.spinner-inverse {
  background-image: url("../img/spinner-inverse.gif");
}
.ie9 .spinner.spinner-inverse-lg {
  background-image: url("../img/spinner-inverse-lg.gif");
}
.ie9 .spinner.spinner-inverse-sm {
  background-image: url("../img/spinner-inverse-sm.gif");
}
.ie9 .spinner.spinner-inverse-xs {
  background-image: url("../img/spinner-inverse-xs.gif");
}
.ie9 .spinner.spinner-lg {
  background-image: url("../img/spinner-lg.gif");
}
.ie9 .spinner.spinner-sm {
  background-image: url("../img/spinner-sm.gif");
}
.ie9 .spinner.spinner-xs {
  background-image: url("../img/spinner-xs.gif");
}
.prettyprint .atn,
.prettyprint .com,
.prettyprint .fun,
.prettyprint .var {
  color: #3f9c35;
}
.prettyprint .atv,
.prettyprint .str {
  color: #a30000;
}
.prettyprint .clo,
.prettyprint .dec,
.prettyprint .kwd,
.prettyprint .opn,
.prettyprint .pln,
.prettyprint .pun {
  color: #363636;
}
.prettyprint .lit,
.prettyprint .tag,
.prettyprint .typ {
  color: #00659c;
}
.prettyprint ol.linenums {
  margin-bottom: 0;
}
table.dataTable {
  height: 100%;
  margin-bottom: 0;
  max-width: none !important;
}
table.dataTable tbody > tr > td.table-view-pf-actions {
  padding: 0;
  vertical-align: middle;
}
table.dataTable tbody > tr:hover > td.table-view-pf-actions {
  background-color: #f5f5f5;
  border-bottom-color: #d1d1d1;
}
table.dataTable tbody > tr.selected {
  color: #fff;
  background-color: #0088ce;
}
table.dataTable tbody > tr.selected > td {
  border-bottom-color: #00659c;
}
table.dataTable tbody > tr.selected > td.table-view-pf-actions {
  background-color: #f5f5f5;
  border-bottom-color: #d1d1d1;
}
table.dataTable tbody > tr.selected:hover > td {
  background-color: inherit;
  border-bottom-color: #00659c;
}
table.dataTable tbody > tr.selected:hover > td.table-view-pf-actions {
  background-color: #f5f5f5;
  border-bottom-color: #d1d1d1;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
}
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
  color: #0088ce !important;
  position: relative;
}
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after {
  content: "\f107";
  font-family: "FontAwesome";
  font-size: 10px;
  font-weight: normal;
  height: 9px;
  left: 7px;
  line-height: 12px;
  position: relative;
  top: 2px;
  vertical-align: baseline;
  width: 12px;
}
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before {
  background: #0088ce;
  content: "";
  height: 2px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
table.dataTable thead .sorting_asc:after {
  content: "\f106";
  top: -3px;
}
table.dataTable th:active {
  outline: none;
}
.table-view-pf-empty.blank-slate-pf {
  background-color: #f5f5f5;
  border: 1px solid #d1d1d1;
  border-radius: 0;
  margin-top: -1px;
}
.table-view-pf-toolbar.toolbar-pf {
  background-color: #f5f5f5;
  border-bottom: none;
  border-left: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
  border-top: 1px solid #d1d1d1;
  box-shadow: none;
  margin-left: 0px;
  margin-right: 0px;
}
.table-view-pf-toolbar.toolbar-pf .toolbar-pf-results {
  border-top: 1px solid #d1d1d1;
  min-height: 40px;
  line-height: 40px;
}
.table-view-pf-toolbar-external.toolbar-pf {
  border-bottom: none;
  border-top: none;
  box-shadow: none;
}
.table-view-pf-toolbar-external.toolbar-pf .toolbar-pf-results {
  border-top: 1px solid #d1d1d1;
  min-height: 40px;
  line-height: 40px;
}
.table-view-pf-colvis-menu > li {
  padding: 5px 10px;
}
.table-view-pf-colvis-menu > li:hover {
  background-color: #def3ff;
}
.table-view-pf-colvis-menu > li > input {
  margin-top: 0;
  margin-right: 10px;
  vertical-align: middle;
}
.table-view-pf-colvis-menu > li > label {
  margin-bottom: 0;
}
.table-view-pf-actions {
  background-color: #f1f1f1;
  height: 100%;
}
.table-view-pf-actions .btn,
.table-view-pf-actions .dropdown-toggle {
  border: none;
  box-shadow: none;
  height: 100%;
  width: 100%;
}
.table-view-pf-actions .dropdown,
.table-view-pf-actions .table-view-pf-btn {
  height: 100%;
}
.table-view-pf-actions .dropdown-menu.dropdown-menu-right {
  right: -5px;
}
.table-view-pf-actions:last-child {
  width: 24px;
}
.table-view-pf-select {
  width: 33px;
}
.table-view-pf-select-results {
  text-align: right;
}
.bootstrap-datetimepicker-widget .today-button-pf:before {
  content: "Today";
}
@media (min-width: 992px) {
  .bootstrap-datetimepicker-widget.timepicker-sbs .datepicker,
  .bootstrap-datetimepicker-widget.timepicker-sbs .accordion-toggle {
    border-right: 1px solid #d1d1d1;
  }
  .bootstrap-datetimepicker-widget.timepicker-sbs .timepicker {
    padding-left: 0;
    margin-top: 50px;
  }
  .bootstrap-datetimepicker-widget.timepicker-sbs .picker-switch {
    width: 50%;
  }
}
.bootstrap-datetimepicker-widget a[data-action] {
  border: 0;
  box-shadow: none;
  color: #363636;
  display: block;
  padding-bottom: 4px;
  padding-top: 4px;
}
.bootstrap-datetimepicker-widget a[data-action]:hover {
  color: #0088ce;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  left: 0!important;
  padding: 0;
  width: calc(100% - 25px);
}
.bootstrap-datetimepicker-widget.dropdown-menu:before,
.bootstrap-datetimepicker-widget.dropdown-menu:after {
  content: none;
}
.bootstrap-datetimepicker-widget.dropdown-menu.top {
  margin-bottom: -1px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.bottom {
  margin-top: -1px;
}
.bootstrap-datetimepicker-widget .timepicker-hour {
  width: 100%;
}
.bootstrap-datetimepicker-widget .timepicker-hour:after {
  content: ":";
  float: right;
}
.timepicker-hours table td,
.timepicker-minutes table td {
  font-weight: bold;
  line-height: 30px;
  height: 30px;
}
.timepicker-hours table td:hover,
.timepicker-minutes table td:hover {
  color: #0088ce;
}
.timepicker-hours .table-condensed > tbody > tr > td,
.timepicker-minutes .table-condensed > tbody > tr > td {
  padding: 0;
}
.time-picker-pf .input-group-addon .fa,
.time-picker-pf .input-group-addon .fas,
.time-picker-pf .input-group-addon .far,
.time-picker-pf .input-group-addon .fab,
.time-picker-pf .input-group-addon .fal,
.time-picker-pf .input-group-addon .pficon {
  width: 12px;
}
.time-picker-pf .input-group-addon:not(.active) {
  box-shadow: none;
}
.timepicker-picker table td span,
.timepicker-picker table td a span {
  height: 24px;
  line-height: 24px;
  margin: 0;
  width: 100%;
}
.timepicker-picker .table-condensed > tbody > tr > td {
  height: 25px;
  line-height: 18px;
  padding: 0;
}
.timepicker-picker button[data-action] {
  padding-bottom: 0;
  padding-top: 0;
}
.timepicker-picker .separator {
  display: none;
}
.timepicker-picker tr:nth-child(2) td {
  background-color: #def3ff;
  border-color: #bee1f4;
  border-style: solid;
  border-width: 1px;
  border-left: 0;
  border-right: 0;
}
.toast-pf {
  background-color: #fff;
  border-color: #bbb;
  box-shadow: 0 2px 6px rgba(3, 3, 3, 0.2);
  padding-left: 68px;
  margin-bottom: 5px;
  /* Medium devices (desktops, 992px and up) */
}
.toast-pf.alert-danger > .pficon {
  background-color: #cc0000;
}
.toast-pf.alert-info > .pficon {
  background-color: #8b8d8f;
}
.toast-pf.alert-success > .pficon {
  background-color: #3f9c35;
}
.toast-pf.alert-warning > .pficon {
  background-color: #ec7a08;
}
.toast-pf .dropdown-kebab-pf {
  margin-left: 10px;
}
.toast-pf > .pficon {
  background-color: #4d5258;
  bottom: -1px;
  box-shadow: 2px 0 5px -2px rgba(3, 3, 3, 0.2);
  left: -1px;
  padding-top: 10px;
  text-align: center;
  top: -1px;
  width: 53px;
}
.toast-pf > .pficon:before {
  color: rgba(255, 255, 255, 0.74);
}
.toast-pf .toast-pf-action {
  margin-left: 15px;
}
.toast-pf .dropdown-kebab-pf .btn-link {
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: text-bottom;
}
@media (min-width: 992px) {
  .toast-pf {
    display: inline-block;
  }
}
@media (min-width: 992px) {
  .toast-pf-max-width {
    max-width: 31.1%;
  }
}
.toast-pf-top-right {
  left: 20px;
  position: absolute;
  right: 20px;
  top: 12px;
  z-index: 1055;
  /* Medium devices (desktops, 992px and up) */
}
@media (min-width: 992px) {
  .toast-pf-top-right {
    left: auto;
  }
}
.toast-notifications-list-pf {
  margin-left: 20px;
  position: fixed;
  right: 20px;
  top: 80px;
  z-index: 1055;
}
.toast-notifications-list-pf .toast-pf {
  clear: both;
  float: right;
}
@media (min-width: 768px) {
  .toast-notifications-list-pf {
    max-width: calc(50%);
  }
}
@media (min-width: 992px) {
  .toast-notifications-list-pf {
    max-width: calc(33.1%);
  }
}
.toolbar-pf {
  background: #fff;
  border-bottom: 1px solid #d1d1d1;
  box-shadow: 0 1px 0px rgba(3, 3, 3, 0.045);
  padding-top: 10px;
}
.toolbar-pf .form-group {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .toolbar-pf .form-group {
    border-right: 1px solid #d1d1d1;
    float: left;
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.toolbar-pf .form-group:last-child {
  border-right: 0;
  margin-bottom: 0;
  padding-right: 0;
}
.toolbar-pf .form-group .btn + .btn,
.toolbar-pf .form-group .btn-group + .btn,
.toolbar-pf .form-group .btn + .btn-group,
.toolbar-pf .form-group .btn-group + .btn-group {
  margin-left: 5px;
}
.toolbar-pf .form-group .btn + .btn-link,
.toolbar-pf .form-group .btn-group + .btn-link,
.toolbar-pf .form-group .btn + .dropdown,
.toolbar-pf .form-group .btn-group + .dropdown {
  margin-left: 10px;
}
.toolbar-pf .form-group .btn-link {
  color: #252525;
  font-size: 16px;
  line-height: 1;
  padding: 4px 0;
}
.toolbar-pf .form-group .btn-link:active,
.toolbar-pf .form-group .btn-link:focus,
.toolbar-pf .form-group .btn-link:hover {
  color: #0088ce;
}
.toolbar-pf .form-group .dropdown-kebab-pf .btn-link {
  padding: 4px 10px;
  margin-left: -10px;
  margin-right: -10px;
}
.toolbar-pf-actions {
  display: table;
  margin-bottom: 10px;
  width: 100%;
}
@media (min-width: 768px) {
  .toolbar-pf-actions .toolbar-pf-filter {
    padding-left: 0;
  }
}
.toolbar-pf-view-selector {
  font-size: 16px;
}
.toolbar-pf-view-selector .btn-link.active {
  color: #0088ce;
  cursor: default;
}
.toolbar-pf-action-right {
  float: right;
}
@media (max-width: 768px) {
  .toolbar-pf-action-right {
    float: none;
  }
}
.toolbar-pf-find {
  font-size: 14px;
  position: relative;
}
.find-pf-dropdown-container {
  background: #fff;
  border: solid 1px #bbb;
  display: none;
  right: -20px;
  padding: 5px;
  position: absolute;
  top: 35px;
  width: 300px;
  z-index: 10000;
}
@media (max-width: 768px) {
  .find-pf-dropdown-container {
    left: 30px;
    top: -5px;
    width: calc(100% - 30px);
  }
}
.find-pf-dropdown-container:before,
.find-pf-dropdown-container:after {
  content: "";
  position: absolute;
}
.find-pf-dropdown-container:before {
  border-bottom: 11px solid #bbb;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  right: 35px;
  top: -12px;
}
.toolbar-pf-find:last-child .find-pf-dropdown-container:before {
  right: 15px;
}
@media (max-width: 768px) {
  .find-pf-dropdown-container:before {
    border-bottom: 11px solid transparent;
    border-right: 11px solid #bbb;
    border-top: 11px solid transparent;
    left: -22px;
    right: initial;
    top: 8px;
  }
}
.find-pf-dropdown-container:after {
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  right: 36px;
  top: -10px;
}
.toolbar-pf-find:last-child .find-pf-dropdown-container:after {
  right: 16px;
}
@media (max-width: 768px) {
  .find-pf-dropdown-container:after {
    border-bottom: 10px solid transparent;
    border-right: 10px solid #fff;
    border-top: 10px solid transparent;
    left: -20px;
    right: initial;
    top: 9px;
  }
}
.find-pf-dropdown-container input {
  height: 30px;
  padding: 5px 117px 5px 5px;
  width: 100%;
}
.find-pf-dropdown-container .find-pf-buttons {
  position: absolute;
  right: 10px;
  top: 5px;
}
.find-pf-dropdown-container .find-pf-buttons .btn {
  border: none;
  cursor: pointer;
  margin-left: 0 !important;
  padding: 0;
  width: 18px;
}
.find-pf-dropdown-container .find-pf-buttons .btn .fa-angle-up,
.find-pf-dropdown-container .find-pf-buttons .btn .fa-angle-down {
  font-weight: bold;
  font-size: 18px;
}
.find-pf-dropdown-container .find-pf-buttons .btn .pficon-close {
  font-size: 14px;
}
.find-pf-dropdown-container .find-pf-buttons span {
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
}
.find-pf-dropdown-container .find-pf-buttons .find-pf-nums {
  color: #8b8d8f;
  margin-right: 3px;
}
.toolbar-pf-results {
  border-top: 1px solid #d1d1d1;
  margin-top: 10px;
}
.toolbar-pf-results h5,
.toolbar-pf-results p,
.toolbar-pf-results ul {
  display: inline-block;
  line-height: 26.66666667px;
  margin-bottom: 0;
  margin-top: 0;
}
@media (min-width: 768px) {
  .toolbar-pf-results h5,
  .toolbar-pf-results p,
  .toolbar-pf-results ul {
    line-height: 40px;
  }
}
.toolbar-pf-results h5 {
  font-weight: 700;
  margin-right: 20px;
}
.toolbar-pf-results .label {
  font-size: 11px;
}
.toolbar-pf-results .label a {
  color: #fff;
  display: inline-block;
  margin-left: 5px;
}
.tree-list-view-pf {
  background-color: #ededed;
  border: none;
  margin-top: 30px;
  overflow-x: hidden;
}
.tree-list-view-pf > .list-group-item:first-child {
  border-top: 1px solid transparent;
}
.tree-list-view-pf .list-group-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  border-color: #ededed;
  padding: 0;
  background-clip: padding-box;
}
.tree-list-view-pf .list-group-item.active {
  background-color: #fff;
  color: currentColor;
  z-index: auto;
}
.tree-list-view-pf .list-group-item.active:hover {
  border-color: #ededed;
}
.tree-list-view-pf .list-group-item.active > .list-group-item-header {
  background-color: #def3ff;
  color: #555;
}
.tree-list-view-pf .list-group-item.active > .list-group-item-header:before {
  background-color: #def3ff;
}
.tree-list-view-pf .list-group-item.active > .list-group-item-header:hover {
  background-color: #edf8ff;
}
.tree-list-view-pf .list-group-item.active > .list-group-item-header:hover:before {
  background-color: #edf8ff;
}
.tree-list-view-pf .list-group-item.active > .list-group-item-header .list-group-item-text {
  color: #555;
}
.tree-list-view-pf .list-group-item.active .list-group-item-text {
  color: currentColor;
}
.tree-list-view-pf .list-group-item .list-group-item {
  border-bottom: none;
  padding-left: 45px;
}
.tree-list-view-pf .list-group-item .list-group-item .list-group-item-header:before {
  border-bottom: solid 1px #ededed;
  content: "";
  height: calc(100% + 1px);
  left: -100%;
  position: absolute;
  width: 100%;
}
.tree-list-view-pf .list-group-item-container {
  border-top-color: #ededed;
  margin: 0;
  padding: 0;
}
.tree-list-view-pf .list-group-item-header {
  box-sizing: border-box;
  margin: 0;
  position: inherit;
}
.tree-list-view-pf .list-group-item-header:hover {
  background-color: #edf8ff;
  border-left-color: transparent;
  border-right-color: transparent;
}
.tree-list-view-pf .list-group-item-header:hover:before {
  background-color: #edf8ff;
}
@media (min-width: 992px) {
  .tree-list-view-pf .list-group-item-heading {
    width: calc(25% - 20px);
  }
}
.nav-pf-vertical {
  background: #292e34;
  border-right: 1px solid #292e34;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 60px;
  width: 200px;
  z-index: 1030;
}
.layout-pf-fixed-with-footer .nav-pf-vertical {
  bottom: 37px;
}
.ie9.layout-pf-fixed .nav-pf-vertical {
  box-sizing: content-box;
}
.nav-pf-vertical.hidden.show-mobile-nav {
  box-shadow: 0 0 3px rgba(3, 3, 3, 0.15);
  display: block !important;
}
.nav-pf-vertical.hide-nav-pf {
  visibility: hidden !important;
}
.nav-pf-vertical .list-group {
  border-top: 0;
  margin-bottom: 0;
}
.nav-pf-vertical .list-group-item {
  background-color: transparent;
  border-color: #030303;
  padding: 0;
}
.nav-pf-vertical .list-group-item > a {
  background-color: transparent;
  color: #d1d1d1;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 400;
  height: 63px;
  line-height: 26px;
  padding: 17px 20px 17px 25px;
  position: relative;
  white-space: nowrap;
  width: 200px;
}
@supports (display: flex) {
  .nav-pf-vertical .list-group-item > a {
    display: -ms-flexbox;
    display: flex;
    padding-right: 0;
  }
}
.nav-pf-vertical .list-group-item > a .fa,
.nav-pf-vertical .list-group-item > a .fas,
.nav-pf-vertical .list-group-item > a .far,
.nav-pf-vertical .list-group-item > a .fab,
.nav-pf-vertical .list-group-item > a .fal,
.nav-pf-vertical .list-group-item > a .glyphicon,
.nav-pf-vertical .list-group-item > a .pficon {
  color: #72767b;
  float: left;
  font-size: 20px;
  line-height: 26px;
  margin-right: 10px;
  text-align: center;
  width: 24px;
}
.nav-pf-vertical .list-group-item > a:hover,
.nav-pf-vertical .list-group-item > a:focus {
  text-decoration: none;
}
.nav-pf-vertical .list-group-item.active > a,
.nav-pf-vertical .list-group-item:hover > a,
.nav-pf-vertical .list-group-item > a:focus {
  background-color: #393f44;
  color: #fff;
  font-weight: 600;
}
.nav-pf-vertical .list-group-item.active > a .fa,
.nav-pf-vertical .list-group-item:hover > a .fa,
.nav-pf-vertical .list-group-item > a:focus .fa,
.nav-pf-vertical .list-group-item.active > a .fas,
.nav-pf-vertical .list-group-item:hover > a .fas,
.nav-pf-vertical .list-group-item > a:focus .fas,
.nav-pf-vertical .list-group-item.active > a .far,
.nav-pf-vertical .list-group-item:hover > a .far,
.nav-pf-vertical .list-group-item > a:focus .far,
.nav-pf-vertical .list-group-item.active > a .fab,
.nav-pf-vertical .list-group-item:hover > a .fab,
.nav-pf-vertical .list-group-item > a:focus .fab,
.nav-pf-vertical .list-group-item.active > a .fal,
.nav-pf-vertical .list-group-item:hover > a .fal,
.nav-pf-vertical .list-group-item > a:focus .fal,
.nav-pf-vertical .list-group-item.active > a .glyphicon,
.nav-pf-vertical .list-group-item:hover > a .glyphicon,
.nav-pf-vertical .list-group-item > a:focus .glyphicon,
.nav-pf-vertical .list-group-item.active > a .pficon,
.nav-pf-vertical .list-group-item:hover > a .pficon,
.nav-pf-vertical .list-group-item > a:focus .pficon {
  color: #39a5dc;
}
.nav-pf-vertical .list-group-item.active > a:before {
  background: #39a5dc;
  content: " ";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 3px;
}
.nav-pf-vertical .list-group-item.active:hover {
  background-color: transparent;
  border-color: #030303;
}
.nav-pf-vertical .list-group-item .list-group-item-value {
  display: block;
  line-height: 25px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@supports (display: flex) {
  .nav-pf-vertical .list-group-item .list-group-item-value {
    -ms-flex: 1;
        flex: 1;
    max-width: none;
    padding-right: 15px;
  }
}
.nav-pf-vertical .list-group-item-separator {
  border-top-color: #030303;
  border-top-width: 2px;
}
.nav-pf-vertical.nav-pf-vertical-with-badges {
  width: 250px;
}
.nav-pf-vertical.nav-pf-vertical-with-badges .list-group-item > a {
  width: 250px;
}
.nav-pf-vertical h5 {
  color: #fff;
  cursor: default;
  font-size: 13px;
  font-weight: 600;
  margin: 30px 20px 10px 20px;
}
.nav-pf-vertical.hidden-icons-pf > .list-group > .list-group-item > a .fa,
.nav-pf-vertical.hidden-icons-pf > .list-group > .list-group-item > a .fas,
.nav-pf-vertical.hidden-icons-pf > .list-group > .list-group-item > a .far,
.nav-pf-vertical.hidden-icons-pf > .list-group > .list-group-item > a .fab,
.nav-pf-vertical.hidden-icons-pf > .list-group > .list-group-item > a .fal,
.nav-pf-vertical.hidden-icons-pf > .list-group > .list-group-item > a .glyphicon,
.nav-pf-vertical.hidden-icons-pf > .list-group > .list-group-item > a .pficon {
  display: none;
}
.nav-pf-vertical.hidden-icons-pf.collapsed {
  display: none;
}
.nav-pf-vertical .badge-container-pf {
  position: absolute;
  right: 15px;
  top: 20px;
}
@supports (display: flex) {
  .nav-pf-vertical .badge-container-pf {
    padding-left: 0;
    padding-right: 15px;
    position: relative;
    right: 0;
    margin-top: -3px;
    top: 5px;
  }
}
.nav-pf-vertical .badge-container-pf .badge {
  background: #292e34;
  color: #fff;
  float: left;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.66666667;
  margin: 0;
  padding: 0 7px;
  text-align: center;
}
.nav-pf-vertical .badge-container-pf .badge .pficon,
.nav-pf-vertical .badge-container-pf .badge .fa,
.nav-pf-vertical .badge-container-pf .badge .fas,
.nav-pf-vertical .badge-container-pf .badge .far,
.nav-pf-vertical .badge-container-pf .badge .fab,
.nav-pf-vertical .badge-container-pf .badge .fal {
  font-size: 14px;
  height: 20px;
  line-height: 1.66666667;
  margin-right: 3px;
  margin-top: -1px;
}
.nav-pf-vertical-tooltip.tooltip {
  margin-left: 15px;
}
.nav-pf-vertical-tooltip.tooltip .tooltip-inner {
  background-color: #fff;
  color: #292e34;
}
.nav-pf-vertical-tooltip.tooltip .tooltip-arrow {
  border-bottom-color: #fff;
  left: calc(50% - 15px) !important;
}
.hover-secondary-nav-pf {
  width: calc(200px * 2);
}
.hover-secondary-nav-pf.nav-pf-vertical-with-badges {
  width: calc(250px * 2);
}
.hover-tertiary-nav-pf {
  width: calc(200px * 3);
}
.hover-tertiary-nav-pf.nav-pf-vertical-with-badges {
  width: calc(250px * 3);
}
.hover-tertiary-nav-pf .nav-pf-secondary-nav {
  width: calc(200px * 2);
}
.hover-tertiary-nav-pf .nav-pf-secondary-nav .collapsed-tertiary-nav-pf {
  width: 200px;
}
.hover-tertiary-nav-pf .nav-pf-tertiary-nav {
  left: calc(200px * 2);
}
.hover-tertiary-nav-pf.nav-pf-vertical-with-badges .nav-pf-secondary-nav {
  width: calc(250px * 2);
}
.hover-tertiary-nav-pf.nav-pf-vertical-with-badges .nav-pf-secondary-nav .collapsed-tertiary-nav-pf {
  width: 250px;
}
.hover-tertiary-nav-pf.nav-pf-vertical-with-badges .nav-pf-tertiary-nav {
  left: calc(250px * 2);
}
.nav-pf-vertical.collapsed {
  width: 75px;
}
.nav-pf-vertical.collapsed.collapsed-secondary-nav-pf {
  width: 200px;
}
.nav-pf-vertical.collapsed.collapsed-secondary-nav-pf.nav-pf-vertical-with-badges {
  width: 250px;
}
.nav-pf-vertical.collapsed.collapsed-tertiary-nav-pf {
  width: 200px;
}
.nav-pf-vertical.collapsed.collapsed-tertiary-nav-pf.nav-pf-vertical-with-badges {
  width: 250px;
}
.nav-pf-vertical.collapsed.hover-secondary-nav-pf {
  width: calc(75px + 200px);
}
.nav-pf-vertical.collapsed.hover-secondary-nav-pf.nav-pf-vertical-with-badges {
  width: calc(75px + 250px);
}
.nav-pf-vertical.collapsed.hover-tertiary-nav-pf {
  width: calc(75px + (200px * 2));
}
.nav-pf-vertical.collapsed.hover-tertiary-nav-pf.nav-pf-vertical-with-badges {
  width: calc(75px + (250px * 2));
}
.show-mobile-nav.show-mobile-secondary {
  width: 200px;
}
.show-mobile-nav.show-mobile-secondary.nav-pf-vertical-with-badges {
  width: 250px;
}
.show-mobile-nav.show-mobile-tertiary {
  width: 200px;
}
.show-mobile-nav.show-mobile-tertiary.nav-pf-vertical-with-badges {
  width: 250px;
}
.show-mobile-nav .mobile-nav-item-pf .nav-pf-secondary-nav,
.show-mobile-nav .mobile-secondary-item-pf .nav-pf-secondary-nav {
  left: 0;
  opacity: 1;
  visibility: visible;
  z-index: 1034;
}
.show-mobile-nav .mobile-nav-item-pf > .nav-pf-tertiary-nav,
.show-mobile-nav .mobile-secondary-item-pf > .nav-pf-tertiary-nav {
  left: 0;
  opacity: 1;
  visibility: visible;
  z-index: 1038;
}
.show-mobile-nav .nav-pf-secondary-nav {
  left: 0;
}
.secondary-nav-item-pf:hover .show-mobile-nav .nav-pf-secondary-nav {
  opacity: 0;
  visibility: hidden;
}
.show-mobile-nav .tertiary-nav-item-pf:hover .nav-pf-tertiary-nav {
  opacity: 0;
  visibility: hidden;
}
.show-mobile-nav .tertiary-nav-item-pf.mobile-nav-item-pf:hover .nav-pf-tertiary-nav {
  opacity: 1;
  visibility: visible;
}
.secondary-nav-item-pf > a {
  cursor: default;
}
.secondary-nav-item-pf > a:after {
  color: #72767b;
  content: "\f105";
  display: block;
  font-family: "FontAwesome";
  font-size: 24px;
  line-height: 30px;
  padding: 14px 0;
  position: absolute;
  right: 20px;
  top: 0;
}
@supports (display: flex) {
  .secondary-nav-item-pf > a .list-group-item-value {
    padding-right: 35px;
  }
}
.secondary-nav-item-pf.active > a,
.secondary-nav-item-pf:hover > a {
  width: calc(200px + 1px);
  z-index: 1031;
}
.secondary-nav-item-pf.active > a:after,
.secondary-nav-item-pf:hover > a:after {
  right: 21px;
}
.collapsed-secondary-nav-pf .secondary-nav-item-pf.active > a,
.collapsed-secondary-nav-pf .secondary-nav-item-pf:hover > a {
  z-index: 0;
}
.collapsed-tertiary-nav-pf .secondary-nav-item-pf.active > a,
.collapsed-tertiary-nav-pf .secondary-nav-item-pf:hover > a {
  z-index: 0;
}
.nav-pf-vertical-with-badges .secondary-nav-item-pf.active > a,
.nav-pf-vertical-with-badges .secondary-nav-item-pf:hover > a {
  width: calc(250px + 1px);
}
.nav-pf-vertical.collapsed-secondary-nav-pf {
  width: 200px;
}
.nav-pf-vertical.collapsed-secondary-nav-pf.nav-pf-vertical-with-badges {
  width: 250px;
}
.nav-pf-vertical.collapsed-secondary-nav-pf .secondary-nav-item-pf.active > a,
.nav-pf-vertical.collapsed-secondary-nav-pf .secondary-nav-item-pf.hover > a {
  z-index: 1030;
}
.nav-pf-vertical.collapsed-secondary-nav-pf.hover-tertiary-nav-pf {
  width: 200px;
}
.nav-pf-vertical.collapsed-secondary-nav-pf.hover-tertiary-nav-pf.nav-pf-vertical-with-badges {
  width: 250px;
}
.nav-pf-vertical.collapsed-secondary-nav-pf .nav-pf-secondary-nav {
  left: 0;
}
.nav-pf-vertical.collapsed-secondary-nav-pf .nav-pf-tertiary-nav {
  left: 200px;
}
.nav-pf-vertical.collapsed-secondary-nav-pf.nav-pf-vertical-with-badges .nav-pf-tertiary-nav {
  left: 250px;
}
.nav-pf-vertical.collapsed-tertiary-nav-pf {
  width: 200px;
}
.nav-pf-vertical.collapsed-tertiary-nav-pf.nav-pf-vertical-with-badges {
  width: 250px;
}
.nav-pf-vertical.collapsed-tertiary-nav-pf .nav-pf-secondary-nav {
  width: 200px;
}
.nav-pf-vertical.collapsed-tertiary-nav-pf .nav-pf-secondary-nav.nav-pf-vertical-with-badges {
  width: 250px;
}
.nav-pf-vertical.collapsed-tertiary-nav-pf .secondary-nav-item-pf.active > a,
.nav-pf-vertical.collapsed-tertiary-nav-pf .tertiary-nav-item-pf.active > a,
.nav-pf-vertical.collapsed-tertiary-nav-pf .secondary-nav-item-pf.hover > a,
.nav-pf-vertical.collapsed-tertiary-nav-pf .tertiary-nav-item-pf.hover > a {
  z-index: 0;
}
@media (min-width: 1200px) {
  .nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf {
    width: calc(200px * 2);
  }
  .nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.nav-pf-vertical-with-badges {
    width: calc(250px * 2);
  }
  .nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed-secondary-nav-pf {
    width: 200px;
  }
  .nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed-secondary-nav-pf.nav-pf-vertical-with-badges {
    width: 250px;
  }
  .nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed-secondary-nav-pf.hover-tertiary-nav-pf {
    width: calc(200px * 2);
  }
  .nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed-secondary-nav-pf.hover-tertiary-nav-pf.nav-pf-vertical-with-badges {
    width: calc(250px * 2);
  }
  .nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed-tertiary-nav-pf {
    width: 200px;
  }
  .nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed-tertiary-nav-pf.nav-pf-vertical-with-badges {
    width: 250px;
  }
  .nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed {
    width: calc(75px + 200px);
  }
  .nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed.collapsed-secondary-nav-pf {
    width: 200px;
  }
  .nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed.collapsed-secondary-nav-pf.nav-pf-vertical-with-badges {
    width: 250px;
  }
  .nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed.collapsed-tertiary-nav-pf {
    width: 200px;
  }
  .nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed.collapsed-tertiary-nav-pf.nav-pf-vertical-with-badges {
    width: 250px;
  }
  .nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed.hover-tertiary-nav-pf {
    width: calc(75px + (200px * 2));
  }
  .nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.collapsed.hover-tertiary-nav-pf.nav-pf-vertical-with-badges {
    width: calc(75px + (250px * 2));
  }
  .nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.hover-tertiary-nav-pf {
    width: calc(200px * 3);
  }
  .nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf.hover-tertiary-nav-pf.nav-pf-vertical-with-badges {
    width: calc(250px * 3);
  }
  .nav-pf-vertical.nav-pf-persistent-secondary.secondary-visible-pf .secondary-nav-item-pf.active .nav-pf-secondary-nav {
    visibility: visible;
    opacity: 1;
  }
}
.nav-item-pf-header {
  color: #fff;
  font-size: 16px;
  margin: 18px 20px 10px 20px;
}
.nav-item-pf-header > a {
  cursor: pointer;
  margin-right: 7px;
}
.nav-item-pf-header > a:hover,
.nav-item-pf-header > a:focus {
  color: #0088ce;
  text-decoration: none;
}
.nav-pf-vertical.collapsed {
  width: 75px;
}
.nav-pf-vertical.collapsed .list-group-item > a {
  width: 75px;
}
.nav-pf-vertical.collapsed .list-group-item > a > .list-group-item-value {
  display: none;
}
.nav-pf-vertical.collapsed .list-group-item > a > .badge-container-pf {
  display: none;
}
.nav-pf-vertical.collapsed .list-group-item.secondary-nav-item-pf.active > a,
.nav-pf-vertical.collapsed .list-group-item.secondary-nav-item-pf > a {
  width: 75px;
}
.nav-pf-vertical.collapsed .list-group-item.secondary-nav-item-pf.active > a:after,
.nav-pf-vertical.collapsed .list-group-item.secondary-nav-item-pf > a:after {
  right: 10px;
}
.nav-pf-vertical.collapsed .list-group-item.secondary-nav-item-pf.active > a,
.nav-pf-vertical.collapsed .list-group-item.secondary-nav-item-pf:hover > a {
  width: 77px;
}
.nav-pf-vertical.collapsed .list-group-item.secondary-nav-item-pf.active > a:after,
.nav-pf-vertical.collapsed .list-group-item.secondary-nav-item-pf:hover > a:after {
  right: 11px;
}
.nav-pf-secondary-nav {
  background: #393f44;
  border: 1px solid #292e34;
  border-bottom: none;
  border-top: none;
  bottom: 0;
  display: block;
  left: 200px;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 60px;
  visibility: hidden;
  width: 200px;
  z-index: 1030;
}
.collapsed-secondary-nav-pf .secondary-nav-item-pf.active .nav-pf-secondary-nav {
  left: 0;
  opacity: 1;
  visibility: visible;
  z-index: 1032;
}
.collapsed-tertiary-nav-pf .secondary-nav-item-pf.active .nav-pf-secondary-nav {
  left: 0;
  opacity: 1;
  visibility: visible;
}
.hover-secondary-nav-pf .secondary-nav-item-pf.is-hover .nav-pf-secondary-nav {
  opacity: 1;
  visibility: visible;
}
.ie9.layout-pf-fixed .nav-pf-secondary-nav {
  box-sizing: content-box;
}
.layout-pf-fixed-with-footer .nav-pf-secondary-nav {
  bottom: 37px;
}
.nav-pf-secondary-nav .list-group-item {
  border: none;
  padding: 0 0 5px 0;
  width: 200px;
}
.nav-pf-secondary-nav .list-group-item > a {
  background-color: #393f44;
  color: #d1d1d1;
  font-size: 12px;
  font-weight: inherit;
  height: inherit;
  padding: 4px 0 2px 0;
  margin-left: 20px;
  width: calc(200px - 20px);
}
.nav-pf-secondary-nav .list-group-item.active > a:before {
  display: none;
}
.nav-pf-secondary-nav .list-group-item.active > a,
.nav-pf-secondary-nav .list-group-item:hover > a,
.nav-pf-secondary-nav .list-group-item > a:focus {
  background-color: #4d5258;
  color: #fff;
}
.nav-pf-secondary-nav .list-group-item .badge-container-pf {
  top: 5px;
}
.nav-pf-secondary-nav .list-group-item .badge-container-pf .badge {
  background: #292e34;
  color: #fff;
}
.nav-pf-secondary-nav .list-group-item .list-group-item-value {
  padding-left: 5px;
}
.nav-pf-secondary-nav .list-group-item.tertiary-nav-item-pf > a {
  cursor: default;
}
.nav-pf-secondary-nav .list-group-item.tertiary-nav-item-pf > a:after {
  color: #72767b;
  content: "\f105";
  display: block;
  font-family: "FontAwesome";
  font-size: 20px;
  line-height: 20px;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 4px;
}
@supports (display: flex) {
  .nav-pf-secondary-nav .list-group-item.tertiary-nav-item-pf > a .list-group-item-value {
    padding-right: 35px;
  }
}
.nav-pf-secondary-nav .list-group-item.tertiary-nav-item-pf.active > a,
.nav-pf-secondary-nav .list-group-item.tertiary-nav-item-pf:hover > a {
  width: calc(200px - 19px);
  z-index: 1033;
}
.nav-pf-secondary-nav .list-group-item.tertiary-nav-item-pf.active > a:after,
.nav-pf-secondary-nav .list-group-item.tertiary-nav-item-pf:hover > a:after {
  right: 21px;
}
.nav-pf-vertical-with-badges .nav-pf-secondary-nav {
  left: 250px;
  width: 250px;
}
.nav-pf-vertical-with-badges .nav-pf-secondary-nav .list-group-item {
  width: 250px;
}
.nav-pf-vertical-with-badges .nav-pf-secondary-nav .list-group-item > a {
  width: calc(250px - 20px);
}
.nav-pf-vertical-with-badges .nav-pf-secondary-nav .list-group-item.tertiary-nav-item-pf.active > a,
.nav-pf-vertical-with-badges .nav-pf-secondary-nav .list-group-item.tertiary-nav-item-pf:hover > a {
  width: calc(250px - 19px);
}
.nav-pf-tertiary-nav {
  background: #4d5258;
  border: 1px solid #292e34;
  border-bottom: none;
  border-top: none;
  bottom: 0;
  display: block;
  left: calc(200px * 2);
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 60px;
  visibility: hidden;
  width: 200px;
  z-index: 1030;
}
.nav-pf-vertical-with-badges .nav-pf-tertiary-nav {
  left: 250px;
  width: 250px;
}
.nav-pf-vertical-with-badges .nav-pf-tertiary-nav .show-mobile-nav {
  left: 0;
}
.collapsed-tertiary-nav-pf .tertiary-nav-item-pf.active .nav-pf-tertiary-nav {
  left: 0;
  opacity: 1;
  visibility: visible;
  z-index: 1033;
}
.hover-tertiary-nav-pf .tertiary-nav-item-pf.is-hover .nav-pf-tertiary-nav {
  opacity: 1;
  visibility: visible;
}
.collapsed.collapsed-tertiary-nav-pf .hover-tertiary-nav-pf .tertiary-nav-item-pf.is-hover .nav-pf-tertiary-nav {
  left: 0;
}
.ie9.layout-pf-fixed .nav-pf-tertiary-nav {
  box-sizing: content-box;
}
.layout-pf-fixed-with-footer .nav-pf-tertiary-nav {
  bottom: 37px;
}
.nav-pf-tertiary-nav .nav-item-pf-header {
  color: #fff;
  margin: 18px 20px 10px 20px;
}
.nav-pf-tertiary-nav h5 {
  color: #fff;
  margin: 30px 20px 10px 20px;
}
.nav-pf-tertiary-nav .list-group-item {
  border: none;
  padding: 0 0 5px 0;
}
.nav-pf-tertiary-nav .list-group-item > a {
  background-color: transparent;
  color: #d1d1d1;
  font-size: 12px;
  font-weight: inherit;
  height: inherit;
  margin: 0 20px 0 20px;
  padding: 4px 0 2px 0;
}
.nav-pf-tertiary-nav .list-group-item.active > a:before {
  display: none;
}
.nav-pf-tertiary-nav .list-group-item.active > a,
.nav-pf-tertiary-nav .list-group-item:hover > a,
.nav-pf-tertiary-nav .list-group-item > a:focus {
  background-color: #393f44;
  color: #fff;
}
.nav-pf-tertiary-nav .list-group-item .badge-container-pf {
  top: 5px;
}
.nav-pf-tertiary-nav .list-group-item .badge-container-pf .badge {
  background: #292e34;
  color: #fff;
}
.nav-pf-tertiary-nav .list-group-item .list-group-item-value {
  padding-left: 5px;
}
.collapsed .nav-pf-secondary-nav {
  left: 75px;
}
.collapsed .nav-pf-secondary-nav .list-group-item > a {
  width: calc(200px - 20px);
}
.collapsed .nav-pf-secondary-nav .list-group-item > a > .list-group-item-value {
  display: inline-block;
}
.collapsed .nav-pf-secondary-nav .list-group-item > a > .badge-container-pf {
  display: inline-block;
}
.collapsed .nav-pf-tertiary-nav {
  left: calc(75px + 200px);
}
.collapsed .nav-pf-tertiary-nav .list-group-item > a {
  width: calc(200px - 20px);
}
.collapsed .nav-pf-tertiary-nav .list-group-item > a > .list-group-item-value {
  display: inline-block;
}
.collapsed .nav-pf-tertiary-nav .list-group-item > a > .badge-container-pf {
  display: inline-block;
}
.collapsed.collapsed-secondary-nav-pf,
.collapsed.collapsed-tertiary-nav-pf {
  width: 200px;
}
.collapsed.collapsed-secondary-nav-pf .secondary-nav-item-pf:hover > a,
.collapsed.collapsed-tertiary-nav-pf .secondary-nav-item-pf:hover > a {
  z-index: 1030;
}
.collapsed.collapsed-secondary-nav-pf .nav-pf-secondary-nav,
.collapsed.collapsed-tertiary-nav-pf .nav-pf-secondary-nav {
  left: 0;
}
.collapsed.collapsed-secondary-nav-pf .nav-pf-tertiary-nav {
  left: 200px;
}
.collapsed.collapsed-tertiary-nav-pf .nav-pf-tertiary-nav {
  left: 0;
}
.collapsed.hover-secondary-nav-pf {
  width: calc(75px + 200px);
}
.collapsed.hover-secondary-nav-pf.collapsed-secondary-nav-pf,
.collapsed.hover-secondary-nav-pf.collapsed-tertiary-nav-pf {
  width: 200px;
}
.collapsed.hover-tertiary-nav-pf {
  width: calc(75px + (200px * 2));
}
.collapsed.hover-tertiary-nav-pf.collapsed-secondary-nav-pf {
  width: calc(200px * 2);
}
.collapsed.hover-tertiary-nav-pf.collapsed-tertiary-nav-pf {
  width: 200px;
}
.collapsed.nav-pf-vertical-with-badges .nav-pf-secondary-nav .list-group-item > a {
  width: calc(250px - 20px);
}
.collapsed.nav-pf-vertical-with-badges .nav-pf-tertiary-nav {
  left: calc(75px + 250px);
}
.collapsed.nav-pf-vertical-with-badges .nav-pf-tertiary-nav .list-group-item > a {
  width: calc(250px - 20px);
}
.collapsed.nav-pf-vertical-with-badges.collapsed-secondary-nav-pf,
.collapsed.nav-pf-vertical-with-badges.collapsed-tertiary-nav-pf {
  width: 250px;
}
.collapsed.nav-pf-vertical-with-badges.collapsed-secondary-nav-pf .nav-pf-tertiary-nav {
  left: 250px;
}
.collapsed.nav-pf-vertical-with-badges.hover-secondary-nav-pf {
  width: calc(75px + 250px);
}
.collapsed.nav-pf-vertical-with-badges.hover-secondary-nav-pf.collapsed-secondary-nav-pf,
.collapsed.nav-pf-vertical-with-badges.hover-secondary-nav-pf.collapsed-tertiary-nav-pf {
  width: 250px;
}
.collapsed.nav-pf-vertical-with-badges.hover-tertiary-nav-pf {
  width: calc(75px + (200px * 2));
}
.collapsed.nav-pf-vertical-with-badges.hover-tertiary-nav-pf.collapsed-secondary-nav-pf {
  width: calc(200px * 2);
}
.collapsed.nav-pf-vertical-with-badges.hover-tertiary-nav-pf.collapsed-tertiary-nav-pf {
  width: 200px;
}
.secondary-collapse-toggle-pf {
  display: none;
  font-family: "FontAwesome";
  font-size: inherit;
  opacity: 0;
  pointer-events: none;
  -webkit-font-smoothing: antialiased;
}
.secondary-collapse-toggle-pf:before {
  content: "\f190";
}
.secondary-collapse-toggle-pf.collapsed:before {
  content: "\f18e";
}
.tertiary-collapse-toggle-pf {
  display: none;
  font-family: "FontAwesome";
  font-size: inherit;
  opacity: 0;
  pointer-events: none;
  -webkit-font-smoothing: antialiased;
}
.tertiary-collapse-toggle-pf:before {
  content: "\f190";
}
.tertiary-collapse-toggle-pf.collapsed:before {
  content: "\f18e";
}
.nav-pf-vertical-collapsible-menus .secondary-collapse-toggle-pf {
  display: inline-block;
}
.nav-pf-vertical-collapsible-menus .secondary-nav-item-pf.active .secondary-collapse-toggle-pf {
  opacity: 1;
  pointer-events: all;
}
.nav-pf-vertical-collapsible-menus .tertiary-collapse-toggle-pf {
  display: inline-block;
}
.nav-pf-vertical-collapsible-menus .tertiary-nav-item-pf.active .tertiary-collapse-toggle-pf {
  opacity: 1;
  pointer-events: all;
}
.show-mobile-nav .secondary-collapse-toggle-pf {
  display: inline-block;
  opacity: 1;
  pointer-events: all;
}
.show-mobile-nav .secondary-collapse-toggle-pf:before {
  content: "\f190";
}
.show-mobile-nav .tertiary-collapse-toggle-pf {
  display: inline-block;
  opacity: 1;
  pointer-events: all;
}
.show-mobile-nav .tertiary-collapse-toggle-pf:before {
  content: "\f190";
}
.force-hide-secondary-nav-pf .nav-pf-secondary-nav {
  display: none !important;
}
.force-hide-secondary-nav-pf .nav-pf-tertiary-nav {
  display: none !important;
}
.nav-pf-vertical.transitions {
  transition: width 100ms;
}
.nav-pf-vertical.transitions .nav-pf-secondary-nav {
  transition: visibility 100ms, opacity 100ms linear;
}
.nav-pf-vertical.transitions .nav-pf-tertiary-nav {
  transition: visibility 100ms, opacity 100ms linear;
}
.wizard-pf {
  padding: 10px;
  margin: 0 auto;
  max-height: 900px;
  width: auto;
}
.wizard-pf .modal-content {
  min-height: 100%;
}
.wizard-pf-body {
  background: #fff;
  padding: 0;
  position: static;
}
/* styles the sidebard containing the sub-steps */
.wizard-pf-sidebar {
  background: #fafafa;
  border-right: 1px solid #d1d1d1;
  display: none;
}
@media (min-width: 768px) {
  .wizard-pf-sidebar {
    display: inherit;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .wizard-pf-sidebar .list-group {
    border-top: 0;
    margin-bottom: 0;
  }
  .wizard-pf-sidebar .list-group-item {
    background-color: transparent;
    border-color: #ededed;
    padding: 0;
  }
  .wizard-pf-sidebar .list-group-item > a {
    color: #030303;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 700;
    height: 50px;
    outline: 0;
    padding-top: 11px;
    padding-left: 20px;
    position: relative;
    white-space: nowrap;
    width: 14em;
  }
  .wizard-pf-sidebar .list-group-item > a:hover {
    background-color: #ededed;
    text-decoration: none;
  }
  .wizard-pf-sidebar .list-group-item > a:hover:focus {
    text-decoration: none;
  }
  .wizard-pf-sidebar .list-group-item > a:hover:focus span {
    text-decoration: underline;
  }
  .wizard-pf-sidebar .list-group-item.active {
    background-color: #ededed;
  }
  .wizard-pf-sidebar .list-group-item.active:hover {
    border-color: #ededed;
  }
  .wizard-pf-sidebar .list-group-item.active > a {
    color: #39a5dc;
    cursor: default;
  }
  .wizard-pf-sidebar .list-group-item.active > a:before {
    content: " ";
    background: #39a5dc;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 3px;
  }
  .wizard-pf-sidebar .list-group-item.active > a:after {
    color: #39a5dc;
    content: "\f105";
    display: block;
    font-family: FontAwesome;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    padding-top: 10px;
    position: absolute;
    right: 23px;
    top: 0;
  }
}
.wizard-pf-substep-number {
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
  width: 25px;
}
.wizard-pf-substep-title {
  display: inline-block;
  margin-right: 5px;
  text-align: left;
  vertical-align: middle;
}
/* styles the steps indicator across the top of the wizard */
.wizard-pf-steps {
  border-bottom: solid 1px #d1d1d1;
}
@media (min-width: 768px) {
  .wizard-pf-steps {
    text-align: center;
  }
}
.wizard-pf-steps-indicator {
  background: #ededed;
  border-top: 1px solid #d1d1d1;
  display: inline-block;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  list-style: none;
  margin-bottom: 0;
  padding: 15px 0;
}
@media (min-width: 768px) {
  .wizard-pf-steps-indicator {
    background: #fff;
    height: 120px;
    padding: 38px 0 0;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}
.wizard-pf-steps-indicator .wizard-pf-step {
  counter-increment: section;
  float: left;
  /* float for IE9 since it doesn't support flex. If items wrap, they overlap */
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  line-height: 15px;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
  /* draw the line between the circles */
}
.wizard-pf-steps-indicator .wizard-pf-step:not(.active) {
  display: none;
}
@media (min-width: 768px) {
  .wizard-pf-steps-indicator .wizard-pf-step:not(.active) {
    display: block;
  }
}
.wizard-pf-steps-indicator .wizard-pf-step a {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  font-weight: 700;
}
@media (min-width: 768px) {
  .wizard-pf-steps-indicator .wizard-pf-step a {
    font-weight: normal;
    -ms-flex-pack: center;
        justify-content: center;
  }
}
.wizard-pf-steps-indicator .wizard-pf-step a .wizard-pf-step-title {
  margin-left: 10px;
}
@media (min-width: 768px) {
  .wizard-pf-steps-indicator .wizard-pf-step a .wizard-pf-step-title {
    margin-left: 0;
  }
}
.wizard-pf-steps-indicator .wizard-pf-step a .wizard-pf-step-title-substep {
  font-weight: normal;
  margin-left: 10px;
  text-transform: capitalize;
}
.wizard-pf-steps-indicator .wizard-pf-step a .wizard-pf-step-title-substep:before {
  content: "\00BB";
  font-size: 20px;
  margin-right: 10px;
}
.wizard-pf-steps-indicator .wizard-pf-step a .wizard-pf-step-title-substep:not(.active) {
  display: none;
}
@media (min-width: 768px) {
  .wizard-pf-steps-indicator .wizard-pf-step {
    /* don't draw the line between the circles on the ends */
  }
  .wizard-pf-steps-indicator .wizard-pf-step .wizard-pf-step-title-substep {
    display: none;
  }
  .wizard-pf-steps-indicator .wizard-pf-step:before {
    background-color: #bbb;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 40px;
  }
  .wizard-pf-steps-indicator .wizard-pf-step:first-child:before {
    left: 50%;
    right: 0;
  }
  .wizard-pf-steps-indicator .wizard-pf-step:last-child:before {
    left: 0;
    right: 50%;
  }
  .wizard-pf-steps-indicator .wizard-pf-step:only-of-type:before {
    background-color: transparent;
  }
}
.wizard-pf-steps-indicator .wizard-pf-step a {
  color: #030303;
  cursor: pointer;
  font-size: 16px;
  margin-left: 1em;
  margin-right: 1em;
  text-decoration: none;
}
.wizard-pf-steps-indicator .wizard-pf-step:not(.active):not(.disabled) a:hover .wizard-pf-step-number {
  background-color: #bbb;
  border-color: #bbb;
  color: #fff;
}
.wizard-pf-steps-indicator .wizard-pf-step .wizard-pf-step-number {
  background-color: #fff;
  border-radius: 50%;
  border: solid 2px #39a5dc;
  color: #bbb;
  font-size: 12px;
  font-weight: 700;
  height: 25px;
  line-height: 22px;
  width: 25px;
  display: inline-block;
}
@media (min-width: 768px) {
  .wizard-pf-steps-indicator .wizard-pf-step .wizard-pf-step-number {
    left: calc(50% - 13px);
    position: absolute;
    top: 27px;
  }
}
.wizard-pf-steps-indicator .wizard-pf-step.active .wizard-pf-step-number {
  background-color: #39a5dc;
  border-color: #39a5dc;
  cursor: default;
  color: #fff;
}
.wizard-pf-steps-indicator .wizard-pf-step.active ~ .wizard-pf-step .wizard-pf-step-number {
  border-color: #bbb;
  background: #fff;
}
/* styles the main content portion of the wizard */
.wizard-pf-main {
  height: 100%;
  padding: 1em;
  vertical-align: top;
  width: 100%;
}
@media (min-width: 768px) {
  .wizard-pf-main {
    overflow: auto;
    padding: 3em;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
  }
}
.wizard-pf-main .blank-slate-pf {
  background-color: transparent;
  border: none;
  left: 0;
  right: 0;
}
.wizard-pf-contents textarea {
  resize: vertical;
}
/* styles the content of a review page */
.wizard-pf-review-steps {
  list-style: none;
}
.wizard-pf-review-steps .list-group,
.wizard-pf-review-steps .list-group-item {
  border: none;
  margin-bottom: 0;
}
.wizard-pf-review-steps > ul > li {
  float: left;
  line-height: 15px;
  margin: 0;
  padding-top: 0;
  position: relative;
  width: 100%;
}
.wizard-pf-review-steps > ul > li > a {
  color: #030303;
  cursor: pointer;
  font-size: 16px;
  padding-left: 30px;
  padding-right: 5px;
  text-decoration: none;
  transition: 250ms;
}
.wizard-pf-review-steps > ul > li > a:before {
  content: "\f107";
  display: block;
  font-family: FontAwesome;
  font-size: 24px;
  font-weight: 500;
  left: 20px;
  position: absolute;
  top: 0;
}
.wizard-pf-review-steps > ul > li > a.collapsed:before {
  content: "\f105";
}
.wizard-pf-review-substeps {
  padding-left: 22px;
}
.wizard-pf-review-substeps > ul > li {
  float: left;
  line-height: 15px;
  margin: 0;
  position: relative;
  width: 100%;
}
.wizard-pf-review-substeps > ul > li a {
  color: #030303;
  cursor: pointer;
  font-size: 16px;
  padding-left: 30px;
  padding-right: 5px;
  text-decoration: none;
  transition: 250ms;
}
.wizard-pf-review-substeps > ul > li a:before {
  content: "\f107";
  display: block;
  font-family: FontAwesome;
  font-size: 24px;
  font-weight: 500;
  left: 20px;
  position: absolute;
  top: 10px;
}
.wizard-pf-review-substeps > ul > li a.collapsed:before {
  content: "\f105";
}
.wizard-pf-review-content {
  padding-top: 10px;
  padding-left: 40px;
}
.wizard-pf-review-content .wizard-pf-review-item {
  padding: 5px 0;
}
.wizard-pf-review-content .wizard-pf-review-item.sub-item {
  margin-left: 10px;
}
.wizard-pf-review-content .wizard-pf-review-item .wizard-pf-review-item-label {
  font-weight: 700;
  padding-right: 10px;
}
.wizard-pf-review-content .wizard-pf-review-item .wizard-pf-review-item-field {
  font-weight: 700;
  margin: 5px 0;
  padding-right: 10px;
}
.wizard-pf-review-content .wizard-pf-review-item .wizard-pf-review-item-field:first-of-type {
  margin-top: 0;
}
.wizard-pf-review-content .wizard-pf-review-item .wizard-pf-review-item-field:last-of-type {
  margin-bottom: 0;
}
.wizard-pf-review-content .wizard-pf-review-item .wizard-pf-review-item-field.sub-field {
  margin-left: 10px;
}
.wizard-pf-success-icon {
  color: #3f9c35;
  font-size: 67.2px;
  line-height: 67.2px;
}
/* styles the footer */
.wizard-pf-footer {
  background: #fff;
  border-top: 1px solid #d1d1d1;
  margin-top: 0;
  padding-bottom: 17px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
}
.wizard-pf-footer .wizard-pf-cancel {
  margin-right: 25px;
  -ms-flex-order: -1;
      order: -1;
}
.wizard-pf-footer .wizard-pf-next {
  -ms-flex-order: 1;
      order: 1;
  margin-left: 8px;
}
@media (min-width: 768px) {
  .wizard-pf-row {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 900px;
    max-height: 65vh;
  }
}
@media (min-width: 992px) {
  .wizard-pf {
    padding: 30px 0;
    width: 900px;
  }
  .wizard-pf-sidebar .list-group-item > a {
    width: 18em;
  }
}
.wizard-pf-steps-alt,
.wizard-pf-steps-alt ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.wizard-pf-steps-alt {
  margin: 15px 0 15px 15px;
}
@media (min-width: 768px) {
  .wizard-pf-steps-alt {
    display: none;
  }
}
.wizard-pf-steps-alt-indicator {
  position: relative;
}
.wizard-pf-steps-alt-indicator:after {
  color: #4d5258;
  content: "\f107";
  display: block;
  font-family: FontAwesome;
  font-size: 24px;
  font-weight: 500;
  position: absolute;
  right: 17px;
  top: 50%;
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
}
@media (min-width: 768px) {
  .wizard-pf-steps-alt-indicator:after {
    display: none;
  }
}
.wizard-pf-steps-alt-indicator.active:after {
  content: "\f106";
}
.wizard-pf-step-alt {
  position: relative;
  z-index: 1;
}
.wizard-pf-step-alt:not(:last-child) {
  padding-bottom: 10px;
}
.wizard-pf-step-alt a {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
      flex: 1;
}
.wizard-pf-step-alt a:hover,
.wizard-pf-step-alt a:focus {
  text-decoration: none;
}
.wizard-pf-step-alt a:hover .wizard-pf-step-alt-title,
.wizard-pf-step-alt a:focus .wizard-pf-step-alt-title {
  color: #39a5dc;
}
.wizard-pf-step-alt ul {
  margin-left: 11px;
}
.wizard-pf-step-alt .wizard-pf-step-alt-number {
  border-radius: 50%;
  font-size: 12px;
  font-weight: 700;
  height: 24px;
  width: 24px;
  display: inline-block;
  text-align: center;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  border: 2px solid #39a5dc;
  background-color: #fff;
}
.wizard-pf-step-alt .wizard-pf-step-alt-title {
  color: #030303;
  font-weight: 700;
  text-transform: capitalize;
  display: inline-block;
  margin-left: 5px;
  -ms-flex-item-align: center;
      align-self: center;
}
.wizard-pf-step-alt.active .wizard-pf-step-alt-number {
  background-color: #39a5dc;
  border-color: #39a5dc;
  cursor: default;
  color: #fff;
}
.wizard-pf-step-alt.active .wizard-pf-step-alt-title {
  color: #39a5dc;
}
.wizard-pf-step-alt.active ~ .wizard-pf-step-alt .wizard-pf-step-alt-number {
  color: #bbb;
  border-color: #bbb;
}
.wizard-pf-step-alt:not(.active) a:hover .wizard-pf-step-alt-number {
  border-color: #bbb;
  background: #bbb;
  color: #fff;
}
.wizard-pf-step-alt:before {
  content: "";
  position: absolute;
  left: 11px;
  height: 100%;
  border-left: 2px solid #bbb;
  z-index: -1;
}
.wizard-pf-step-alt-substep {
  display: -ms-flexbox;
  display: flex;
  border-left: 2px solid transparent;
}
.wizard-pf-step-alt-substep a {
  padding: 5px 0 5px 18px;
  color: #393f44;
}
.wizard-pf-step-alt-substep.active,
.wizard-pf-step-alt-substep:not(.disabled):hover {
  background-color: #ededed;
  border-color: #39a5dc;
}
.wizard-pf-step-alt-substep.active a,
.wizard-pf-step-alt-substep:not(.disabled):hover a {
  color: #39a5dc;
}
.wizard-pf-step-alt-substep.active a {
  font-weight: 700;
}
.wizard-pf-step.disabled > a:hover,
.wizard-pf-step-alt-substep.disabled > a:hover,
.wizard-pf-sidebar .list-group-item.disabled > a:hover {
  cursor: not-allowed;
}
/* RCUE-specific */
.about-modal-pf {
  background-color: #292e34;
  background-image: url("../img/bg-login-2.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 216px auto;
}
@media (min-width: 768px) {
  .about-modal-pf {
    background-size: auto;
  }
}
.about-modal-pf .modal-body {
  color: #fff;
  padding-bottom: 16px;
  padding-left: 40px;
  padding-right: 40px;
}
@media (min-width: 768px) {
  .about-modal-pf .modal-body {
    padding-left: 80px;
    padding-right: 80px;
  }
}
.about-modal-pf .modal-header {
  background-color: transparent;
}
.about-modal-pf .modal-footer img {
  width: 20%;
}
.about-modal-pf .pficon-close {
  color: #fff;
}
.product-versions-pf {
  margin-bottom: 30px;
  margin-top: 30px;
}
.product-versions-pf li strong {
  margin-right: 10px;
}
.trademark-pf {
  font-size: 11px;
}
.login-pf {
  background-color: #1a1a1a;
}
@media (min-width: 768px) {
  .login-pf {
    background-image: url("../img/bg-login-2.png");
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 30%;
  }
}
@media (min-width: 992px) {
  .login-pf {
    background-size: auto;
  }
}
.login-pf #badge {
  margin-right: 65px;
  margin-bottom: 50px;
  text-align: right;
}
.login-pf #badge img {
  width: 29%;
}
.login-pf body {
  background: transparent;
}
@media (min-width: 768px) {
  .login-pf body {
    background-image: url("../img/bg-login.png");
    background-repeat: no-repeat;
    background-size: 30%;
    height: 100%;
  }
}
@media (min-width: 992px) {
  .login-pf body {
    background-size: auto;
  }
}
.login-pf #brand {
  top: -30px;
}
.login-pf #brand img {
  height: auto;
  width: 50%;
}
@media (min-width: 768px) {
  .login-pf #brand {
    top: -40px;
  }
  .login-pf #brand + .alert {
    margin-top: -20px;
  }
}
.login-pf .container {
  padding-top: 0;
}
@media (min-width: 992px) {
  .login-pf .container {
    bottom: 20%;
    padding-right: 120px;
  }
}
.login-pf-page-logo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}
.login-pf-page-logo img {
  margin-top: 15px;
  height: 50px;
}
.login-pf .login-pf-page-footer {
  position: fixed;
  right: 0;
  bottom: 50px;
  left: 0;
}
.login-pf .login-pf-page-footer-link {
  color: #8b8d8f;
}
.navbar-pf-vertical .navbar-brand .navbar-brand-name {
  height: 35px;
}